import * as React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { AppContext, ProjectExploreBaseMapChanged, ProjectExploreRastersChanged, useTeam } from '@/context';
import { updateProject, updateReport } from '@/resources';
import { LoadingAnimation, MainNavigation } from '@/lib';
import MapLayerPicker from '@/lib/map/MapLayerPicker';
import './index.scss';
import SideStepNavigation from "@/lib/SideStepNavigation";
import {initSites} from "@/lib/AppLoader";


export default function ProjectPage(): JSX.Element {
    const { dispatch, state } = React.useContext(AppContext);
    const [initialized, setInitialized] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const params = useParams();
    const project = state.projects.projects[params.project] as IProject;
    const report = project?.reports?.[project.latest_report_id];
    const team = useTeam();

    React.useEffect(() => {
        updateProject(state, dispatch, params.project).then(project => {
            if (!project) {
                navigate(`/${team.slug}`);
                return;
            }

            updateReport(state, dispatch, project, project.latest_report_id).then(report => {
                if (report && project.site_count === 0) {
                    navigate(`/${team.slug}/${project.slug}/explore`);
                }

                setInitialized(true);
            });
        });

        const intervalId = window.setInterval(() => {
            if (project && team) {
                initSites(dispatch, team, project, () => {});
            }
        }, 60000);

        return () => window.clearInterval(intervalId);

    }, [project]);

    if (!initialized || !project || project.loading || !report || report.loading) {
        return (
            <LoadingAnimation />
        );
    }

    return (
        <>
            <MainNavigation />
            {state.showSideStep && (<SideStepNavigation />)}
            <Outlet />

            {state.showDataLayerModal && <MapLayerPicker
                availableRasters={project.rasters}
                onChoose={(kind, raster) => {
                    console.log(' it receives here onChoose ==>> ', kind, raster);
                    switch (kind) {
                    case 'basemap':
                        dispatch({ type: ProjectExploreBaseMapChanged, basemap: raster });
                        break;

                    case 'raster':
                        dispatch({ type: ProjectExploreRastersChanged, rasters: { [(raster as IProjectRasterConfig).id]: raster } });
                        break;
                    }
                }}
                onClose={() => dispatch({ type: 'toggleDataLayerModal' })}
                selectedBaseMap={state.projects.exploreMapBaseMap}
                selectedRasters={state.projects.exploreMapRasters} />}
        </>
    );
}
