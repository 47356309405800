import * as React from 'react';
import { AppContext, useTheme } from '@/context';
import { Button, Icon, SegmentedButton } from '@/lib';
import { IDropdownItem } from '@/lib/Dropdown';
import { isEqual, t } from '@/utils';
import { Alpha2CountryFormat, CoordinateFormat, hasOneValueWithFormat, IndustryCodeFormat, ISiteFileData,
         ISiteMappings, LatCommaLongFormat, LatLngImportMode } from './util';
import { mdiInformationOutline, mdiMicrosoftExcel, mdiArrowRightBoldOutline } from '@mdi/js';
import MappingRow from './MappingRow';
import {LogoFullGraphic} from '@/lib/Logo';


interface IMappingStepProps {
    fileName: string
    fileData: ISiteFileData
    mappings: ISiteMappings
    latLngImportMode: LatLngImportMode
    setMappings: (mappings: ISiteMappings) => void
    setLatLngImportMode: (mode: LatLngImportMode) => void
    onNext: (mappingsChanged: boolean) => void
    cancel: () => void
}

interface IMappingErrors {
    city: string
    country: string
    industry: string
    lat: string
    location: string
    long: string
    name: string
    street: string
    zip: string
}

const hasOneValueWithValidMapping = (mapping: string, fileData: ISiteFileData, format: RegExp): boolean => {
    return mapping && hasOneValueWithFormat(format, Object.values(fileData[mapping]));
};

const hasValidMappings = (mode: LatLngImportMode, mappings: ISiteMappings, fileData: ISiteFileData) => {
    if (!mappings.name || !hasLocationMappings(mappings)) {
        return false;
    }

    if (mappings.industry) {
        return hasOneValueWithValidMapping(mappings.industry, fileData, IndustryCodeFormat);
    }

    if (mode === 'combined') {
        if (!hasOneValueWithValidMapping(mappings.location, fileData, LatCommaLongFormat)) {
            return false;
        }
    }
    else if (mode === 'separate') {
        if (!hasOneValueWithValidMapping(mappings.lat, fileData, CoordinateFormat) || !hasOneValueWithValidMapping(mappings.long, fileData, CoordinateFormat)) {
            return false;
        }
    }
    else if (mode === 'unsupplied') {
        if (!['name', 'street', 'city', 'zip'].every(k => (mappings as unknown as Record<string, unknown>)[k] !== undefined)) {
            return false;
        }
    }

    if (mappings.country) {
        return hasOneValueWithValidMapping(mappings.country, fileData, Alpha2CountryFormat);
    }

    return true;
};

const hasLocationMappings = (mappings: ISiteMappings) => {
    if (mappings.location || (mappings.lat && mappings.long)) {
        return true;
    }
    else {
        return mappings.street && mappings.city && mappings.zip;
    }
};

const getCoordinateMappingError = (mapping: string, fileData: ISiteFileData, format: RegExp): string => {
    return !mapping
        ? t('error.required')
        : hasOneValueWithValidMapping(mapping, fileData, format) ? null : t('ui.sites_import.coordinate_mapping_error');
};

export default function SiteImportMappingStep(props: IMappingStepProps): JSX.Element {
    const { state } = React.useContext(AppContext);
    const theme = useTheme();
    const nameMappingRef = React.useRef<HTMLInputElement>();
    const locationMappingRef = React.useRef<HTMLInputElement>();
    const latMappingRef = React.useRef<HTMLInputElement>();
    const longMappingRef = React.useRef<HTMLInputElement>();
    const industryMappingRef = React.useRef<HTMLInputElement>();
    const countryMappingRef = React.useRef<HTMLInputElement>();
    const streetMappingRef = React.useRef<HTMLInputElement>();
    const cityMappingRef = React.useRef<HTMLInputElement>();
    const zipMappingRef = React.useRef<HTMLInputElement>();
    const [initialMappings, setInitialMappings] = React.useState<ISiteMappings>(undefined);
    const [errors, setErrors] = React.useState<IMappingErrors>({} as IMappingErrors);
    const [columns, setColumns] = React.useState<IDropdownItem[]>(undefined);
    const [amountOfRows, setAmountOfRows] = React.useState<string>(undefined);

    const subheadingColSpan = React.useMemo<number>(() => state.layoutSize === 'xs' ? 2 : 3, [state.layoutSize]);

    React.useEffect(() => {
        setInitialMappings(props.mappings);
    }, []);

    React.useEffect(() => {
        const keys = Object.keys(props.fileData);
        createColumns(keys);
        setAmountOfRows(Object.keys(props.fileData[keys[0]]).length.toString());
        setErrors({} as IMappingErrors);
    }, [props.fileData]);

    const createColumns = (keys: string[]) => {
        const columns = keys.map(key => {
            return { id: key, label: key } as IDropdownItem;
        });
        columns.unshift({ id: '', label: '—' });

        setColumns(columns);
    };

    const onSetLatLngImportMode = (mode: LatLngImportMode) => {
        props.setLatLngImportMode(mode);
        props.setMappings({ ...props.mappings, lat: undefined, long: undefined, location: undefined });
    };

    const onNameMappingChange = (value: string) => {
        props.setMappings({ ...props.mappings, name: value });
        setErrors({ ...errors, name: (value ? null : t('error.required')) });
    };

    const onOptionalMappingWithFormatChange = (mapping: string, value: string, format: RegExp, errorText: string) => {
        props.setMappings({ ...props.mappings, [mapping]: value });
        const mappingValid = !value || hasOneValueWithValidMapping(value, props.fileData, format);
        setErrors({ ...errors, [mapping]: !mappingValid ? errorText : null });
    };

    const onCoordinateMappingChange = (mapping: string, value: string, format: RegExp) => {
        props.setMappings({ ...props.mappings, [mapping]: value });
        setErrors({ ...errors, [mapping]: getCoordinateMappingError(value, props.fileData, format) });
    };

    const mappingsChanged = (): boolean => {
        return !isEqual(props.mappings, initialMappings);
    };

    const createSiteData = () => {
        if (hasValidMappings(props.latLngImportMode, props.mappings, props.fileData)) {
            props.onNext(mappingsChanged());
        }
        else {
            handleErrors();
        }
    };

    const handleErrors = () => {
        const m = props.mappings;
        const errors = {
            long: props.latLngImportMode === 'separate' ? getCoordinateMappingError(m.long, props.fileData, CoordinateFormat) : null,
            lat: props.latLngImportMode === 'separate' ? getCoordinateMappingError(m.lat, props.fileData, CoordinateFormat) : null,
            name: !m.name ? t('error.required') : null,
            location: props.latLngImportMode === 'combined' ? getCoordinateMappingError(m.location, props.fileData, LatCommaLongFormat) : null,
            country: !m.country || hasOneValueWithValidMapping(m.country, props.fileData, Alpha2CountryFormat) ? null : t('ui.sites_import.country_mapping_error'),
            industry: !m.industry || hasOneValueWithValidMapping(m.industry, props.fileData, IndustryCodeFormat) ? null : t('ui.sites_import.industry_mapping_error'),
        } as IMappingErrors;

        if (props.latLngImportMode === 'unsupplied') {
            if (!m.street) {
                errors.street = t('ui.required_mapping_missing_error');
            }
            else if (!m.city) {
                errors.city = t('ui.required_mapping_missing_error');
            }
            else if (!m.zip) {
                errors.zip = t('ui.required_mapping_missing_error');
            }
        }

        setErrors(errors);

        if (!props.mappings.name) {
            nameMappingRef.current.focus();
        }
        else if (props.latLngImportMode === 'combined') {
            if (!props.mappings.location || !hasOneValueWithValidMapping(props.mappings.location, props.fileData, LatCommaLongFormat)) {
                locationMappingRef.current.focus();
            }
        }
        else if (props.latLngImportMode === 'separate') {
            if (!props.mappings.lat || !hasOneValueWithValidMapping(props.mappings.lat, props.fileData, CoordinateFormat)) {
                latMappingRef.current.focus();
            }
            else if (!props.mappings.long || !hasOneValueWithValidMapping(props.mappings.long, props.fileData, CoordinateFormat)) {
                longMappingRef.current.focus();
            }
        }
        else if (props.latLngImportMode === 'unsupplied') {
            for (const item of [['name', nameMappingRef], ['street', streetMappingRef], ['city', cityMappingRef], ['zip', zipMappingRef]]) {
                const k = item[0] as string;
                const f = item[1] as React.MutableRefObject<HTMLInputElement>;

                if ((props.mappings as unknown as Record<string, unknown>)[k] === undefined) {
                    f.current.focus();
                    return;
                }
            }
        }
        else if (props.mappings.industry && !hasOneValueWithValidMapping(props.mappings.industry, props.fileData, IndustryCodeFormat)) {
            industryMappingRef.current.focus();
        }
        else if (props.mappings.country && !hasOneValueWithValidMapping(props.mappings.country, props.fileData, Alpha2CountryFormat)) {
            countryMappingRef.current.focus();
        }
    };

    const getFieldExamples = (field: string) => {
        if (!props.fileData[field]) {
            return '';
        }

        const firstThreeValues = Object.values(props.fileData[field]).filter(value => !!value).slice(0, 3);

        return <div className="s-site-import-mappings-info">{firstThreeValues.map((v, ix) => <div key={ix}>{v}</div>)}</div>;
    };

    return (
        <div>
            <div className="s-site-import-file-info">
                <Icon path={mdiInformationOutline} />

                {columns && amountOfRows &&
                    t('ui.sites_import.file_columns_rows_info')
                        .replace('{1}', (columns.length - 1).toString())
                        .replace('{2}', amountOfRows)
                }
            </div>
            <table className="s-table s-site-import-mappings-table">
                <colgroup>
                    <col />
                    <col style={{ backgroundColor: '#FFF' }} />
                    <col />
                </colgroup>
                <thead>
                    <tr>
                        <th scope="col">
                            <div className="s-site-import-mappings-table-heading-item">
                                <div className="s-site-import-mappings-table-heading-item-file">
                                    <Icon color="#1D6F42" path={mdiMicrosoftExcel} size={48} />
                                    <div className="s-site-import-mappings-table-heading-filename">{props.fileName}</div>
                                </div>
                                <div>{t('ui.sites_import.csv_field')}</div>
                            </div>
                        </th>
                        <th scope="col">
                            <div className="s-site-import-mappings-table-heading-item">
                                <Icon path={mdiArrowRightBoldOutline} size={48} color={theme.tintColor} style={{ margin: 'auto', marginTop: '0' }} />
                                <div className="s-font-small s-site-import-mappings-table-heading-text">
                                    {t('ui.sites_import.example_heading')}
                                </div>
                            </div>
                        </th>
                        <th scope="col">
                            <div className="s-site-import-mappings-table-heading-item">
                                <div className="s-logo" style={{ marginTop: '.5rem' }}><LogoFullGraphic variant="black" size={1.5} /></div>
                                <div className="s-font-small s-site-import-mappings-table-heading-text">{t('ui.sites_import.site_field')}</div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th colSpan={subheadingColSpan} className="s-site-import-mappings-table-subheading" scope="colgroup">
                            {t('ui.sites_import.first_mapping_group')}
                        </th>
                    </tr>
                    <MappingRow
                        ref={nameMappingRef}
                        id="site-mapping-name"
                        examples={getFieldExamples(props.mappings.name)}
                        dropdownlabel={props.mappings.name}
                        kuyuaLabel={t('ui.name')}
                        options={columns}
                        error={errors.name}
                        onChange={item => onNameMappingChange(item)}
                    >
                        <div>
                            {t('ui.required')}
                        </div>
                    </MappingRow>
                    <MappingRow
                        ref={industryMappingRef}
                        id="site-mapping-industry"
                        examples={getFieldExamples(props.mappings.industry)}
                        dropdownlabel={props.mappings.industry}
                        kuyuaLabel={t('ui.industry')}
                        options={columns}
                        error={errors.industry}
                        onChange={item => onOptionalMappingWithFormatChange('industry', item, IndustryCodeFormat, t('ui.sites_import.industry_mapping_error'))}
                    >
                        <div>
                            {t('ui.sites_import.required_for_site_creation')}. {t('ui.sites_import.industry_format')}.
                        </div>
                    </MappingRow>
                    <tr>
                        <th colSpan={subheadingColSpan} className="s-site-import-mappings-table-subheading" scope="colgroup">
                            {t('ui.location')}
                            <div className="s-font-small">
                                {t('ui.sites_import.required_location')}
                            </div>
                            <div className="s-site-import-segment">
                                <SegmentedButton buttons={[
                                    { active: props.latLngImportMode === 'separate', id: 'separate', label: t('ui.separate_coordinate_field_plural') },
                                    { active: props.latLngImportMode === 'combined', id: 'combined', label: t('ui.single_coordinates_field') },
                                    { active: props.latLngImportMode === 'unsupplied', id: 'unsupplied', label: t('ui.not_supplied') },
                                ]} onClick={b => onSetLatLngImportMode(b.id as LatLngImportMode)} />
                            </div>
                        </th>
                    </tr>

                    {props.latLngImportMode === 'combined' && <MappingRow
                        ref={locationMappingRef}
                        id="site-mapping-location"
                        examples={getFieldExamples(props.mappings.location)}
                        dropdownlabel={props.mappings.location}
                        kuyuaLabel={t('ui.coordinate_plural')}
                        options={columns}
                        error={errors.location}
                        onChange={item => onCoordinateMappingChange('location', item, LatCommaLongFormat)}>
                        <div>{t('ui.required')}. {t('ui.sites_import.lat_long_format')}.</div>
                    </MappingRow>}

                    {props.latLngImportMode === 'separate' && <>
                        <MappingRow
                            ref={latMappingRef}
                            id="site-mapping-latitude"
                            examples={getFieldExamples(props.mappings.lat)}
                            dropdownlabel={props.mappings.lat}
                            kuyuaLabel={t('ui.lat')}
                            options={columns}
                            error={errors.lat}
                            onChange={item => onCoordinateMappingChange('lat', item, CoordinateFormat)}>
                            <div>{t('ui.required')}. {t('ui.sites_import.coordinate_format')}.</div>
                        </MappingRow>
                        <MappingRow
                            ref={longMappingRef}
                            id="site-mapping-longitude"
                            examples={getFieldExamples(props.mappings.long)}
                            dropdownlabel={props.mappings.long}
                            kuyuaLabel={t('ui.long')}
                            options={columns}
                            error={errors.long}
                            onChange={item => onCoordinateMappingChange('long', item, CoordinateFormat)}>
                            <div>{t('ui.required')}. {t('ui.sites_import.coordinate_format')}.</div>
                        </MappingRow>
                    </>}
                    <tr>
                        <th colSpan={subheadingColSpan} className="s-site-import-mappings-table-subheading" scope="colgroup">
                            {t('ui.post_address')}
                            <div className="s-font-small">
                                {t('ui.sites_import.required_location')}
                            </div>
                        </th>
                    </tr>
                    <MappingRow
                        ref={streetMappingRef}
                        id="site-mapping-street"
                        error={errors.street}
                        examples={getFieldExamples(props.mappings.street)}
                        dropdownlabel={props.mappings.street}
                        kuyuaLabel={t('ui.street_address')}
                        options={columns}
                        onChange={item => props.setMappings({ ...props.mappings, street: item })}
                    >
                        {props.latLngImportMode === 'unsupplied' && <div>
                            {t('ui.required')}
                        </div>}
                    </MappingRow>
                    <MappingRow
                        ref={cityMappingRef}
                        id="site-mapping-city"
                        error={errors.city}
                        examples={getFieldExamples(props.mappings.city)}
                        dropdownlabel={props.mappings.city}
                        kuyuaLabel={t('ui.city')}
                        options={columns}
                        onChange={item => props.setMappings({ ...props.mappings, city: item })}
                    >
                        {props.latLngImportMode === 'unsupplied' && <div>
                            {t('ui.required')}
                        </div>}
                    </MappingRow>

                    <MappingRow
                        ref={zipMappingRef}
                        id="site-mapping-zip"
                        error={errors.zip}
                        examples={getFieldExamples(props.mappings.zip)}
                        dropdownlabel={props.mappings.zip}
                        kuyuaLabel={t('ui.zip_code')}
                        options={columns}
                        onChange={item => props.setMappings({ ...props.mappings, zip: item })}
                    >
                        {props.latLngImportMode === 'unsupplied' && <div>
                            {t('ui.required')}
                        </div>}
                    </MappingRow>
                    <MappingRow
                        id="site-mapping-province"
                        examples={getFieldExamples(props.mappings.province)}
                        dropdownlabel={props.mappings.province}
                        kuyuaLabel={t('ui.province')}
                        options={columns}
                        onChange={item => props.setMappings({ ...props.mappings, province: item })}
                    />
                    <MappingRow
                        ref={countryMappingRef}
                        id="site-mapping-country"
                        examples={getFieldExamples(props.mappings.country)}
                        dropdownlabel={props.mappings.country}
                        kuyuaLabel={t('ui.country_code')}
                        options={columns}
                        error={errors.country}
                        onChange={item => onOptionalMappingWithFormatChange('country', item, Alpha2CountryFormat, t('ui.sites_import.country_mapping_error'))}
                    >
                        <div>
                            {t('ui.sites_import.required_for_site_creation')}. {t('ui.sites_import.country_format')}.
                        </div>
                    </MappingRow>
                </tbody>
            </table>

            <div className="s-site-import-button-container">
                <Button onClick={props.cancel}>{t('actions.cancel')}</Button>
                <Button onClick={createSiteData} variant="primary">{t('ui.sites_import.process')}</Button>
            </div>
        </div>
    );
}
