import * as React from 'react';
import { mdiMagnify } from '@mdi/js';
import { AppContext } from '@/context';
import { externalHttpGet, t } from '@/utils';
import { Icon, TextInput } from '@/lib';
import { MapboxApiEndpoint } from '@/lib/map';


interface IExploreMapTopPanelProps {
    onSearchComplete: (data: {
        bbox: number[]
        center: ILatLng
        pointOfInterest: ILatLng
        zoom: number
    }) => void
}


export default function ExploreMapTopPanel(props: IExploreMapTopPanelProps): JSX.Element {
    const { state } = React.useContext(AppContext);
    const [searchText, setSearchText] = React.useState<string>('');
    const onPerformSearch = () => {
        let text = searchText.trim();

        if (text.length === 0) {
            return;
        }

        if (/^\s*-?([1-9]\d*|0)(\.\d+)?,\s*-?([1-9]\d*|0)(\.\d+)?\s*$/.test(text)) {
            // Mapbox api expects in longitude, latitude order when sought using coordinates, but users tend to supply
            // it in reverse order, which is more familiar

            let [lat, lng] = text.split(',');

            lat = lat.trim();
            lng = lng.trim();
            text = `${lng},${lat}`;
        }

        const url = `${MapboxApiEndpoint}/${encodeURIComponent(text)}.json`;
        const params = { access_token: state.config.mapbox_public_api_token };

        externalHttpGet<IMapboxQueryResult>(url, params).then(reply => {
            for (const feat of reply.features) {
                if (feat.bbox && feat.center) {
                    const center = { lat: feat.center[1], lng: feat.center[0] };

                    props.onSearchComplete({
                        bbox: feat.bbox,
                        center: center,
                        pointOfInterest: center,
                        zoom: undefined,
                    });

                    return;
                }
                else if (feat.center) {

                    const center = { lat: feat.center[1], lng: feat.center[0] };

                    props.onSearchComplete({
                        bbox: undefined,
                        center: center,
                        pointOfInterest: center,
                        zoom: 13,
                    });

                    return;
                }
            }
        });
    };

    return (
        <div className={'s-explore-map-search-box s-explore-map-search-box-' + state.layoutSize}>
            <TextInput
                id="map-search-box"
                ariaLabel={t('actions.search')}
                onEnter={onPerformSearch}
                onInput={setSearchText}
                leftAccessory={<Icon color="#999" path={mdiMagnify} size={24} style={{ marginRight: '.5rem' }} />}
                placeholder="Type an address to find a location…"
                style={{ boxShadow: '0 2px 4px 0px rgba(0 0 0 / 0.1)', width: '24rem' }}
                value={searchText} />
        </div>
    );
}
