import { colors, style, StyleSheet } from "@/utils/style";
import React from "react";
import { PrioritySitesInfo } from "@/project/sleap/Assess/InfoBox/PrioritySitesInfo";
import { ImpactSitesInfo } from "@/project/sleap/Assess/InfoBox/ImpactSitesInfo";
import { DependencySitesInfo } from "@/project/sleap/Assess/InfoBox/DependencySitesInfo";
import { SiteInfoTabProp, Tab, TabItemProps } from "@/types/sleap";

const tabs: Tab[] = [
  {
    id: 1,
    label: 'priority locations',
    color: colors.purple,
    template: PrioritySitesInfo,
  }, {
    id: 2,
    label: 'impacts',
    color: colors.red,
    template: ImpactSitesInfo,
  }, {
    id: 3,
    label: 'dependencies',
    color: colors.yellow,
    textColor: colors.black,
    template: DependencySitesInfo,
  },
];

export const AssessmentInfoBox: React.FC<SiteInfoTabProp> = ({ onFilter, unsetPosition }) => {
  const [currentTabId, setCurrentTabId] = React.useState(1);

  const currentTab = tabs.find(tab => tab.id === currentTabId);
  const Template = currentTab.template;

  return (
    <div style={{ ...styles.sleapSectionRight, backgroundColor: 'unset', ...(unsetPosition && { position: 'unset', overflow: 'unset', transform: 'unset' }) }}>
      <div style={{ display: 'flex', flexDirection: 'row', padding: '0 10px' }}>
        {tabs.map(t => <TabItem
            key={t.id}
            {...t}
            onClick={setCurrentTabId}
        />)}
      </div>
      <div style={{ ...style.flexRow, ...styles.infoBox, border: `2px solid ${currentTab.color}` }}>
        {<Template onFilter={onFilter} />}
      </div>
    </div>
  );
};

const TabItem: React.FC<TabItemProps> = ({ color, label, onClick, id, textColor }) => {

  return (
    <div
      style={{ ...style.flexRow, ...styles.tabItem, backgroundColor: color, color: textColor ?? 'white' }}
      onClick={() => onClick(id)}
    >
      { label.toUpperCase() }
    </div>
  );
};

const styles = StyleSheet.create({
  infoBox: {
    height: 180,
    backgroundColor: 'rgba(32, 51, 77, 0.95)',
    borderRadius: 10,
    alignItems: 'center',
    padding: 20,
  },
  tabItem: {
    height: 20,
    borderRadius: '10px 10px 0px 0px',
    justifyContent: 'center',
    fontSize: 12,
    width: 'unset',
    padding: '0 20px',
  },
   sleapSectionRight: {
        position: 'fixed',
        bottom: 200,
        right: '30vW',
        transform: 'translateX(50%)',
        backgroundColor: 'rgba(32, 51, 77, 0.95)',
        zIndex: 2,
        width: '600px',
        borderRadius: '10px',
        overflow: 'hidden',
        maxWidth: 900,
    },
});
