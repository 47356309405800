import { NotificationRemoved, NotificationsChanged } from '@/context';
import { apiDelete, apiGet } from '@/utils';


type IGenericDispatch = (action: IGenericAction) => void;


export async function updateNotifications(state: IAppState, dispatch: IGenericDispatch, project: IProject): Promise<INotification[] | null> {
    const path = `projects/${project.slug}/notifications`;
    const reply = await apiGet<INotification[]>(state.config.team.slug, path);

    if (reply.ok) {
        dispatch({ type: NotificationsChanged, notifications: reply.data });
    }

    return reply.data;
}


export async function dismissNotification(state: IAppState, dispatch: IGenericDispatch, project: IProject, notification: INotification): Promise<boolean> {
    const path = `projects/${project.slug}/notifications/${notification.id}`;
    const reply = await apiDelete(path, state.config.team.slug);

    if (reply.ok) {
        dispatch({ type: NotificationRemoved, notification });
    }

    return reply.ok;
}
