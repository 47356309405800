import * as React from 'react';
import { AppContext, ToastAdded, useProject, useTeam } from '@/context';
import { LoadingAnimation, SectionHeader } from '@/lib';
import { MapboxApiEndpoint, parseAddress } from '@/lib/map';
import { apiPost, externalHttpGet, t } from '@/utils';
import SitePropertiesForm from '../site/SitePropertiesForm';
import { updateReport } from '@/resources';


interface IAddToSitesProps {
    center: ILatLng
    onClose: () => void
    onPathChange: (path: (number | string)[]) => void
    path: (number | string)[]
    style?: React.CSSProperties
}


export default function AddToSites(props: IAddToSitesProps): JSX.Element {
    const { dispatch, state } = React.useContext(AppContext);
    const team = useTeam();
    const project = useProject();
    const [isResolving, setIsResolving] = React.useState<boolean>(true);
    const [isSaving, setIsSaving] = React.useState<boolean>(false);
    const [data, setData] = React.useState<IAddToSitesData>(undefined);
    const onCreateSite = () => {
        setIsSaving(true);
        apiPost<ISite>(team.slug, `projects/${project.slug}/reports/${project.latest_report_id}/sites/create`, data).then(reply => {
            if (reply.ok) {
                dispatch({ type: ToastAdded, toast: { kind: 'success', text: t('ui.site_added') } });
                updateReport(state, dispatch, project, project.latest_report_id, true);
                props.onClose();
            }
            else {
                dispatch({ type: ToastAdded, toast: { kind: 'error', text: t('ui.failed_to_add_site') } });
            }
        }).finally(() => setIsSaving(false));
    };

    React.useEffect(() => {
        props.onPathChange(['add', 'loading']);

        setIsResolving(true);
        setData({
            industry: undefined,
            location: { address: undefined, center: props.center },
        });

        if (!props.center) {
            return;
        }

        const { lat, lng } = props.center;
        const url = `${MapboxApiEndpoint}/${lng},${lat}.json`;
        const params = { access_token: state.config.mapbox_public_api_token };

        externalHttpGet<IMapboxQueryResult>(url, params).then(reply => {
            setIsResolving(false);
            setData(data => ({
                ...data,
                location: { ...data.location, address: parseAddress(reply) },
            }));

            props.onPathChange(['add', 'form']);
        });
    }, [props.center]);

    if (isResolving) {
        return <LoadingAnimation />;
    }

    return (
        <div style={props.style}>
            <SectionHeader
                bordered={false}
                level={3}
                style={{ margin: 'auto', marginBottom: '1.5rem', maxWidth: '50rem' }}
                text={t('ui.add_new_site')}
            />
            <SitePropertiesForm
                data={data}
                setData={setData}
                onCancel={() => props.onPathChange(['stats'])}
                onSubmit={onCreateSite}
                submitText={t('actions.add')}
                isSaving={isSaving}
            ></SitePropertiesForm>
        </div>
    );
}
