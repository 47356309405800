import * as React from 'react';
import { useParams } from 'react-router';


interface ISpeciesImageProps {
    className?: string
    height: number | string
    species?: ISpecies
    style?: React.CSSProperties
    width: number | string
}


function isElementVisible(r: DOMRect): boolean {
    return r.top >= 0 &&
        r.left >= 0 &&
        r.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        r.right <= (window.innerWidth || document.documentElement.clientWidth);
}


export default function SpeciesImage(props: ISpeciesImageProps): JSX.Element {
    const [isVisible, _setIsVisible] = React.useState<boolean>(false);
    const isVisibleRef = React.useRef(isVisible);
    const setIsVisible = (visible: boolean) => {
        isVisibleRef.current = visible;
        _setIsVisible(visible);
    };
    const params = useParams();
    const elementRef = React.useRef(null);
    const url = isVisibleRef.current && props.species ? `/api/v1/teams/${params.team}/projects/${params.project}/species/${props.species.id}/image` : null;
    const rules = {
        background: url ? `#D3D3D3 no-repeat center / cover url(${url})` : '#F6F6F6',
        filter: url ? 'brightness(1.15) grayscale(15%)' : 'none',
        flexShrink: 0,
        flexGrow: 0,
        width: typeof props.width === 'number' ? props.width + 'px' : props.width,
        height: typeof props.height === 'number' ? props.height + 'px' : props.height,
        ...props.style,
    };
    const onScroll = () => {
        const r = elementRef.current?.getBoundingClientRect();
        const v = r ? isElementVisible(r) : false;

        if (v && !isVisibleRef.current) {
            setIsVisible(true);

            let p = elementRef.current.offsetParent;

            while (p) {
                p.removeEventListener('scroll', onScroll);
                p = p.offsetParent;
            }

            window.removeEventListener('scroll', onScroll);
        }
    };

    React.useEffect(() => {
        if (!elementRef.current) {
            return;
        }

        let p = elementRef.current.offsetParent;

        while (p) {
            p.addEventListener('scroll', onScroll, { passive: true });
            p = p.offsetParent;
        }

        window.addEventListener('scroll', onScroll, { passive: true });

        onScroll();
    }, []);

    return <div className={props.className} ref={elementRef} style={rules} />;
}
