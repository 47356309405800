import * as React from 'react';


interface ITitleProps {
    text: string
    style?: React.CSSProperties
}


export default function Title(props: ITitleProps): JSX.Element {
    return <div className="s-title" style={props.style}>{props.text}</div>;
}
