import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {mdiLayersTripleOutline, mdiMinus, mdiPlus} from '@mdi/js';
import Icon from '../Icon';


interface IMapZoomPickerProps {
    onStyleClick?: () => void
    onZoomClick: (what: 'minus' | 'plus') => void
    targetElementId: string,
    toggleDataLayerModal: () => void
}

interface IMapZoomPickerState {
    targetElementId: string,
}

export default class MapZoomPicker extends React.Component<IMapZoomPickerProps, IMapZoomPickerState> {
    el: HTMLDivElement;


    constructor(props: IMapZoomPickerProps) {
        super(props);

        this.el = document.createElement('div');
        this.state = {
            targetElementId: props.targetElementId,
        };
    }


    componentDidMount(): void {
        if (!this.el.parentElement) {
            document.querySelector('#' + this.state.targetElementId)?.appendChild(this.el);
        }
    }


    componentDidUpdate(): void {
        if (!this.el.parentElement) {
            document.querySelector('#' + this.state.targetElementId)?.appendChild(this.el);
        }
    }


    componentWillUnmount(): void {
        try {
            document.querySelector('#' + this.state.targetElementId)?.removeChild(this.el);
        }
        catch (err) {  // eslint-ignore: no-empty
        }
    }

    render(): JSX.Element {


        return ReactDOM.createPortal(
            <div className="mapboxgl-ctrl">
                <div className="mapboxgl-ctrl-group" style={{ marginRight: '10px', marginBottom: '10px' }}>
                    <div className="s-map-icon-button" onClick={this.props.toggleDataLayerModal}>
                        <Icon path={mdiLayersTripleOutline} size={24} />
                    </div>
                    <div className="s-map-icon-button" onClick={() => this.props.onZoomClick('plus')}>
                        <Icon path={mdiPlus} size={16} />
                    </div>
                    <div className="s-map-icon-button" onClick={() => this.props.onZoomClick('minus')}>
                        <Icon path={mdiMinus} size={16} />
                    </div>
                </div>
            </div>,
            this.el,
        );
    }
}
