import * as React from 'react';
import Button from './Button';
import HorizontalStack from './HorizontalStack';


interface ISegmentedButtonSegment {
    active?: boolean
    id: number | string
    label: string
}

interface ISegmentedButtonProps {
    buttons: ISegmentedButtonSegment[]
    onClick: (button: ISegmentedButtonSegment) => void
    size?: string
}


export default function SegmentedButton(props: ISegmentedButtonProps): JSX.Element {
    return (
        <HorizontalStack className="s-segmented-button" horizontalAlign="center" spacing="none">
            {props.buttons.map(b => <Button
                active={b.active}
                key={b.id}
                onClick={() => props.onClick(b)}
                size="sm">{b.label}</Button>)}
        </HorizontalStack>
    );
}
