import * as React from 'react';
import MD from 'markdown-it';


interface IMarkdownProps {
    md: string
    style?: React.CSSProperties
}


export default function Markdown(props: IMarkdownProps): JSX.Element {
    const __html = MD({ html: true, linkify: true, typographer: true }).render(props.md);

    return (
        <div className="s-markdown" style={props.style} dangerouslySetInnerHTML={{ __html }} />
    );
}
