import React, { useContext } from "react";
import {
    AppContext,
    ImpactsAndDependenciesChanges,
    SiteDeleted,
    SiteUpdated,
    ToastAdded,
    useProject,
    useTeam
} from "@/context";
import { apiDelete, apiPatch } from "@/utils/http";
import { fetchImpactsAndDependencies, url } from "@/rest/apiHelper";
import { colors, style, StyleSheet } from "@/utils/style";
import Icon from "../../../lib/Icon";
import { mdiPencilOutline, mdiTrashCanOutline } from "@mdi/js";
import LocationForm from "@/project/sleap/Locate/LocationForm";
import { DeleteLocationForm } from "@/project/sleap/Locate/DeleteLocationForm";
import { SleapContext } from "@/context/SleapContext";
import { LocationTileProps } from "@/types/sleap";

export const LocationTile: React.FC<LocationTileProps> = ({ site, scopeOptions, openHandler, currentOpen }) => {

    const [openEdit, setOpenEdit] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const team = useTeam();
    const project = useProject();
    const report = project.reports[project.latest_report_id] as IAssessmentReport;
    const { sleapDispatch } = React.useContext(SleapContext);
    const { dispatch } = useContext(AppContext);

    function saveLocation(s: INewSiteRequest): void {

        apiPatch(team.slug, url.site(report.id, site.id, project.slug), s).then((reply) => {
            if (reply.ok) {

                dispatch({ type: SiteUpdated, site: reply.data });
                dispatch({ type: ToastAdded, toast: { kind: 'success', text: 'Site updated successfully.' } });
                fetchImpactsAndDependencies(team.slug, project.slug).then(reply => {
                    dispatch({ type: ImpactsAndDependenciesChanges, impacts: reply.impacts, dependencies: reply.dependencies });
                });
                setOpenEdit(false);
                openHandler(0);
                sleapDispatch({ zoom: 2, selectedCenter: undefined });

            } else {
                dispatch({ type: ToastAdded, toast: { kind: 'error', text: `Failed to update site!` } });
            }
        });
    }

    function deleteLocation(): void {

        apiDelete<{ ok: boolean }>(url.site(report.id, site.id, project.slug), team.slug).then(reply => {
            if (reply.ok) {

                dispatch({ type: SiteDeleted, siteId: site.id })
                dispatch({ type: ToastAdded, toast: { kind: 'success', text: 'Site deleted successfully.' } });
                setOpenDelete(false);
                fetchImpactsAndDependencies(team.slug, project.slug).then(reply => {
                    dispatch({ type: ImpactsAndDependenciesChanges, impacts: reply.impacts, dependencies: reply.dependencies });
                });
            } else {
                dispatch({ type: ToastAdded, toast: { kind: 'error', text: `Failed to delete site!` } });
            }
        });
    }

    React.useEffect(() => {

        if (openEdit) {
            openHandler(site.id);
        }
    }, [openEdit]);

    React.useEffect(() => {

        setOpenEdit(site.id === currentOpen);
    }, [currentOpen]);

    const closeForm = () => {

        setOpenEdit(false);
        openHandler(0);
        sleapDispatch({ unsetZoom: true, selectedCenter: undefined, selectedSiteId: undefined });
    };

    const setTargetLocationSite = (): void => {

      sleapDispatch({ targetSiteId: site.id });
    };

    return (
      <div style={styles.locationTile} onClick={setTargetLocationSite}>
          <div style={{ flex: 1 }}>
              {site.name}
          </div>
          <div style={{ flex: 1, ...style.centerFlex, justifyContent: 'space-between' }}>
              {site.site_id}
              <div style={{ ...style.centerFlex, marginLeft: 'auto' }}>
                  <Icon path={mdiPencilOutline} color={'white'} size={20} style={{ marginRight: 10 }} onClick={() => setOpenEdit(p => !p)} />
                  <Icon path={mdiTrashCanOutline} color={'white'} size={20} onClick={() => setOpenDelete(p => !p)} />
              </div>
          </div>
          {openEdit && (
              <LocationForm
                siteData={site}
                saveHandler={saveLocation}
                closeHandler={closeForm}
                scopeOptions={scopeOptions}
              />
          )}
          {openDelete && (
              <DeleteLocationForm
                  siteName={site.name}
                  siteId={site.site_id}
                  deleteHandler={deleteLocation}
                  closeHandler={() => setOpenDelete(false)}
              />
          )}
      </div>
    );
};

const styles = StyleSheet.create({
    locationTile: {
        backgroundColor: colors.mediumBlue,
        height: 60,
        width: '100%',
        color: 'white',
        padding: '20px 15px',
        fontSize: 12,
        display: 'flex',
        flexDirection: 'row',
    },
});
