import * as React from 'react';
import { AppContext, ToastRemoved } from '@/context';
import Toast from './Toast';


export default function ToastManager(): JSX.Element {
    const { dispatch, state } = React.useContext(AppContext);

    return (
        <>
            {state.toasts.map(t => <Toast
                key={t.id}
                kind={t.kind}
                text={t.text}
                onClose={() => dispatch({ type: ToastRemoved, toast: t })} />)}
        </>
    );
}
