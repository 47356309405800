import * as React from 'react';
import classnames from 'classnames';
import { SortOrder } from '@/utils/sort';


interface ITableHeaderCellProps {
    columnBackgroundColor?: string
    colSpan?: number
    label: string
    labelStyle?: React.CSSProperties
    onClick?: (sortBy: SortOrder) => void
    sortable?: boolean
    sortedBy?: SortOrder | undefined
    style?: React.CSSProperties
    textAlign?: 'end'
    verticalAlign?: 'bottom' | 'middle' | 'top'
    width?: string
}

export type TableHeaderCellValue = string | ITableHeaderCellProps;


export default function TableHeaderCell(props: ITableHeaderCellProps): JSX.Element {
    const isSortable = props.sortable === undefined || props.sortable;
    const isAscending = props.sortedBy === 'asc';
    const classes = classnames('s-table-heading', {
        's-table-heading-sortable': isSortable,
        ['s-table-heading-' + props.verticalAlign]: props.verticalAlign,
    });

    const HeaderCell = (props.sortable ? 'button' : 'span') as keyof JSX.IntrinsicElements;

    return (
        <th
            className={classes}
            colSpan={props.colSpan || 1}
            style={{ ...props.style, width: props.width }}>
            <HeaderCell
                className={`s-table-heading-button ${props.textAlign === 'end' ? 's-table-heading-button--align-right' : ''}`}
                onClick={() => props.onClick?.(isAscending ? 'desc' : 'asc')}
                style={props.labelStyle}
                tabIndex={isSortable ? 0 : -1}>
                {props.label}
                {isSortable && <span className={`s-arrow-small-grey ${!props.sortedBy ? 's-arrow-small-grey-hidden' : ''} `}>
                    {isAscending ? '▲' : '▼'}
                </span>}
            </HeaderCell>
        </th>
    );
}
