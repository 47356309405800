import * as React from "react";
import Bubble from "@/lib/Bubble";
import { colors, StyleSheet } from "@/utils/style";
import { Bar } from "@/project/sleap/Assess/AssessTile";
import { useDependencies, useImpacts } from "@/context";
import { getBubbleBarColor } from "@/utils/getBubbleBarColor";
import { getRatingText } from "@/utils/getRatingText";
import { Rating } from "@/types/utils";

export const MaterialityDetail: React.FC<{site: INewSite}> = ({ site }) => {
  console.log('site from materiality =====>>>> ', site);
  const impacts = useImpacts();
  const dependencies = useDependencies();

  const no_total_impacts = impacts.length;
  const no_site_impacts = site.impacts.reduce((sum, newValue) => sum + newValue.values.length, 0);
  const no_total_dependencies = dependencies.length;
  const no_site_dependencies = site.dependencies.reduce((sum, newValue) => sum + newValue.values.length, 0);
  return (
    <div style={styles.materialBox}>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <span style={{ fontSize: 17, textTransform: 'capitalize', marginBottom: 20 }}>economic activities</span>
        <Bubble
          count={site.economic_activities.length}
          size='md'
          style={{ color: colors.white, backgroundColor: getBubbleBarColor([...site.impacts, ...site.dependencies]), marginBottom: 20 }}
        />
        <div style={styles.multipleContainer}>
          <ul style={{ margin: 'unset', padding: '0 10px' }}>
            {site.economic_activities.map((activity, i) => (<li key={i}>{activity.description}</li>))}
          </ul>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <span style={{ fontSize: 17, textTransform: 'capitalize', marginBottom: 20 }}>impacts</span>
        <Bubble
          count={no_site_impacts}
          countTotal={no_total_impacts}
          size='md'
          style={{ color: colors.white, backgroundColor: getBubbleBarColor([...site.impacts, ...site.dependencies]), marginBottom: 20 }}

        />
        <div>
          {
            site.impacts.map(impact => (
              // <Bar
              //   key={'impact_bar_' + impact.rating}
              //   style={{ paddingBottom: 5 }}
              //   backgroundColor={getBubbleBarColor([...site.impacts, ...site.dependencies])}
              //   color={colors.opaqueBlue}
              //   barColor={colors.mediumBlue}
              //   rating={impact.rating}
              //   values={impact.values}
              //   totalAvailable={no_total_impacts}
              // />
              <div style={{fontSize: '12px'}}>{getRatingText(impact.rating as Rating)}: {impact.values.length}</div>
            ))
          }
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <span style={{ fontSize: 17, textTransform: 'capitalize', marginBottom: 20 }}>dependencies</span>
        <Bubble
          count={no_site_dependencies}
          countTotal={no_total_dependencies}
          size='md'
          style={{ color: colors.white, backgroundColor: getBubbleBarColor([...site.impacts, ...site.dependencies]), marginBottom: 20 }}

        />
        <div>
          {
            site.dependencies.map(dependency => (
              // <Bar
              //   key={'impact_bar_' + dependency.rating}
              //   style={{ paddingBottom: 5 }}
              //   backgroundColor={getBubbleBarColor([...site.impacts, ...site.dependencies])}
              //   color={colors.opaqueBlue}
              //   barColor={colors.mediumBlue}
              //   rating={dependency.rating}
              //   values={dependency.values}
              //   totalAvailable={no_total_dependencies}
              // />
              <div style={{fontSize: '12px'}}>{getRatingText(dependency.rating as Rating)}: {dependency.values.length}</div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

const styles = StyleSheet.create({
  materialBox: {
    position: 'absolute',
    width: '100%',
    height: 265,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    padding: '20px 15px',
    backgroundColor: colors.opaqueBlueWithoutDependency,
    zIndex: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  multipleContainer: {
    color: 'white',
    maxHeight: 100,
    overflowY: 'scroll',
    width: 130,
    fontSize: 12,
  },
});
