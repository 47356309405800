import * as React from 'react';
import { mdiShieldAccountOutline } from '@mdi/js';
import { AppContext } from '@/context';
import { apiGet, t } from '@/utils';
import { Button, Icon, LoadingAnimation, NavLink, TableHeaderCell } from '@/lib';
import InviteTeamMember from './InviteTeamMember';
import { booleanSort, getSortBy, SortOrder, stringSort } from '@/utils/sort';


interface ITeamMembershipsReply {
    memberships: ITeamMembership[]
}

type SortType = 'name' | 'email' | 'role' | 'active';

export default function TeamMembers(): JSX.Element {
    const { state } = React.useContext(AppContext);
    const [inviteModalVisible, setInviteModalVisible] = React.useState<boolean>(false);
    const [memberships, setMemberships] = React.useState<ITeamMembership[]>(undefined);
    const canManageUsers = state.config.role.slug === 'admin';
    const [sortOrder, setSortOrder] = React.useState<SortOrder>('asc');
    const [sortType, setSortType] = React.useState<SortType>('email');
    const getFullUserName = (memberShip: ITeamMembership) => {
        return memberShip.user.first_name + " " + memberShip.user.last_name;
    };
    const sortedMemberships = React.useMemo(() => {
        const sortedMemberships = [].concat(memberships);
        sortedMemberships.sort((a, b) => {
            if (sortType === 'name') {
                return stringSort(getFullUserName(a), getFullUserName(b), sortOrder);
            }
            else if (sortType === 'email') {
                return stringSort(a.user.email, b.user.email, sortOrder);
            }
            else if (sortType === 'role') {
                return stringSort(a.role.name, b.role.name, sortOrder);
            }
            else {
                return booleanSort(a.user.is_active, b.user.is_active, sortOrder);
            }
        });
        return sortedMemberships;
    }, [sortOrder, sortType, memberships]);
    React.useEffect(() => {
        apiGet<ITeamMembershipsReply>(state.config.team.slug, 'members').then(reply => setMemberships(
            reply.ok ? reply.data.memberships.sort((a, b) => a.user.email.localeCompare(b.user.email)) : [],
        ));
    }, [inviteModalVisible]);

    if (memberships === undefined) {
        return <LoadingAnimation />;
    }

    const sort = (order: SortOrder, type: SortType) => {
        setSortType(type);
        setSortOrder(order);
    };

    return (
        <>
            <div>
                <table className="s-table">
                    <colgroup>
                        <col width="35%" />
                        <col width="0" />
                        <col width="35%" />
                        <col width="20%" />
                        <col width="10%" />
                    </colgroup>
                    <thead>
                        <tr>
                            <TableHeaderCell
                                onClick={(arg) => sort(arg, 'name')}
                                sortedBy={getSortBy(sortType, 'name', sortOrder)}
                                colSpan={2}
                                label={t('ui.name')}
                            />
                            <TableHeaderCell
                                onClick={(arg) => sort(arg, 'email')}
                                sortedBy={getSortBy(sortType, 'email', sortOrder)}
                                label={t('ui.email')}
                            />
                            <TableHeaderCell
                                onClick={(arg) => sort(arg, 'role')}
                                sortedBy={getSortBy(sortType, 'role', sortOrder)}
                                label={t('ui.role')}
                            />
                            <TableHeaderCell
                                onClick={(arg) => sort(arg, 'active')}
                                sortedBy={getSortBy(sortType, 'active', sortOrder)}
                                label="Active"
                            />
                        </tr>
                    </thead>
                    <tbody>
                        {sortedMemberships.map(m => <tr key={`${m.role.id} ${m.user.id}`}>
                            <td className={m.user.is_active ? undefined : 's-table-disabled'}>
                                {canManageUsers && <NavLink className="s-link" to={'/account/members/' + m.user.id}>{getFullUserName(m)}</NavLink>}
                                {!canManageUsers && `${m.user.first_name}\u00A0${m.user.last_name}`}
                            </td>
                            <td>{m.user.is_staff && <Icon path={mdiShieldAccountOutline} color="white" />}</td>
                            <td className={m.user.is_active ? undefined : 's-table-disabled'}>{m.user.email}</td>
                            <td className={m.user.is_active ? undefined : 's-table-disabled'}>{m.role.name}</td>
                            <td className={m.user.is_active ? undefined : 's-table-disabled'}>{m.user.is_active ? 'Yes' : 'No'}</td>
                        </tr>)}
                    </tbody>
                </table>

                {canManageUsers && <div style={{ marginTop: '1.5rem' }}>
                    <Button onClick={() => setInviteModalVisible(true)}>{t('ui.invite_new_member')}</Button>
                </div>}
            </div>

            {inviteModalVisible && <InviteTeamMember
                onClose={() => setInviteModalVisible(false)} />}
        </>
    );
}
