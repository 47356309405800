import * as React from 'react';
import { AppContext, useProject } from '@/context';
import { expandBBox, isEmpty, t } from '@/utils';
import { LoadingAnimation } from '@/lib';
import { StaticMap } from '@/lib/map';
import MapBottomPanel from './MapBottomPanel';
import MapTopPanel from './MapTopPanel';
import ExploreMapLegendPanel from './MapLegendPanel';
import './index.scss';


export default function ProjectExplorePage(): JSX.Element {
    const { state } = React.useContext(AppContext);
    const contentWrapper = React.useRef<HTMLDivElement>();
    const project = useProject();
    const [bottomPanelPath, setBottomPanelPath] = React.useState<(number | string)[]>([]);
    const [bottomPanelPeek, setBottomPanelPeek] = React.useState<string>('0');
    const [pointOfInterest, setPointOfInterest] = React.useState<ILatLng & { siteId?: number }>(undefined);
    const [mapBBox, setMapBBox] = React.useState<number[]>(expandBBox(project.minimap.bbox));
    const [mapCenter, setMapCenter] = React.useState<ILatLng>(undefined);
    const [mapZoom, setMapZoom] = React.useState<number>(5);
    const pins = React.useMemo(() => Object
        .values(project.locations || {})
        .map(s => ({ center: s.latlng, id: 'site-' + s.id, siteId: s.id, text: s.name })), [project.locations]);

    React.useEffect(() => {
        document.title = `${t('pages.explore')} — ${project.name} — kuyua`;
    }, []);

    React.useEffect(() => {
        if (pointOfInterest && bottomPanelPath.length === 0) {
            setBottomPanelPath(['stats']);
        }
    }, [pointOfInterest]);

    React.useLayoutEffect(() => {
        const bottomPanel = contentWrapper.current?.querySelector('.s-map-bottom-panel');
        const contentHeight = bottomPanel?.getBoundingClientRect().height || 0;
        const height = -Math.min(contentHeight, window.innerHeight * .66);

        setBottomPanelPeek(pointOfInterest ? `${Math.floor(height)}px` : '0');
    }, [bottomPanelPath, pointOfInterest]);

    if (!project || project.loading || !project.reports) {
        return <LoadingAnimation />;
    }

    const baseMap = state.projects.exploreMapBaseMap;
    const mapRasters = state.projects.exploreMapRasters;

    return (
        <div ref={contentWrapper}>
                <MapTopPanel
                    onSearchComplete={data => {
                        setMapBBox(data.bbox);
                        setMapCenter(data.center);
                        setPointOfInterest(data.pointOfInterest);
                        setMapZoom(data.zoom);
                    }}
                />

                <StaticMap
                    bbox={mapBBox}
                    center={mapCenter}
                    interactive={true}
                    layers={[]}
                    onClick={setPointOfInterest}
                    onPinClick={pin => {
                        setBottomPanelPath(['stats']);
                        setPointOfInterest({ ...pin.center, siteId: (pin as unknown as { siteId: number }).siteId });
                    }}
                    pins={pointOfInterest ? [...pins, { center: pointOfInterest, id: `poi ${pointOfInterest.lat} ${pointOfInterest.lng}` }] : pins}
                    rasters={mapRasters}
                    roundedCorners={false}
                    zoom={mapZoom}
                    baseMapUri={baseMap}
                />

                {!isEmpty(mapRasters) && !Object.values(mapRasters)[0].categories && <ExploreMapLegendPanel raster={Object.values(mapRasters)[0]} />}

            {bottomPanelPath.length !== 0 && <MapBottomPanel
                onClose={() => {
                    setBottomPanelPath([]);
                    setPointOfInterest(undefined);
                }}
                onPathChange={path => setBottomPanelPath(path)}
                path={bottomPanelPath}
                pointOfInterest={pointOfInterest}
                poiSiteId={pointOfInterest?.siteId}
                raster={Object.values(mapRasters)[0]}
                style={{ marginTop: bottomPanelPeek, zIndex: 5 }} />}3
        </div>
    );
}
