const dateFormat = new Intl.DateTimeFormat(navigator.language, { dateStyle: 'medium' });


export function formatDate(date: Date | string): string {
    if (typeof date === 'string') {
        const timestamp = Date.parse(date);

        if (!isNaN(timestamp)) {
            return dateFormat.format(new Date(timestamp));
        }

        return undefined;
    }

    return dateFormat.format(date as Date);
}

export function getWeekRange(date: Date, weekStartDay = 1): [Date, Date] {
    const dateDay = date.getDay();
    const weekStartDate = new Date(date);
    weekStartDate.setDate(date.getDate() - (dateDay - weekStartDay));
    const lastWeekDay = new Date(weekStartDate);
    lastWeekDay.setDate(weekStartDate.getDate() + 6);

    return [weekStartDate, lastWeekDay];
}
