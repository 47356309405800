import { colors } from "@/utils/style";
import * as React from "react";
import { IButtonProps } from "@/types/components";

export const Button: React.FC<IButtonProps> = ({ style, size, color, onClick, title, disabled }) => {

    return (
        <button
            style={{
                ...styles.actionButton,
                ...(size ? { ...styles.size[size] } : { ...styles.size.md }),
                color: color ?? colors.black,
                ...(onClick ? { cursor: 'pointer' } : ''),
                ...(disabled && {opacity: 0.5}),
              ...style,
            }}
            onClick={ onClick ? onClick : () => false}
        >
            {title}
        </button>
    );
};
const styles = {
    size: {
        md: {
            minWidth: 160,
            fontSize: 14,
            height: 40,
        },
        sm: {
            minWidth: 80,
            fontSize: 10,
            height: 25,
        },
    },
    actionButton: {
        backgroundColor: colors.brightBlue,
        borderRadius: 20,
        border: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'normal',
        padding: '0 20px',
    },
};
