export function isThreatenedSpecies(species: IAssessedSpecies): boolean {
    const a = species?.assessment;

    if (!a) {
        return false;
    }

    return ![null, 'NE', undefined].includes(a.conservation_status);
}


export function isProtectedSpecies(species: IAssessedSpecies): boolean {
    const a = species?.assessment;

    if (!a?.protection_statuses) {
        return false;
    }

    return Object.keys(a.protection_statuses).length > 0;
}


export function isKeyStoneSpecies(species: IAssessedSpecies): boolean {
    const a = species?.assessment;

    if (!a) {
        return false;
    }

    return (
        a.is_keystone_species ||
        a.is_umbrella_species ||
        a.apex_predator_rank > 0 ||
        a.habitat_creator_rank > 0 ||
        a.seed_disperser_rank > 0
    );
}
