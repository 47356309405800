import * as React from 'react';
import {mdiArrowLeft, mdiArrowRight, mdiTriangleSmallUp} from '@mdi/js';
import Icon from '../Icon';
import './pagination.scss';
import { AppContext } from '@/context';
import { t } from '@/utils';
import {colors} from "@/utils/style";

interface IPaginationProps {
    lastPage: number
    currentPage: number
    changePage: (page: number) => void
}

export default function Pagination(props: IPaginationProps): JSX.Element {
    const { state } = React.useContext(AppContext);

    const visiblePages = React.useMemo<number[]>(() => {
        const maxPagesVisible = ['xs', 'sm', 'md'].includes(state.layoutSize) ? 5 : 9;

        //show all possible pages if there are less total pages than layout maximum
        if (maxPagesVisible >= props.lastPage) {
            return Array.from(Array(props.lastPage - 2)).map((_, index) => index + 2);
        }

        const notCurrentVisibleAmountHalf = (maxPagesVisible - 1) / 2;
        let lastVisible = props.currentPage + notCurrentVisibleAmountHalf;
        let firstVisible = props.currentPage - notCurrentVisibleAmountHalf;

        //last visible page number can't be more than second last page
        if (lastVisible >= props.lastPage) {
            lastVisible = props.lastPage - 1;
        }
        //first visible page number can't be less than 2
        if (firstVisible < 2) {
            firstVisible = 2;
        }

        //make the amount of pages visible constant
        if ((lastVisible - firstVisible + 1) < maxPagesVisible) {
            const pagesOnLeft = props.currentPage - firstVisible;

            //add more page buttons to the right of current page if current page is close to beginning
            if (pagesOnLeft < notCurrentVisibleAmountHalf) {
                lastVisible = lastVisible + notCurrentVisibleAmountHalf - pagesOnLeft - 1;
            }
            //add more more page buttons to the left of current page if current page is close to the end
            else {
                const pagesOnRight = lastVisible - props.currentPage;
                firstVisible = firstVisible - notCurrentVisibleAmountHalf + pagesOnRight + 1;
            }
        }

        const pageNumbers = [];
        for (let i = firstVisible; i < lastVisible + 1; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;

    }, [props.lastPage, props.currentPage, state.layoutSize]);

    const getSelectedClass = (page: number) => {
        return props.currentPage === page ? "s-pagination-button--selected" : "";
    };

    return (
        <div className="s-pagination-container">
            <button
              className="s-pagination-button"
              title={t('ui.previous')}
              aria-label={t('ui.previous')}
              onClick={() => props.changePage(props.currentPage - 1)}
              disabled={props.currentPage === 1}
            >
                <Icon path={mdiTriangleSmallUp} color={colors.white} style={{transform: "rotate(-90deg)"}}/>
            </button>
            <div className="s-pagination-lastPage-container">
                <button
                    className={`s-pagination-button ${getSelectedClass(1)}`}
                    onClick={() => props.changePage(1)}
                >
                   1
                </button>
                { (visiblePages.length > 2 && !visiblePages.includes(2)) &&
                    <span>
                        ...
                    </span>
                }
                { visiblePages.map(page =>
                    <button
                        key={'page-button-' + page}
                        className={`s-pagination-button ${getSelectedClass(page)}`}
                        onClick={() => props.changePage(page)}
                    >
                        {page}
                    </button>)
                }
                { (visiblePages.length > 2 && !visiblePages.includes(props.lastPage - 1)) &&
                    <span>
                        ...
                    </span>
                }
                <button
                    className={`s-pagination-button ${getSelectedClass(props.lastPage)}`}
                    onClick={() => props.changePage(props.lastPage)}
                >
                    {props.lastPage}
                </button>
            </div>
            <button
              className="s-pagination-button"
              title={t('ui.next')}
              aria-label={t('ui.next')}
              onClick={() => props.changePage(props.currentPage + 1)}
              disabled={props.currentPage === props.lastPage}
            >
                <Icon path={mdiTriangleSmallUp} color={colors.white} style={{transform: "rotate(90deg)"}}/>
            </button>
        </div>
    );
}
