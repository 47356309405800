export const SFDRReportChanged = 'sfdr/reportChanged';


export function makeSFDRState(): ISFDRState {
    return {
        reports: {},
    };
}


export const sfdrReducer = (state: ISFDRState, action: IGenericAction): ISFDRState => {
    const type = action.type;

    switch (type) {
    case SFDRReportChanged:
        return {
            ...state,
            reports: {
                ...state.reports,
                [action.report.id]: action.report,
            },
        };
    }

    return state;
};
