import { Button } from "@/project/sleap/Button";
import { useSleapStep } from "@/utils/customHooks";
import { AppContext } from "@/context";
import React from "react";

export const DetailButton: React.FC = () => {
  const { state } = React.useContext(AppContext);
  const [,, toggleStepDetail] = useSleapStep();

  return (
    <Button title={state.showSideStepDetail ? 'CLOSE' : 'DETAIL'} style={{ height: '100%', minWidth: 'unset', fontSize: 13 }} onClick={() => toggleStepDetail()} />
  );
};
