import * as React from 'react';
import { Button, TextInput, Toast, VerticalStack } from '@/lib';
import { httpPost, t } from '@/utils';
import { isEmptyString, requiredErrorText } from '@/utils/validation';
import StaticMapWrapper from "@/lib/map/StaticMapWrapper";
import {style} from "@/utils/style";


interface IPasswordChangeReply {
    ok: boolean
    reason: string
}

const currentPasswordInvalid = (currentPassword: string, newPassword: string): string => {
    if (isEmptyString(currentPassword)) {
        return requiredErrorText;
    }
    else if (currentPassword === newPassword) {
        return t('ui.new_and_current_password_are_same');
    }
    return null;
};
const newPasswordInvalid = (currentPassword: string, newPassword: string): string => {
    if (isEmptyString(currentPassword)) {
        return requiredErrorText;
    }
    else if (newPassword.length > 0 && newPassword.length < 12) {
        return t('ui.password_too_short');
    }
    else if (currentPassword === newPassword) {
        return t('ui.new_and_current_password_are_same');
    }
    else if (newPassword.length > 64) {
        return t('ui.password_too_long');
    }

    return null;
};
const repeatPasswordInvalid = (newPassword: string, repeatPassword: string): string => {
    if (isEmptyString(repeatPassword)) {
        return requiredErrorText;
    }

    return newPassword !== repeatPassword ? t('ui.password_not_equal') : null;
};


export default function PasswordChangeForm(): JSX.Element {
    const [saving, setSaving] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState<{ kind: string, text: string }>({ kind: '', text: '' });
    const [currentPassword, setCurrentPassword] = React.useState<string>('');
    const [newPassword, setNewPassword] = React.useState<string>('');
    const [repeatPassword, setRepeatPassword] = React.useState<string>('');

    const currentPasswordRef = React.useRef<HTMLInputElement>(null);
    const newPasswordRef = React.useRef<HTMLInputElement>(null);
    const repeatPasswordRef = React.useRef<HTMLInputElement>(null);
    const [errors, setErrors] = React.useState<Record<string, string>>({});

    const onChangeClick = () => {
        if (!currentPasswordInvalid(currentPassword, newPassword)
        && !newPasswordInvalid(currentPassword, newPassword)
        && !repeatPasswordInvalid(newPassword, repeatPassword)) {
            setSaving(true);

            httpPost<IPasswordChangeReply>('/api/v1/account/password', {
                current: currentPassword,
                new: newPassword,
                new2: repeatPassword,
            }).then(reply => {
                if (reply.ok) {
                    setMessage({ kind: 'success', text: 'Password changed!' });
                }
                else {
                    setMessage({ kind: 'error', text: 'Password was not changed — check your current password!' });
                }
            }).finally(() => {
                setSaving(false);
            });
        }
        else {
            validateFields();
        }
    };

    React.useEffect(() => {
        if (errors.currentPassword) {
            setErrors({ ...errors, currentPassword: currentPasswordInvalid(currentPassword, newPassword) });
        }
        if (errors.newPassword) {
            setErrors({ ...errors, newPassword: newPasswordInvalid(currentPassword, newPassword) });
        }
        if (errors.repeatPassword) {
            setErrors({ ...errors, repeatPassword: repeatPasswordInvalid(newPassword, repeatPassword) });
        }

    }, [currentPassword, newPassword, repeatPassword]);

    const validateFields = () => {
        const currentPasswordError = currentPasswordInvalid(currentPassword, newPassword);
        const newPasswordError = newPasswordInvalid(currentPassword, newPassword);
        const repeatPasswordError = repeatPasswordInvalid(newPassword, repeatPassword);

        setErrors({
            currentPassword: currentPasswordError,
            newPassword: newPasswordError,
            repeatPassword: repeatPasswordError,
        });

        if (currentPasswordError) {
            currentPasswordRef.current.focus();
        }
        else if (newPasswordError) {
            newPasswordRef.current.focus();
        }
        else if (repeatPasswordError) {
            repeatPasswordRef.current.focus();
        }
    };

    return (
      <div style={{
          height: '100vH',
          display: 'flex',
          alignItems: 'flex-start'
      }}>
          <StaticMapWrapper right={true} />
          <div style={style.leftContainer}>
            <form>
            <VerticalStack style={{ margin: '2rem 0', maxWidth: '30rem' }}>
                <VerticalStack spacing="condensed">
                    <label htmlFor="current_password">{t('ui.current_password')}</label>
                    <TextInput
                        ref={currentPasswordRef}
                        id="current_password"
                        type="password"
                        onInput={setCurrentPassword}
                        onBlur={() => setErrors({ ...errors, currentPassword: currentPasswordInvalid(currentPassword, newPassword) })}
                        value={currentPassword}
                        error={errors.currentPassword} />
                </VerticalStack>
                <VerticalStack spacing="condensed">
                    <label htmlFor="new_password">{t('ui.new_password')}</label>
                    <TextInput
                        ref={newPasswordRef}
                        id="new_password"
                        type="password"
                        onInput={setNewPassword}
                        onBlur={() => setErrors({ ...errors, newPassword: newPasswordInvalid(currentPassword, newPassword) })}
                        value={newPassword}
                        error={errors.newPassword} />
                </VerticalStack>
                <VerticalStack spacing="condensed">
                    <label htmlFor="repeat_password">{t('ui.repeat_new_password')}</label>
                    <TextInput
                        ref={repeatPasswordRef}
                        id="repeat_password"
                        type="password"
                        onInput={setRepeatPassword}
                        onBlur={() => setErrors({ ...errors, repeatPassword: repeatPasswordInvalid(newPassword, repeatPassword) })}
                        value={repeatPassword}
                        error={errors.repeatPassword} />
                </VerticalStack>

                <Button disabled={saving} onClick={onChangeClick}>{t('ui.change_password')}</Button>
            </VerticalStack>

            {message.text && <Toast kind={message.kind} text={message.text} onClose={() => setMessage({ kind: '', text: '' })} />}
        </form>
          </div>
      </div>
    );
}
