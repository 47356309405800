import * as React from 'react';
import { AppContext } from '@/context';
import { apiGet, formatDate, t } from '@/utils';
import { Kpi, ErrorPanel, LoadingAnimation, HorizontalStack } from '@/lib';


export default function AccountOverview(): JSX.Element {
    const { state } = React.useContext(AppContext);
    const [subscription, setSubscription] = React.useState<ITeamSubscription>(undefined);

    React.useEffect(() => {
        apiGet<ITeamSubscription>(state.config.team.slug, 'subscription').then(reply => {
            if (reply?.ok) {
                setSubscription(reply.data);
            }
        });
    }, []);

    if (subscription === undefined) {
        return (
            <div style={{ marginTop: '6rem', textAlign: 'center' }}>
                <LoadingAnimation />
            </div>
        );
    }

    if (subscription === null) {
        return (
            <ErrorPanel
                title="No subscription"
                text="You do not have currently an active subscription, please contact support@kuyua.com to set you up with one"
                style={{ margin: '6rem auto 0 auto', maxWidth: '35rem', textAlign: 'center' }} />
        );
    }

    return (
        <>
            <HorizontalStack style={{ marginTop: '2rem' }}>
                <Kpi
                    label={t('ui.subscription_plan')}
                    size="sm"
                    value={subscription.name} />

                <Kpi
                    label="Max Site Assessments"
                    size="sm"
                    value={subscription.limits.site_assessments} />

                <Kpi
                    label={t('ui.valid_until_date')}
                    size="sm"
                    value={formatDate(subscription.valid_until) || '—'} />
            </HorizontalStack>
        </>
    );
}
