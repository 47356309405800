import * as React from 'react';
import { AppContext } from '@/context';
import { Grid, Kpi } from '@/lib';
import { isThreatenedSpecies, isProtectedSpecies, isKeyStoneSpecies, t } from '@/utils';


interface ISpeciesKeyValuesProps {
    species: IAssessedSpecies[]
    style?: React.CSSProperties
}


export default function SpeciesKeyValues(props: ISpeciesKeyValuesProps): JSX.Element {
    const { state } = React.useContext(AppContext);
    const isSmallLayout = ['xs', 'sm', 'md'].includes(state.layoutSize);
    const kpis = React.useMemo(() => {
        const species = props.species || [];
        const hasSpecies = species.length > 0;

        return [
            {
                label: t('ui.total_species'),
                value: hasSpecies ? species.length : 'N/A',
            }, {
                label: t('ui.threatened_species'),
                value: hasSpecies ? species.filter(s => isThreatenedSpecies(s)).length : 'N/A',
            }, {
                label: t('ui.protected_species'),
                value: hasSpecies ? species.filter(s => isProtectedSpecies(s)).length : 'N/A',
            }, {
                label: t('ui.umbrella_species'),
                value: hasSpecies ? species.filter(s => isKeyStoneSpecies(s)).length : 'N/A',
            },
        ];
    }, [props.species]);

    return (
        <Grid alignItems="flex-start" itemsPerRow={isSmallLayout ? 2 : 4} style={props.style}>
            {kpis.map(k => <Kpi key={k.label} label={k.label} size="sm" value={k.value} />)}
        </Grid>
    );
}
