import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { AppContext } from '@/context';
import { getHomeUrl } from '@/utils';


interface ILogoProps {
    clickable?: boolean
    style?: React.CSSProperties
    variant?: string
    size?: number
}

interface ILogoSphereProps {
    clickable?: boolean
    style?: React.CSSProperties
}


export function LogoSphere(props: ILogoSphereProps): JSX.Element {
    return <div className="s-logo-sphere" style={props.style} />;
}

export default function Logo(props: ILogoProps): JSX.Element {
    const { state } = React.useContext(AppContext);
    const url = state.config?.team ? getHomeUrl(state.config.team) + '/explore' : '/login';

    return (
        <NavLink to={url} style={props.style}>
            <img src={require('../assets/kuyua.png')} height={'30px'}/>
        </NavLink>
    );
}

export function LogoFullGraphic(props: ILogoProps): JSX.Element {
    const fill = props.variant === 'black' ? '#000' : '#FFF';
    const size = props.size || 1;
    const sphereStyle = size ? { width: (22 * size) + 'px', height: (22 * size) + 'px', marginRight: (0.3 * size) + '.rem' } : null;

    return <>
        <LogoSphere clickable={props.clickable} style={sphereStyle} />

        <svg width={60 * size} height={20 * size} viewBox="70 0 180 60">
            <path fill={fill} d="M95.36,35.1A41,41,0,0,0,88,33.61c-2.11-.33-3.74-.64-4.9-.93a6.71,6.71,0,0,1-3-1.58,4.11,4.11,0,0,1-1.21-3.16,4.5,4.5,0,0,1,1.94-3.85,8.64,8.64,0,0,1,5.18-1.41,10.05,10.05,0,0,1,5.63,1.66,6.42,6.42,0,0,1,3,4.81H102a12.15,12.15,0,0,0-4.69-9.23q-4.38-3.48-11.26-3.48a17.88,17.88,0,0,0-7.49,1.5,12.5,12.5,0,0,0-5.14,4.13,10.34,10.34,0,0,0-1.86,6.11c0,3,.65,5.36,1.94,7a10,10,0,0,0,4.7,3.4,42.32,42.32,0,0,0,7.12,1.58c2.22.38,3.93.73,5.15,1A6.78,6.78,0,0,1,93.54,43a4.75,4.75,0,0,1,1.26,3.52,4.69,4.69,0,0,1-2.19,3.93A9.33,9.33,0,0,1,87,52a10.06,10.06,0,0,1-6.39-1.9,7.47,7.47,0,0,1-2.76-5.47H70.51a13.78,13.78,0,0,0,4.85,10.16Q79.82,58.55,87,58.55q7,0,11.14-3.32a10.79,10.79,0,0,0,4.17-8.91q0-5-2.07-7.65A10.26,10.26,0,0,0,95.36,35.1Z" />
            <path fill={fill} d="M127.76,17.09h-12.3V13.44a6.56,6.56,0,0,1,1.66-4.73A5.9,5.9,0,0,1,121.61,7h6.15V0H121a15.22,15.22,0,0,0-7.2,1.62A11.37,11.37,0,0,0,109,6.2a13.8,13.8,0,0,0-1.66,6.92V57.9h8.09V24.05h12.31Z" />
            <path fill={fill} d="M195.42,49.84a12.84,12.84,0,0,1-7.17,1.82,11.79,11.79,0,0,1-6.6-1.86,12.61,12.61,0,0,1-4.49-5.14A15.8,15.8,0,0,1,175.62,39h31a31.11,31.11,0,0,0,.16-3.16,21.2,21.2,0,0,0-2.22-9.67,17.12,17.12,0,0,0-6.6-7,20.12,20.12,0,0,0-10.53-2.63,19.43,19.43,0,0,0-10.53,2.79,18.31,18.31,0,0,0-6.8,7.53,23.39,23.39,0,0,0-2.35,10.49,23,23,0,0,0,2.51,10.57,19.62,19.62,0,0,0,7.17,7.81,20,20,0,0,0,10.81,2.92,20.67,20.67,0,0,0,12.67-3.81,15.3,15.3,0,0,0,6.2-10.2h-8A8.57,8.57,0,0,1,195.42,49.84ZM178.7,26.52q3.08-3.68,8.74-3.68a11.2,11.2,0,0,1,7.94,2.79A10.74,10.74,0,0,1,198.7,33H175.9A13.58,13.58,0,0,1,178.7,26.52Z" />
            <path fill={fill} d="M223,19a11.56,11.56,0,0,0-4,5.91V17.09h-8.1V57.9H219V39.36q0-7.22,2.26-11.26t7.53-4.05h6v-7h-5A11.43,11.43,0,0,0,223,19Z" />
            <rect fill={fill} x="237.8" y="17.09" width="8.1" height="40.81" />
            <rect fill={fill} x="237.32" y="1.64" width="8.99" height="8.98" />
            <path fill={fill} d="M157.72,44.54a8.57,8.57,0,0,1-3.68,5.3,12.84,12.84,0,0,1-7.17,1.82,11.79,11.79,0,0,1-6.6-1.86,12.68,12.68,0,0,1-4.49-5.14A15.8,15.8,0,0,1,134.24,39h31a31.11,31.11,0,0,0,.16-3.16,21.07,21.07,0,0,0-2.22-9.67,17.12,17.12,0,0,0-6.6-7,20.12,20.12,0,0,0-10.53-2.63,19.43,19.43,0,0,0-10.53,2.79,18.38,18.38,0,0,0-6.8,7.53,23.39,23.39,0,0,0-2.35,10.49,23,23,0,0,0,2.51,10.57,19.68,19.68,0,0,0,7.17,7.81,20,20,0,0,0,10.81,2.92,20.67,20.67,0,0,0,12.67-3.81,15.3,15.3,0,0,0,6.2-10.2Zm-20.4-18q3.08-3.68,8.74-3.68A11.18,11.18,0,0,1,154,25.63,10.74,10.74,0,0,1,157.32,33h-22.8a13.68,13.68,0,0,1,2.8-6.52Z" />
        </svg>
    </>;
}
