import * as React from 'react';
import classnames from 'classnames';
import { MapStyles } from './constants';
import { hasProperty, t } from '@/utils';
import BaseModalDialog from '../modals/BaseModalDialog';
import VerticalStack from '../VerticalStack';
import {AppContext, ProjectExploreRastersChanged} from "@/context";
import {NavLink} from "@/lib";
import {StyleSheet} from "@/utils/style";


interface IMapLayerPickerProps {
    availableRasters: Record<string, IProjectRasterConfig>
    onClose: () => void
    onChoose: (kind: 'basemap' | 'raster', raster: IProjectRasterConfig | string | undefined) => void
    selectedBaseMap: string
    selectedRasters: Record<string, IProjectRasterConfig>
}


export default function MapLayerPicker(props: IMapLayerPickerProps): JSX.Element {
    const {dispatch} = React.useContext(AppContext);
    const groupedRasters = React.useMemo(() => {
        const otherLabel = t('ui.other');
        const grouped = Object
            .values(props.availableRasters)
            .reduce((g, r) => {
                const label = r.group_label || otherLabel;

                if (!hasProperty(g, label)) {
                    g[label] = [r];
                }
                else {
                    g[label].push(r);
                }

                return g;
            }, {} as Record<string, IProjectRasterConfig[]>);

        Object.values(grouped).map(g => g.sort((a, b) => a.label.localeCompare(b.label)));

        return Object.entries(grouped).sort(([ak], [bk]) => {
            if (ak !== bk) {  // Send "other" category to bottom
                if (ak === otherLabel) {
                    return 1;
                }
                else if (bk === otherLabel) {
                    return -1;
                }
            }

            return ak.localeCompare(bk);
        });
    }, [props.availableRasters]);
    console.log('groupedRasters ==>> ', groupedRasters);
    const onChoose = (kind: 'basemap' | 'raster', item: IProjectRasterConfig | string | undefined) => {

        // TODO Start animation on clicked item and lengthen the timeout below

        window.setTimeout(() => {
            console.log('here you can pass onChoose ==>> ', kind, item);
            props.onChoose(kind, item);
            props.onClose();
        }, 25);
    };

    const clearSelectedDataLayer = () => {

        dispatch({ type: ProjectExploreRastersChanged, rasters: {} })
        window.setTimeout(() => {
            props.onClose();
        }, 50);
    }

    const selectedRasterKeys = Array.from(Object.keys(props.selectedRasters));
    const isRasterSelected = selectedRasterKeys.length > 0;
    const activeStyle = { color: '#F7F7F5' };

    return (
        <BaseModalDialog onClose={props.onClose} scrollableBody={true} title={t('map.layer_plural')}>
            <div className="s-map-layer-panel" style={styles.layers}>
                <VerticalStack>

                    <div className="s-map-layer-subtitle">{t('ui.base_layer_plural')}</div>

                    {MapStyles.map(s =>
                        <div key={s.uri} className={classnames('s-map-layer', { 's-map-layer-selected': !isRasterSelected && props.selectedBaseMap === s.uri })}>
                            <div
                                className="s-map-layer-thumbnail"
                                onClick={() => onChoose('basemap', s.uri)}
                                style={{ background: `#F7F7F7 center / cover no-repeat url(${s.icon})` }}
                            />
                            <div>
                                <div className="s-map-layer-title" onClick={() => onChoose('basemap', s.uri)}>{s.name}</div>
                            </div>
                        </div>
                    )}

                    <NavLink
                        active={true}
                        onClick={() => clearSelectedDataLayer()}
                        to='#'>{t('actions.reset_selection')}</NavLink>
                </VerticalStack>
                <VerticalStack>
                    {groupedRasters.map(([label, rasters]) => <React.Fragment key={label}>
                        <div className="s-map-layer-subtitle">{label}</div>
                        
                        {rasters.filter((item)=> item.id !== "aq3awrdefqal20190715" && item.id !== "aq3awrdefqan20190715").map(r => <div key={r.id} className={classnames('s-map-layer', { 's-map-layer-selected': selectedRasterKeys.includes(r.id) })}>
                            <div
                                className="s-map-layer-thumbnail"
                                onClick={() => onChoose('raster', r)}
                                style={{ background: `#F7F7F7 center / cover no-repeat url(${r.icon})` }} />

                            <div>
                                <div className="s-map-layer-title" onClick={() => onChoose('raster', r)}>{r.label}</div>
                            </div>
                        </div>)}
                    </React.Fragment>)}
                </VerticalStack>

            </div>
        </BaseModalDialog>
    );
}
const styles = StyleSheet.create({
   layers: {
        display: 'flex',
        flexDirection: 'row',
   }
});