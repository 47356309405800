import * as React from 'react';
import { colors, style, StyleSheet } from "@/utils/style";
import Scope from "@/project/sleap/Scope/Scope";
import { Locate } from "@/project/sleap/Locate/Locate";
import { useSleapStep } from "@/utils/customHooks";
import Checkbox from "@/utils/Checkbox";
import StaticMapWrapper from "@/lib/map/StaticMapWrapper";
import { AppContext, ShowSideStepDetail, useProject, useTeam } from "@/context";
import Assess from "@/project/sleap/Assess/Assess";
import { DetailButton } from "@/project/sleap/Assess/DetailButton";
import { getHomeUrl } from "@/utils";
import { useNavigate } from "react-router-dom";
import { ISleap } from "@/types/sleap";

export default function Sleap(): JSX.Element {
    const project = useProject();
    const { state } = React.useContext(AppContext);
    const navigate = useNavigate();
    const team = useTeam();

    const sleap: ISleap[] = React.useMemo(() => ([
        {
            step: 1,
            letter: 'S',
            name: 'scope',
            Template: Scope,
            hasDetail: false,
            isChecked: project?.is_complete,
        }, {
            step: 2,
            letter: 'L',
            name: 'locate',
            Template: Locate,
            hasDetail: false,
            isChecked: project?.locations.length > 0,
        }, {
            step: 3,
            letter: 'E',
            name: 'evaluate',
            Template: () => (navigate(getHomeUrl(team) + `/understand`)),
            hasDetail: false,
            isChecked: project?.locations.length > 0,
        }, {
            step: 4,
            letter: 'A',
            name: 'assess',
            Template: Assess,
            DetailTemplate: DetailButton,
            hasDetail: true,
            isChecked: project?.locations.length > 0,
        }, {
            step: 5,
            letter: 'P',
            name: 'prepare',
            Template: null,
            hasDetail: false,
            isDisabled: true,
        },
    ]), [project]);

    return (
        <div className="content">
            <StaticMapWrapper right={true} />
            {state.showSideStepDetail &&
                <div style={{ ...style.centerContainer, ...styles.sleapWrapper, ...styles.sleapWrapperDetail }}>
                    <div style={{ ...styles.sleapContainer, ...styles.sleapContainerDetail }}>
                        {
                            sleap.map((section, i) => {
                                return (
                                    section.hasDetail && <Section key={i} {...section} />
                                );
                            })
                        }
                    </div>
                </div>
            }

            {!state.showSideStepDetail &&
                <div style={{ ...style.leftContainer, ...styles.sleapWrapper }}>
                    <div style={styles.sleapContainer}>
                        {
                            sleap.map((section, i) => {
                                return (
                                    <Section key={i} {...section} />
                                );
                            })
                        }
                    </div>
                </div>
            }
        </div>
    );
}

const Section: React.FC<ISleap> = ({ letter, name, step, Template, DetailTemplate, isChecked, isDisabled }) => {
    const [sleapStep, setSleapStep] = useSleapStep();
    const { state, dispatch } = React.useContext(AppContext);

    const [checked, setChecked] = React.useState(isChecked);
    const isActive = sleapStep === letter;
    const backdropStyle = isActive ? style.sleapBackDrop : {};

    const handleOnClick = () => {

        /** for now, we ignore prepare step **/
        if (letter === 'P') return;

        if (state.showSideStepDetail && letter !== 'A') {

            dispatch({ type: ShowSideStepDetail, value: false });
        }
        setSleapStep(letter);
    };

    if (state.showSideStepDetail) {

        return (
          <div style={{ display: 'flex', flex: 1 }}>
            <Template checkHandler={setChecked} />
          </div>
        );
    }

    return (
        <div style={backdropStyle}>
            <div style={{
                ...style.sleapSection,
                ...(isActive && { backgroundColor: colors.opaqueWhite }),
                ...(isDisabled && { opacity: 0.5 }),
            }}
                 onClick={handleOnClick}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ ...style.centerFlex, flexDirection: 'column', fontWeight: 'bold' }}>
                        <span style={{ fontSize: 12 }}>Step {step}</span>
                        <span style={{ fontSize: 25, lineHeight: 1 }}>{letter}</span>
                    </div>
                    <span style={{ marginLeft: 30, fontSize: 25, color: colors.brightBlue }}>{name}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'right' }}>
                    {isActive && DetailTemplate && (
                        <div style={{ paddingRight: 10 }}>
                            <DetailTemplate />
                        </div>
                    )}
                    <Checkbox checked={checked} readonly={true} />
                </div>
            </div>
            {isActive && (<div style={{ display: 'flex', flex: 1 }}>
                <Template checkHandler={setChecked} />
            </div>)}
        </div>
    );
};

const styles = StyleSheet.create({
    sleapWrapper: {
        height: '100vH',
        paddingTop: 125,
        paddingBottom: 50,
        width: 'unset',
    },
    sleapContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: 400,
        gap: 5,
    },
    sleapContainerDetail: {
        width: '100%',
    },
    sleapWrapperDetail: {
        width: '100%',
        marginRight: 100,
    },
});
