import * as React from 'react';
import classnames from 'classnames';


interface IIconProps {
    className?: string
    color?: string
    path: string
    onClick?: (e: React.MouseEvent<SVGSVGElement>) => void
    size?: number
    style?: React.CSSProperties
    tooltip?: string
}


export default function Icon(props: IIconProps): JSX.Element {
    const size = props.size || 24;
    const classes = classnames('s-icon', props.className, {
        's-icon-clickable': !!props.onClick,
    });

    return (
        <svg
            className={classes}
            height={size}
            onClick={props.onClick}
            style={props.style}
            viewBox="0 0 24 24"
            width={size}>
            <path d={props.path} fill={props.color || 'black'} strokeWidth="0.1">
                {props.tooltip && <title>{props.tooltip}</title>}
            </path>
        </svg>
    );
}
