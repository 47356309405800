import * as React from 'react';
import { apiGet, getHomeUrl, httpGet, httpPost } from "@/utils";
import StaticMapWrapper from "@/lib/map/StaticMapWrapper";
import { colors, style, StyleSheet } from "@/utils/style";
import {Icon, LoadingAnimation, MainNavigation} from "@/lib";
import {AppContext, ConfigChanged, ProjectsChanged, useLoading} from "@/context";
import { mdiAlertCircleOutline } from '@mdi/js';
import {useNavigate} from 'react-router-dom';
import {
    initDependencies,
    initEconomicActivityCount,
    initImpacts,
    initSites
} from "@/lib/AppLoader";
import {NewPasswordForm} from "@/account/NewPasswordForm";

export default function LoginPage(): JSX.Element {

    const urlParams = new URLSearchParams(window.location.search);
    const recoveryToken = urlParams.get('recovery-token');

    const [initErrorCode, setInitErrorCode] = React.useState<number>(0);
    const { dispatch, state } = React.useContext(AppContext);
    const navigate = useNavigate();
    const [loginError, setLoginError] = React.useState('');
    const [form, setForm] = React.useState({
        email: '',
        password: '',
    });
    const btn = React.useRef(null);
    const isLoading = useLoading();

    function handleForm(value: object) {

        setForm({ ...form, ...value });
        setLoginError('');
    }

    function initializeAppWithTeam(teamSlug: string): void {
        apiGet<IConfig>(teamSlug, 'hello').then(reply => {
            if (!reply.ok) {
                if (reply.status >= 400) {
                    setInitErrorCode(reply.status);
                }

                return;
            }

            const { team } = reply.data;

            Object.values(team.projects).forEach(p => p.reports = new Map());

            initImpacts(dispatch);
            initDependencies(dispatch);
            initEconomicActivityCount(dispatch);
            initSites(dispatch, team, Object.values(team.projects)[0], () => {
                navigate(getHomeUrl(team) + '/understand');
            });
            dispatch({ type: ConfigChanged, config: reply.data });
            dispatch({ type: ProjectsChanged, projects: team.projects });
        });
    }

    function login() {

        if (form.email.length < 1 || form.password.length < 1) {

            setLoginError('E-Mail and Password required');
            return;
        }
        httpPost('/loginPost', form).then(reply => {

            if (!reply.ok) {

                setInitErrorCode(reply.status);
                setLoginError(reply.reason);
                btn.current?.classList.add('shake');

                setTimeout(function () {
                    btn.current?.classList.remove('shake');
                }, 700);
                return;
            } else {

                const team = reply.data.teams[0];
                initTeam(team);
            }
        });
    }

    function initTeam(team: string) {

        if (team) {
            initializeAppWithTeam(team);
            return;
        }

        httpGet<{ teams: ITeamMembership[] }>('/api/v1/teams').then(reply => {
            if (!reply.ok) {
                setInitErrorCode(reply.status);
                return;
            }

            switch (reply.data.teams.length) {
                case 0:
                    setInitErrorCode(404);
                    return;

                case 1:
                    initializeAppWithTeam(reply.data.teams[0].team.slug);
                    return;

                default:
                    document.location = '/teams';
                    return;
            }
        });

        if (initErrorCode !== 0) {
            const arnold = 'We\'ll be back soon!';
            const pd = 'Permission Denied';
            const mb = 'Maintenance Break';
            const title = { 401: pd, 403: pd, 404: pd, 500: mb, 502: mb, 503: mb, 504: mb }[initErrorCode] || 'Error';
            const msg = {
                401: 'Make sure that you\'ve logged in',
                403: 'Make sure that you\'ve logged in',
                404: 'No permission to access this team',
                500: arnold,
                502: arnold,
                503: arnold,
                504: arnold,
            }[initErrorCode] || 'An error occured while initializing the app';

            return (
                <div className="s-app-loader-error">
                    <Icon color="#666" path={mdiAlertCircleOutline} size={92} />
                    <span>{title}</span>
                    <span>{msg}</span>
                    <span><a href="/logout" style={{ color: '#013' }}>Log in to continue</a></span>
                </div>
            );
        }

        if (!state.config) {
            return <div className="s-app-loader">Loading…</div>;
        }
    }

    if (recoveryToken) {

        return (
          <NewPasswordForm recoveryToken={recoveryToken}/>
        );
    }
    return (
        <>
            { isLoading && <LoadingAnimation /> }
            <MainNavigation />
            <div className="content">

                <StaticMapWrapper right={true} />
                <div style={{ ...style.leftContainer, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: 500 }}>

                    <p style={styles.title}>SIGN IN</p>
                    <div>
                        <div>EMAIL ADDRESS</div>
                        <input style={{ ...style.inputField }} value={form.email} onChange={(e) => handleForm({ email: e.target.value })} />
                    </div>
                    <div>
                        <div>PASSWORD</div>
                        <input id='password' style={style.inputField} type={'password'} value={form.password} onChange={(e) => handleForm({ password: e.target.value })} />
                    </div>
                    <div style={{ textTransform: 'uppercase', color: loginError ? colors.red : 'transparent' }}>{loginError}</div>
                    <button
                        ref={btn}
                        style={{ ...style.actionButton, fontSize: 14, fontWeight: 'normal', color: colors.black }}
                        onClick={() => login()}
                        disabled={loginError?.length > 0}
                        className={''}
                    >
                        sign in
                    </button>

                    <div>
                        <div>Forgot your password?</div>
                        <a href={'/recovery-token'}>Reset password</a>
                    </div>
                </div>
            </div>
        </>
    );
}

const styles = StyleSheet.create({
    title: {
        fontWeight: 'bold',
        color: colors.brightBlue,
        fontSize: 30,
    },
});
