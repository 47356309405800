import * as React from 'react';
import classnames from 'classnames';


interface IMasterHeaderProps {
    bordered?: boolean
    style?: React.CSSProperties
    text: string
}


export default function MasterHeader(props: IMasterHeaderProps): JSX.Element {
    const classes = classnames('s-master-header', {
        's-master-header-bordered': props.bordered,
    });

    return <h1 className={classes} style={props.style}>{props.text}</h1>;
}
