const PropertySets = [
    ['IUCNKatego', 'ELY', 'LakiPerust', 'ViimPaatPv', 'LakkautusP'],
    ['IUCN_CAT', 'MANG_PLAN', 'MANG_AUTH', 'STATUS_YR'],
    ['Nimi', 'MuutosPvm', 'Lisatieto', 'Subtype'],
    ['naturaTunn', 'paatosPvm', 'paatosAsia', 'suojeluPer'],
    ['RELEASE_DA', 'SITETYPE'],
    ['N2', 'KOODI'],
];


// From a list of polygon properties, determine the most suitable set
// of properties to be displayed.
export function getPrimaryProperties(keys: string[]): string[] {
    if (!keys) {
        return [];
    }

    for (const set of PropertySets) {
        let ok = true;

        for (const name of set) {
            if (!keys.includes(name)) {
                ok = false;
                break;
            }
        }

        if (ok) {
            return set;
        }
    }

    return keys;
}
