import * as React from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { useProject, useTeam } from '@/context';
import { apiGet, getBBoxFromSourceData, t } from '@/utils';
import { ErrorPanel, LoadingAnimation, TabBar } from '@/lib';
import { SiteOverview } from './overview/SiteOverview';
import BSA  from './bsa/BSA';
import './index.scss';
import { colors, StyleSheet } from "@/utils/style";
import { SpeciesPage } from "@/project/site/species/SpeciesPage";
import SingleAction from "@/project/site/actions/SingleAction";
import Actions from './actions';
function getTabs() {
    return [
        { id: 'overview', label: t('ui.overview') },
        { id: 'species', label: t('ui.species') },
        { id: 'bsa', label: t('ui.bsa') },
        { id: 'actions', label: t('ui.action_plan') },
        //{ id: 'key-area', label: t('ui.key_area') },
    ] as { id: string, label: string, postfixIcon?: string }[];
}


export const SiteDetail: React.FC = () => {
    const navigate = useNavigate();
    const { siteId } = useParams();
    const project = useProject();
    const team = useTeam();
    const tabs = getTabs();
    const report = project.reports[project.latest_report_id] as IAssessmentReport;
    const [site, setSite] = React.useState<INewSiteDetailed>(undefined);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [tabId, setTabId] = React.useState<number | string>('overview');
    const [bbox, setBBox] = React.useState<number[]>(undefined);
    const [viewedSpeciesLayer, setViewedSpeciesLayer] = React.useState<string>(null);

    const getSite = (id: number) => {
        const url = `projects/${project.slug}/reports/${report.id}/site/${id}/detailed-view`;
        apiGet<INewSiteDetailed>(team.slug, url).then(reply => {
            if (reply.ok) {
                setSite(reply.data);
            }
            else {
                setSite(undefined);
            }
        }).finally(() => setLoading(false));
    };

    const onLayerLoad = (id: string, layer: IKeyAreaLayer) => {
        if (id === viewedSpeciesLayer) {
            setBBox(getBBoxFromSourceData(layer.geometry, 0.05));
        }
    };

    const onTabClick = (id: string) => {
        if (id !== 'species') {
            setViewedSpeciesLayer(null);
        }


        setTabId(id);
        navigate('./' + id + document.location.search);
    };

    const onSpeciesClick = (species: ISpecies, what: 'info' | 'map') => {
        if (what === 'map') {
            setViewedSpeciesLayer(`species/${site.id}/${species.id}`);
        }
    };

    React.useEffect(() => {
        if (!site || site.id !== parseInt(siteId)) {
            setLoading(true);
            getSite(parseInt(siteId));
        }

        if (site) {
            document.title = site?.name ? `${site.name} — ${project.name} — kuyua` : `${project.name} — kuyua`;
        } else {
            if (loading) {
                document.title = t('ui.loading');
            }
            else {
                document.title = t('ui.site_not_found');
            }
        }
    }, [site, loading, siteId]);

    React.useEffect(() => {
        const path = document.location.pathname;

        const tabId = ['overview','actions', 'measures', 'species', 'bsa', 'key-area'].find(tabId => path.includes(tabId));
        setTabId(tabId || 'overview');
    }, [document.location.pathname]);

    if (!loading && !site) {
        return <ErrorPanel
            style={{ margin: '30vh auto 0 auto' }}
            text={`The site you've navigated to does not exist or has been deleted`}
            title="Site Not Found" />;
    }

    if (loading) {
        return <LoadingAnimation />;
    }

    const layers: string[] = [];

    if (viewedSpeciesLayer) {
        layers.push(viewedSpeciesLayer);
    }

    const props = { project, report, site };

    console.log('tabId ====>>> ',tabId );
    
    return (
        <div style={styles.wrapper}>
            <TabBar activeId={tabId} onClick={onTabClick} tabs={tabs} />

            <div className="s-overview">
            {tabId !== "overview" && (
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '.5rem' }}>
                    <h1 style={{ fontSize: 40, color: colors.brightBlue, margin: 'unset', marginRight: 20 }}>
                        {site.name ?? site.site_id}
                    </h1>
                    <span style={{ fontSize: 20, marginRight: 10 }}>Site Type: </span>
                    <span style={{ fontSize: 20, color: colors.brightBlue, textTransform: 'capitalize' }}>{site.site_type.name}</span>
                </div>
                )}
                    <ErrorBoundary fallback={<p>Failed to load tab content</p>}>
                        <Routes>
                            <Route path="/overview" element={<SiteOverview site={props.site} bbox={bbox} layers={layers} onLayerLoad={onLayerLoad} />} />
                            <Route path="/species" element={<SpeciesPage site={props.site} bbox={bbox} layers={layers} onLayerLoad={onLayerLoad} onClick={onSpeciesClick} />} />
                            <Route path="/actions/" element={<Actions {...props} />} />
                            <Route path="/actions/:subIndustryId/:clusterId" element={<SingleAction site={props.site} />} />
                            <Route path="/bsa" element={<BSA site={props.site} />} />
                            {/*<Route path="/key-area" element={<KeyArea site={props.site} bbox={bbox} layers={layers} onLayerLoad={onLayerLoad} />} />*/}
                        </Routes>
                    </ErrorBoundary>
            </div>
        </div>
    );
};

const styles = StyleSheet.create({
   wrapper: {
     padding: '0 100px',
     top: 100,
     position: 'absolute',
     width: '100%',
   },
});
