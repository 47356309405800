import * as React from 'react';


interface IAvatarProps {
    onClick?: (e: React.MouseEvent<HTMLElement>) => void
    style?: React.CSSProperties
    user: IUser
}

function initials(user: IUser): string {
    const { email, first_name, last_name } = user;
    const init = (first_name?.[0] || '') + (last_name?.[0] || '');

    return (init || (email.slice(0, 2) || '') || '?').toUpperCase();
}


export default function Avatar(props: IAvatarProps): JSX.Element {
    return <span className="s-avatar" onClick={e => props.onClick(e)} style={props.style}>{initials(props.user)}</span>;
}

