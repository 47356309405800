import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { mdiBugOutline } from '@mdi/js';
import {AppContext, AppProvider, useLoading} from './context';
import {AppLoader, Icon, LoadingAnimation, ToastManager} from './lib';
import AccountPage from './account';
import AccountOverview from './account/AccountOverview';
import PasswordChangeForm from './account/PasswordChangeForm';
import PersonalDetails from './account/PersonalDetails';
import TeamMemberDetail from './account/TeamMemberDetail';
import TeamMembers from './account/TeamMembers';
import ProjectPage from './project';
import ProjectExplorePage from './project/explore';
import ProjectPickerPage from './project-picker';
import './app.scss';
import './lib/index.scss';
import DashboardPage from '@/project/understand';
import ActAndTrackPage from '@/project/act-and-track/ActAndTrackPage';
import LoginPage from "@/account/Login";
import ProjectOverviewPage from '@/project/overview';
import ProjectNewSFDRReportPage from '@/project/reports/sfdr/new-report';
import ProjectSFDRReportSiteListPage from '@/project/reports/sfdr/site-list';
import ProjectSFDRReportPage from '@/project/reports/sfdr';
import ProjectReportsPage from '@/project/reports';
import {SiteDetail} from '@/project/site/SiteDetailPage';
import EditSiteProperties from '@/project/site/EditSiteProperties';
import SiteImport from '@/project/site/import';
import SleapPage from "@/project/sleap/SleapPage";
import PasswordRecoverForm from "@/account/PasswordRecoverForm";
import { MeasurePage } from "@/project/measure/MeasurePage";
import { RiskPage } from "@/project/risk/RiskPage";
import PredictPage from "@/project/predict/PredictPage";

function ErrorMessage(): JSX.Element {
    return (
        <div style={{ alignItems: 'center', display: 'flex', flexFlow: 'column', margin: '20vw auto', maxWidth: '30rem', textAlign: 'center' }}>
            <Icon color="#666" path={mdiBugOutline} size={64} />
            <div style={{ fontSize: '1.25rem', marginTop: '1.5rem' }}>An error has occurred</div>
            <div style={{ marginTop: '.5rem' }}>
                <span>The administrators have been notified, if the problem persists, please contact </span>
                <a href="mailto:support@kuyua.com" style={{ color: '#333' }}>support@kuyua.com</a>
            </div>
            <a href="/" style={{ color: '#333', display: 'block', marginTop: '1.5rem' }}>Click to return to home page</a>
        </div>
    );
}


function JumpToUrl(props: { url: string }): JSX.Element {
    React.useEffect(() => {
        document.location = props.url;
    }, []);

    return undefined;
}


function App(): JSX.Element {
    const {state} = React.useContext(AppContext)
    const isLoading = state.isLoading;

    return (
        <Sentry.ErrorBoundary fallback={<ErrorMessage />}>
            <AppProvider>
                <AppLoader>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/:team" element={<ProjectPickerPage />} />
                            <Route path="/:team/:project" element={<ProjectPage />}>
                                <Route index path="explore" element={<ProjectExplorePage />} />
                                <Route index path="understand" element={<DashboardPage />} />
                                <Route path="understand/sleap" element={<SleapPage />} />
                                <Route index path="act-and-track" element={<ActAndTrackPage />} />
                                <Route index path="measure" element={<MeasurePage />} />
                                <Route index path="risk" element={<RiskPage />} />
                                <Route index path="predict" element={<PredictPage />} />
                                <Route index path="report" element={<ProjectExplorePage />} />
                                <Route path="site/:siteId/*" element={<SiteDetail />} />

                                ### old paths ###
                                <Route index path="sites" element={<ProjectOverviewPage />} />
                                <Route path="sites/:siteId/*" element={<SiteDetail />} />
                                <Route path="sites/:siteId/edit" element={<EditSiteProperties />} />
                                <Route path="sites/import" element={<SiteImport />} />

                                <Route path="reports/sfdr/create" element={<ProjectNewSFDRReportPage />} />
                                <Route path="reports/sfdr/:reportId/bd" element={<ProjectSFDRReportSiteListPage listType="bd" />} />
                                <Route path="reports/sfdr/:reportId/pa" element={<ProjectSFDRReportSiteListPage listType="pa" />} />
                                <Route path="reports/sfdr/:reportId/water" element={<ProjectSFDRReportSiteListPage listType="water" />} />
                                <Route path="reports/sfdr/:reportId" element={<ProjectSFDRReportPage />} />
                                <Route path="reports" element={<ProjectReportsPage />} />
                                ### old paths end ###

                            </Route>
                            <Route path="/account" element={<AccountPage />}>
                                <Route index element={<AccountOverview />} />
                                <Route path="details" element={<PersonalDetails />} />
                                <Route path="members/:userId" element={<TeamMemberDetail />} />
                                <Route path="members" element={<TeamMembers />} />
                                <Route path="password" element={<PasswordChangeForm />} />
                            </Route>
                            <Route path="/logout" element={<JumpToUrl url="/logout" />} />
                            <Route path="/teams" element={<JumpToUrl url="/teams" />} />
                            <Route path="/login" element={<LoginPage/>} />
                            <Route path="/recovery-token" element={<PasswordRecoverForm/>} />
                        </Routes>
                    </BrowserRouter>
                </AppLoader>
                <ToastManager />
            </AppProvider>
        </Sentry.ErrorBoundary>
    );
}


window.addEventListener('DOMContentLoaded', function () {
    const cfg = window as unknown as {
        releaseTag: string | undefined
        sentryDSN: string | undefined
        user: {
            id: string
            email: string
        }
    };

    if (cfg.sentryDSN) {
        Sentry.init({
            autoSessionTracking: false,
            dsn: cfg.sentryDSN,
            release: cfg.releaseTag,
            tracesSampleRate: 0,
        });

        Sentry.setUser(cfg.user);
    }

    const container = document.getElementById('app');
    const root = ReactDOM.createRoot(container);

    root.render(<App />);
});
