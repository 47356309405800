import * as React from 'react';


interface IFormFieldLabelProps {
    children: React.ReactNode
    label: string
    labelStyle?: React.CSSProperties
    style?: React.CSSProperties
}


export default function FormFieldLabel(props: IFormFieldLabelProps): JSX.Element {
    return (
        <label className="s-form-field-label" style={props.style}>
            <span style={props.labelStyle}>{props.label}</span>
            <div>{props.children}</div>
        </label>
    );
}
