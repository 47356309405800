import { choice } from './random';


const Hex = '0123456789ABCDEF'.split('');


export function makeRandomColor(alpha: boolean | number = false): string {
    let color = '#';

    for (let i = 0; i < 6; i++) {
        color += choice(Hex);
    }

    if (alpha === true) {
        color += choice(Hex) + choice(Hex);
    }

    return color;
}
