import {colors} from "@/utils/style";
import * as React from "react";

declare type IBubble = {
    count?: number,
    countTotal?: number,
    size?: 'sm' | 'md',
    style?: React.CSSProperties
}
const Bubble: React.FC<IBubble> = ({style, count, countTotal, size}) => {

    return (
        <div style={{
            ...styles.bubble,
            ...styles.upperCaseCenterText,
            ...style,
            ...(size ? {...styles.size[size]} : {...styles.size.md}),
        }}>
            <div style={{...(size ? {...styles.count.size[size]} : {...styles.count.size.md} )}}>{count ? count : '0'}</div>
            {countTotal && (<div style={{...styles.total.default, ...(size ? {...styles.total.size[size]} : {...styles.total.size.md} )}}>out of {countTotal}</div>)}
        </div>
    )
}

const styles = {
    size: {
        md: {
            height: 100,
            width: 100,
            minHeight: 100,
            minWidth: 100,
            font: 'normal normal bold 40px/45px Arial',
        },
        sm: {
            height: 50,
            width: 50,
            minHeight: 50,
            minWidth: 50,
            fontSize: 12,
            font: 'normal normal bold 28px/10px Arial',
            marginTop: -5
        }
    },
    bubble: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: '50%',
    },
    upperCaseCenterText: {
        color: colors.white,
        textTransform: 'uppercase',
    },
    count: {
        size: {
            md: {},
            sm: {
                marginTop: -6
            },
        }
    },
    total: {
        default: {
        },
        size: {
            md: {
                fontSize: 10,
                marginTop: -10,
            },
            sm: {
                fontSize: 8,
                marginTop: -8
            },
        }
    }
};

export default Bubble;
