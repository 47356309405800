import { getTempSiteId, ISiteMappings } from "./util";

export interface IBatchGeocodingTask {
    task_id: string,
    sites: string[]
}

export interface IGeocodingResult {
    center: number[]
}

export const recreateSitesWithLocations = (sites: IAddToSitesData[], results: {id: string, geocodingResult: IGeocodingResult[]}[], taskAndSites: IBatchGeocodingTask[]) => {
    results.forEach(result => {
        const sitesInResult = taskAndSites.find(task => task.task_id === result.id).sites;

        sitesInResult.forEach((siteResult, index) => {
            const site = sites.find((site, index) => siteResult === getTempSiteId(site, index));
            const centerResult = result.geocodingResult[index].center;
            if (centerResult) {
                site.location.center = { lat: centerResult[1], lng: centerResult[0] };
            }
        });
    });

    return sites;
};

const hasLocation = (site: IAddToSitesData) => {
    return site.location && site.location.center && site.location.center.lat && site.location.center.lng;
};

export const needsLocationGeocoding = (sites: IAddToSitesData[], mappings: ISiteMappings) => {
    if (sites.every(site => hasLocation(site) || !hasSomeAddressData(site))) {
        return false;
    }

    if (!mappings.location && !mappings.lat && !mappings.long) {
        return true;
    }

    return !!sites.find(site =>
        hasSomeAddressData(site)
        && (!site.location?.center || (!site.location?.center?.lat && !site.location?.center?.lng)));
};

const hasSomeAddressData = (site: IAddToSitesData) => {
    return (site.location && site.location.address)
        && (site.location.address.street_address || site.location.address.city || site.location.address.zip_code);
};

export const getDataForGeocoding = (sites: IAddToSitesData[]) => {
    return sites.map((site, index) => {
        if (site.location.center?.lat || site.location.center?.lng || !hasSomeAddressData(site)) {
            return null;
        }

        return {
            city: site.location.address.city,
            street_address: site.location.address.street_address,
            zip_code: site.location.address.zip_code,
            province: site.location.address.province,
            site_id: getTempSiteId(site, index),
            country_code: site.location.address.country_code,
        };
    }).filter(site => site);
};
