import * as React from 'react';
import {NavLink} from 'react-router-dom';
import {colors, style, StyleSheet} from "@/utils/style";

interface INavButtonProps {
  title: string,
  route?: string,
  clickHandler: Function,
  i: number,
  currentIndex: number,
}
export default function NavButton(props: INavButtonProps): JSX.Element {

    const [buttonStyle, setButtonStyle] = React.useState({});

    React.useEffect(() => {

        switch (true) {
            case props.i === props.currentIndex:
                setButtonStyle({
                    color: colors.darkBlue,
                    backgroundColor: 'white'
                });
                break;
            case props.i < props.currentIndex:
                setButtonStyle({
                    color: 'white',
                    backgroundColor: colors.darkBlue,
                });
                break;
            default:
                setButtonStyle({
                    color: 'white',
                    backgroundColor:colors.opaqueBlue,
                });
        }

    }, [props.currentIndex]);

    return (
        <>
          {props.route && (
              <div style={{display: 'flex'}}>
                  <div className={'arrow-rectangle'} style={buttonStyle}>
                      <NavLink
                          to={props.route}
                          style={{...styles.navLink, ...(props.i === props.currentIndex && {color: colors.darkBlue})}}
                          end
                          onClick={() => props.clickHandler(props.i)}
                      >
                          {props.title}
                      </NavLink>
                  </div>
                  <div className={'arrow-rectangle-wrapper'}>
                     <div className={'arrow-rectangle-inner'} style={buttonStyle}/>
                  </div>
              </div>
          )}
            {!props.route && (
                <div style={{display: 'flex'}}>
                    <div className={'arrow-rectangle'} style={buttonStyle}>
                        <a className="button disabled" style={{...styles.navLink, ...(props.i === props.currentIndex && {color: colors.darkBlue})}}>{props.title}</a>
                    </div>
                    <div className={'arrow-rectangle-wrapper'}>
                        <div className={'arrow-rectangle-inner'} style={buttonStyle}/>
                    </div>
                </div>
            )}
        </>
    )
}

const styles = StyleSheet.create({
    navLink: {
        textTransform: 'uppercase',
        padding: '5px 15px',
        fontSize: 14,
        color: 'white',
        height: '100%',
    },
    img: {
        height: 30.5,
    }
});
