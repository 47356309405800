import { colors, style, StyleSheet } from "@/utils/style";
import React from "react";
import { useSites } from "@/utils/customHooks";
import {SiteInfoTabProp} from "@/types/sleap";

export const PrioritySitesInfo: React.FC<SiteInfoTabProp> = ({ onFilter }) => {
  const { sites } = useSites();
  const [activeOptionId, setActiveOptionId] = React.useState(3);

  const totalSites = sites.length;
  const prioritySites = sites.filter(site => site.is_priority_site).length;

  const options = React.useMemo(() => ([
    {
      id: 1,
      label: 'Priority',
      filter: () => (sites.filter(site => site.is_priority_site)),
    }, {
      id: 2,
      label: 'Non Priority',
      filter: () => (sites.filter(site => !site.is_priority_site)),
    }, {
      id: 3,
      label: 'All',
      filter: () => (sites),
    },
  ]), [sites]);

  React.useEffect(() => {

    const queryMap:Record<number, string[]> = {
      1: ['priority_location=True'],
      2: ['priority_location=False'],
    };

    const queries = queryMap[activeOptionId] || [];

    onFilter(queries);

  }, [activeOptionId]);

  return (
    <>
      <div style={{
        ...style.bubble, ...style.centerFlex, ...style.upperCaseCenterText,
        backgroundColor: colors.purple,
        position: 'relative',
      }}>
        {prioritySites}
        <span style={style.totalSpan}>out of {totalSites}</span>
      </div>
      <div style={{ flex: 1, padding: '0 30px', height: '100%' }}>
        <p>Show me: </p>
        <div style={{ ...style.flexRow }}>
          {options.map(option =>
            <button
              style={{ ...styles.button, ...(activeOptionId === option.id && { border: `3px solid ${colors.purple}` }) }}
              key={option.id}
              onClick={() => setActiveOptionId(option.id)}
            >
              {option.label}
            </button>,
          )}
        </div>
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  button: {
    backgroundColor: 'transparent',
    width: 120,
    borderRadius: 20,
    height: 30,
    border: '1px solid white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.white,
  },
});
