import * as React from 'react';
import {t} from '@/utils';
import {colors, style, StyleSheet} from "@/utils/style";
import {BaseModalDialog} from "@/lib/modals";
import {VerticalStack} from "@/lib";

export type ValidationResponse = {
  total_sites_count: number,
  sites_with_errors_count: number,
  problematic_lines_in_file: Record<string, string[]>;
  problematic_row_details: Record<string, string[]>;
}

interface SiteImportValidationProps {
  onClose: () => void,
  validationResponse: ValidationResponse
}


export default function SiteImportValidationDialog(props: SiteImportValidationProps): JSX.Element {

    const successfulImportQuote = `${props.validationResponse.total_sites_count - props.validationResponse.sites_with_errors_count}/${props.validationResponse.total_sites_count}`;

    function convertDataToCSV(data: Record<string, any>): string {

      const headers = Object.keys(data['1']);

      const csvRows: string[] = [];
      csvRows.push(headers.join(','));

      for (const rowKey in data) {
        const rowData = data[rowKey];
        const values = headers.map((header) => rowData[header]);
        csvRows.push(values.join(','));
      }

      return csvRows.join('\n');
    }

    function downloadCSV(): void {

      const csvString = convertDataToCSV(props.validationResponse.problematic_row_details);
      const blob = new Blob([csvString], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'erroneous_rows.csv';
      a.click();
      window.URL.revokeObjectURL(url);
    }

    return (
        <BaseModalDialog onClose={props.onClose} scrollableBody={true} title={'Validation'}>
            <div>
              <p>{`${successfulImportQuote} ${t('siteImport.successfulImportQuote')}`}</p>
              <p>{t('siteImport.rowsWithErrors')}</p>
                <VerticalStack>
                  <ul>
                    {Object.entries(props.validationResponse.problematic_lines_in_file).map(([key, value]) => (
                      <li key={key}>
                        {`${key}: ${value.join(', ')}`}
                      </li>
                    ))}
                  </ul>
                </VerticalStack>
            </div>

          <button
            style={{ ...style.centerFlex, ...styles.downloadButton }}
            onClick={downloadCSV}
          >
            Download CSV
          </button>
        </BaseModalDialog>
    );
}
const styles = StyleSheet.create({
  downloadButton: {
    color: colors.white,
    backgroundColor: colors.darkBlue,
    border: 'none',
    borderRadius: 20,
    height: 30,
    padding: 20,
    marginTop: 20,
  }
});
