import * as React from 'react';
import { colors, StyleSheet } from "@/utils/style";
import Button from '../../lib/Button';
import HorizontalStack from '../../lib/HorizontalStack';
import LabeledTextInput from '../../lib/LabeledTextInput';
import { Icon } from '../../lib';
import { mdiPlusCircle } from '@mdi/js';
import { mdiMinusCircle } from '@mdi/js';
import { useRef } from 'react';

export const RiskPage:React.FC = () => {
  
  const divRef = useRef(null);
  const [riskLink, setRiskLink] = React.useState("");
  const [showPDF, setShowPDF] = React.useState(false);
  const [showForm, setShowForm] = React.useState(false);

  React.useEffect(() => {
    let riskLink = localStorage.getItem('riskLink');
    if (riskLink && riskLink !== ""){
      setRiskLink(riskLink);
      setShowPDF(true);
    }
  }, []);

  const handleSubmitBtn = () => {
        let updatedRiskLink = riskLink.replace("/view?usp=sharing", "/preview");
        localStorage.setItem("riskLink", updatedRiskLink);
        setRiskLink(updatedRiskLink);
        setShowPDF(true);
        setShowForm(false);
  }

  const toggleForm = () => {
    if (showForm){
      setShowForm(false);
    }else{
      setShowForm(true);
      setTimeout(
        function() {
          divRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 250);
    }
  }

  return (
    <div className="riskPage"  style={styles.wrapper}>

        {showPDF && <iframe id="pdfViewer" src={riskLink} width="640" height="480"></iframe>}

        <div className="toggleBtn">
          <Button disabled={false} onClick={toggleForm}>
            {!showForm ? <Icon color="#fff" path={mdiPlusCircle} size={36} /> : <Icon color="#fff" path={mdiMinusCircle} size={36} />}
          </Button>
        </div>
          {showForm &&
            <HorizontalStack>
                <LabeledTextInput
                    id="riskLinkInput"
                    inputStyle={{ color: "white !important" }}
                    label={""}
                    onInput={link => setRiskLink(link)}
                    value={riskLink}
                  />
                  <Button disabled={false} onClick={handleSubmitBtn}>Submit</Button>
              </HorizontalStack>
          }  
        <div ref={divRef}>
        </div>

    </div>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    width: '50%',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    top: 125,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: 5,
    zIndex: 10,
  },
  tableWrapper: {
    width: '100%',
    backgroundColor: colors.darkBlue,
    border: `1px solid ${colors.brightBlue}`,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
});
