export type SortOrder = 'desc' | 'asc';

export const stringSort = (a: string, b: string, sortOrder: SortOrder): number => {
    if (!b || b.length == 0) {
        return -1;
    }
    if (!a || a.length == 0) {
        return 1;
    }

    return a.localeCompare(b) * sortNumber(sortOrder);
};

export const numberSort = (a: number, b: number, sortOrder: SortOrder): number => {
    if (a === b) {
        return 0;
    }
    else if (a === null) {
        return 1;
    }
    else if (b === null) {
        return -1;
    }

    return (a < b ? -1 : 1) * sortNumber(sortOrder);
};

export const booleanSort = (a: boolean, b: boolean, sortOrder: SortOrder): number => {
    return numberSort(Number(a), Number(b), sortOrder);
};

export const dateSort = (a: Date | string, b: Date | string, sortOrder: SortOrder): number => {
    const timestampA = (typeof a === 'string' ? Date.parse(a) : a.getTime());
    const timestampB = (typeof b === 'string' ? Date.parse(b) : b.getTime());

    return (timestampA - timestampB) * sortNumber(sortOrder);
};

export const getSortBy = (sortType: string, key: string, sortOrder: SortOrder): SortOrder | undefined => {
    return sortType === key ? sortOrder : undefined;
};

const sortNumber = (sortOrder: SortOrder): number => {
    return sortOrder === 'asc' ? 1 : -1;
};


export const speciesRoleSort = (a: IAssessedSpecies, b: IAssessedSpecies, sortOrder: SortOrder): number => {
    const criteria = (s: IAssessedSpecies) => [
        s.assessment.apex_predator_rank || 0,
        s.assessment.habitat_creator_rank || 0,
        s.assessment.seed_disperser_rank || 0,
    ];
    const as = criteria(a), bs = criteria(b);

    for (let i = 0; i < as.length; i += 1) {
        if (as[i] !== bs[i]) {
            return as[i] > bs[i] ? (sortOrder === 'asc' ? 1 : -1) : (sortOrder === 'asc' ? -1 : 1);
        }
    }

    if (a.assessment.score !== b.assessment.score) {
        return a.assessment.score >= b.assessment.score ? (sortOrder === 'asc' ? 1 : -1) : (sortOrder === 'asc' ? -1 : 1);
    }

    return 0;
};

/**
 * Sort sites by priority score
 *
 * @param a
 * @param b
 */
export const orderByPriority = (a:INewSite, b: INewSite) => { return b.priority_score - a.priority_score }