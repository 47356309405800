import * as React from 'react';
import classnames from 'classnames';


interface ITextInputProps {
    ariaLabel?: string
    disabled?: boolean
    error?: string
    id: string
    leftAccessory?: React.ReactElement
    onBlur?: () => void
    onEnter?: () => void
    onInput?: (value: string) => void
    placeholder?: string
    rightAccessory?: React.ReactElement
    size?: string
    style?: React.CSSProperties
    type?: string
    value: string
    variant?: 'dark' | 'default'
}


const TextInput = React.forwardRef((props: ITextInputProps, ref: React.ForwardedRef<HTMLInputElement>): JSX.Element => {
    const [focused, setFocused] = React.useState<boolean>(false);
    const [notifyTimer, setNotifyTimer] = React.useState<number>(0);
    const [value, setValue] = React.useState<string>(props.value);
    const classes = classnames('s-text-input s-text-input-' + (props.size || 'regular'), {
        's-text-input-disabled': props.disabled,
        's-text-input-focused': focused,
        's-text-input-has-error': props.error,
        's-text-input-dark': props.variant === 'dark',
    });
    const onValueChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const v = e.currentTarget.value;

        window.clearTimeout(notifyTimer);

        setValue(v);

        if (['Enter', 'NumpadEnter'].includes((e as unknown as { code: string }).code)) {
            props.onInput?.(v);
            props.onEnter?.();
        }
        else {
            setNotifyTimer(window.setTimeout(() => props.onInput?.(v), 75));
        }
    };
    const onBezelClick = (e: React.MouseEvent) => {
        // Treat clicks on bezel (non-input element occupied area) like a focus click to
        // the input

        if (!props.disabled && e.currentTarget.classList.contains('s-text-input')) {
            e.currentTarget.querySelector('input').focus();
        }
    };
    const onBlur = (): void => {
        setFocused(false);

        if (props.onBlur) {
            props.onBlur();
        }
    };

    React.useEffect(() => {
        if (props.value !== value) {
            setValue(props.value);
        }
    }, [props.value]);

    return (
        <>
            <div className={classes} onClick={onBezelClick} style={props.style}>
                {props.leftAccessory}
                <input
                    ref={ref}
                    id={props.id}
                    aria-label={props.ariaLabel ? props.ariaLabel : null}
                    autoComplete="off"
                    disabled={props.disabled}
                    onBlur={onBlur}
                    onChange={onValueChange}
                    onFocus={() => setFocused(true)}
                    onKeyUp={onValueChange}
                    onPaste={onValueChange}
                    placeholder={props.placeholder}
                    role="textbox"
                    type={props.type || 'text'}
                    value={value}
                    aria-describedby={props.id + '-error'} />
                {props.rightAccessory}
            </div>
            {props.error && <span id={props.id + '-error'} role="alert" className="s-text-input-error">{props.error}</span>}
        </>
    );
});

TextInput.displayName = 'TextInput';

export default TextInput;
