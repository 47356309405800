import React from "react";
import { colors, style, StyleSheet } from "@/utils/style";
import { useProject, useTeam } from "@/context";
import { LocationSearch } from "@/project/sleap/Locate/LocationSearch";
import { DetailButton } from "@/project/sleap/Assess/DetailButton";
import Checkbox from "@/utils/Checkbox";
import { AssessmentDetailHeaderProps } from "@/types/sleap";
import Icon from "../../../lib/Icon";
import { mdiTune } from "@mdi/js";
import { ExportButton } from "@/project/sleap/Assess/ExportButton";

export const AssessmentDetailHeader: React.FC<AssessmentDetailHeaderProps> = ({ inputHandler, toggleFilter, filterOpen }) => {
  const project = useProject();
  const team = useTeam();
  const url=`/api/v1/teams/${team.slug}/projects/${project.slug}/reports/${project.latest_report_id}/site/export/csv`
  return (
    <div style={{ ...style.sleapSection, backgroundColor: colors.opaqueWhite }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ ...style.centerFlex, flexDirection: 'column', fontWeight: 'bold' }}>
          <span style={{ fontSize: 12 }}>Step 4</span>
          <span style={{ fontSize: 25, lineHeight: 1 }}>A</span>
        </div>
        <span style={{ marginLeft: 30, fontSize: 25, color: colors.brightBlue }}>ASSESS</span>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: 10, height: 30 }}>
        <LocationSearch inputHandler={inputHandler} />
        <ExportButton url={url}  />
        <FilterButton toggleFilter={toggleFilter} isOpen={filterOpen} />
        <DetailButton />
        <Checkbox checked={true} readonly={true} />
      </div>
   </div>
  );
};

const FilterButton: React.FC<{ toggleFilter: () => void, isOpen: boolean }> = ({ toggleFilter, isOpen }) => {

  return (
    <div onClick={() => toggleFilter()} style={{ ...styles.filterButton, ...(isOpen ? styles.filterOpen : styles.filterClosed) }}>
      <Icon path={mdiTune} color={ isOpen ? colors.brightBlue : colors.white} size={16} />
      <span>FILTER</span>
    </div>
  );
};

const styles = StyleSheet.create({
  filterButton: {
    height: '100%',
    borderRadius: 20,
    backgroundColor: 'transparent',
    opacity: 1,
    padding: '5px 10px',
    color: colors.white,
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    fontSize: 13,
  },
  filterOpen: {
    border: `1px solid ${colors.brightBlue}`,
    color: colors.brightBlue,
  },
  filterClosed: {
    border: `1px solid ${colors.white}`,
    color: colors.white,
  },
});
