import {apiGet} from "@/utils";

export const url = {
    section: 'economic-activity/section',
    division: (query: string) => `economic-activity/division${query}`,
    classes: (query: string) => `economic-activity/isic-class${query}`,
    scopes: 'scopes',
    numberDependencies: 'dependency/number-of-ratings',
    numberImpacts: 'impact/number-of-ratings',
    dependencies: 'dependency',
    impacts: 'impact',
    site: (reportId: number, siteId: number, projectSlug: string) => `projects/${projectSlug}/reports/${reportId}/site/${siteId}`,
    siteCreate: (reportId: number, projectSlug: string) => `projects/${projectSlug}/reports/${reportId}/site`,
    impactsAndDependencies: (projectSlug: string) => `projects/${projectSlug}/impacts_and_dependencies`,
};

export function fetchScopes() {

    return apiGet<iScope[]>(null, url.scopes).then((reply) => {
        if (reply.ok) {
            return reply.data;
        } else {
            throw new Error(`${reply.status}`);
        }
    });
}

export function fetchSections() {

    return apiGet<iSection[]>(null, url.section).then((reply) => {
        if (reply.ok) {
            return reply.data;
        } else {
            throw new Error(`${reply.status}`);
        }
    });
}

export function fetchDivisions(sectionIds: number[]) {

    const query = `?section_ids[]=${sectionIds.join('&section_ids[]=')}`;
    return apiGet<iDivisions>(null, url.division(query)).then((reply) => {
        if (reply.ok) {
            return reply.data;
        } else {
            throw new Error(`${reply.status}`);
        }
    });
}

export function fetchEconomicActivities(divisionIds: number[]) {

    const query = `?division_ids[]=${divisionIds.join('&division_ids[]=')}`;
    return apiGet<iClasses>(null, url.classes(query)).then((reply) => {
        if (reply.ok) {
            return reply.data;
        } else {
            throw new Error(`${reply.status}`);
        }
    });
}

export function fetchImpactsAndDependencies(teamSlug: string, projectSlug: string) {

    return apiGet<{impacts: iCluster[], dependencies: iCluster[]}>(teamSlug, url.impactsAndDependencies(projectSlug)).then((reply) => {
        if (reply.ok) {

            return reply.data;
        } else {
            throw new Error(`${reply.status}`);
        }
    });
}
