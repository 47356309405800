import * as React from 'react';
import { AppContext, makeRasterColormapKey, useProject } from '@/context';
import { updateRasterColormap, updateRasterMetadata } from '@/resources';
import { formatted, makeMapLegendRange } from '@/utils';


interface IExploreMapLegendPanelProps {
    raster: IProjectRasterConfig
}


export default function ExploreMapLegendPanel(props: IExploreMapLegendPanelProps): JSX.Element {
    const { dispatch, state } = React.useContext(AppContext);
    const project = useProject();
    const raster = props.raster;

    React.useEffect(() => {
        if (props.raster && !state.rasters.metadatas[props.raster.id]) {
            updateRasterMetadata(state, dispatch, props.raster.id).then(metadata => {
                const conf = project.rasters[raster.id];

                if (metadata && conf.colormap) {
                    updateRasterColormap(state, dispatch, conf.colormap, metadata.range);
                }
            });
        }
    }, [props.raster]);

    const metadata = state.rasters.metadatas[props.raster.id];
    const colormapKey = raster && metadata ? makeRasterColormapKey({ id: raster?.colormap, stretchRange: metadata.range, valueCount: 10 }) : undefined;
    const colormap = state.rasters.colormaps[colormapKey];

    if (!metadata || !colormap) {
        return <span />;
    }

    const legendRange = makeMapLegendRange(metadata.range)
        .map((entry, ix) => ({ ...entry, color: colormap[ix].cssRGBA }));

    return (
        <div className={'s-explore-map-legend s-explore-map-legend-' + state.layoutSize}>
            {legendRange.map((entry, ix) => <div key={`${ix} ${entry.low}`}>
                <span style={{ background: entry.color }} />
                <span>{formatted(raster.value_format, entry.low)}</span>
                {ix === legendRange.length - 1 && <span>{formatted(raster.value_format, entry.high)}</span>}
            </div>)}
        </div>
    );
}
