import * as React from 'react';
import { mdiAlertCircleOutline, mdiInformationOutline } from '@mdi/js';
import SitePropertiesForm, { checkIsSaveable } from '../SitePropertiesForm';
import { ErrorBoundary } from '@sentry/react';
import { StaticMap } from '@/lib/map';
import { Button, Icon, LoadingAnimation } from '@/lib';
import { getKeyPath, t, toDMSString } from '@/utils';
import { getTempSiteId } from './util';
import './index.scss';


interface ISiteImportEditStepProps {
    data: IAddToSitesData[]
    loading: boolean
    pendingSites: string[]
    updateData: (sites: IAddToSitesData[]) => void
    cancel: () => void
    submit: () => void
}

const requiredSiteProps = ['name', 'location', 'industry', 'country'];

const getMissingPropertyStyle = (propertyKey: string) => {
    return requiredSiteProps.includes(propertyKey) ? 's-missing-value' : 's-missing-value s-missing-value--optional';
};

const getMissingPropertyText = (propertyKey: string) => {
    return requiredSiteProps.includes(propertyKey)
        ? t('ui.' + propertyKey) + ' ' + t('ui.missing')
        : t('ui.no') + ' ' + t('ui.' + propertyKey);
};

function BaseBlock(props: { site: IAddToSitesData }): JSX.Element {
    const site = props.site as unknown as Record<string, string>;
    const addr = props.site.location.address as unknown as Record<string, string>;
    const itemize = (key: string) => <span className={addr[key] ? 's-valid-value' : 's-missing-value'}>{addr[key] || t('ui.' + key) + ' ' + t('ui.missing')}</span>;
    const itemizeSite = (keypath: string, labelKey: string) => <span className={getKeyPath(keypath, site) ? 's-valid-value' : 's-missing-value'}>{getKeyPath(keypath, site) || t('ui.' + labelKey) + ' ' + t('ui.missing')}</span>;

    return <>
        <div style={{ fontFamily: 'Roobert Medium' }}>{t('ui.name')}</div>
        <div>{itemize('name')}</div>

        <div style={{ fontFamily: 'Roobert Medium', marginTop: '.5rem' }}>{t('ui.industry')}</div>
        <div>
            {itemizeSite('industry.name', 'industry')}
        </div>
    </>;
}


function AddressBlock(props: { index: number, pendingSites: string[], site: IAddToSitesData }): JSX.Element {
    const { index, pendingSites, site } = props;
    const addr = site.location.address as unknown as Record<string, string>;
    const itemize = (key: string) =>
        <span
            className={addr[key]
                ? 's-valid-value'
                : getMissingPropertyStyle(key) }>
            {addr[key] || getMissingPropertyText(key)}
        </span>;

    const getLocationString = () => {
        if (!site.location.center || !site.location.center?.lat || !site.location.center?.lng) {
            return null;
        }
        return `${toDMSString(site.location.center?.lat, false)},\u00A0${toDMSString(site.location.center?.lng, true)}`;
    };

    return <>
        <div style={{ fontFamily: 'Roobert Medium' }}>{t('ui.address')}</div>
        <address>
            <div>
                {itemize('street_address')}
            </div>
            <div>
                {itemize('zip_code')}
                {itemize('city')}
            </div>
            <div>
                {itemize('province')}
                {itemize('country')}
            </div>

            <div style={{ fontFamily: 'Roobert Medium', marginTop: '.5rem' }}>{t('ui.location')}</div>
            <div>
                {pendingSites.includes(getTempSiteId(site, index))
                    ? <LoadingAnimation />
                    : getLocationString() || <span className="s-missing-value">{getMissingPropertyText('location')}</span>
                }
            </div>
        </address>
    </>;
}


export default function SiteImportEditStep(props: ISiteImportEditStepProps): JSX.Element {
    const [editingIndex, setEditingIndex] = React.useState<number>(undefined);
    const [editingSite, setEditingSite] = React.useState<IAddToSitesData>(undefined);
    const [isImporting, setIsImporting] = React.useState<boolean>(false);
    const [validateFormOnShow, setValidateFormOnShow] = React.useState<number>(undefined);
    const formRef = React.useRef<{ showErrorsAndFocus: () => void }>(null);

    const errorCount = React.useMemo(() => {
        return props.data.filter(site => !checkIsSaveable(site)).length;
    }, [props.data, props.pendingSites]);

    React.useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    React.useEffect(() => {
        if (formRef?.current && validateFormOnShow !== undefined && editingIndex === validateFormOnShow) {
            formRef.current.showErrorsAndFocus();
            setValidateFormOnShow(undefined);
        }
    }, [editingIndex, validateFormOnShow, formRef]);

    const updateData = () => {
        const sites = [...props.data];
        sites[editingIndex] = editingSite;
        props.updateData(sites);

        setEditing(undefined, undefined);
    };

    const setLocation = (point: ILatLng) => {
        setEditingSite({ ...editingSite, location: { ...editingSite.location, center: point } });
    };

    const getPin = (site: IAddToSitesData) => {
        if (!site.location.center) {
            return { center: { lat: 0, lng: 0 }, id: 'site-0-0' };
        }

        return {
            center: site.location.center, id: `site-${site.location.center.lat}-${site.location.center.lng}`,
        };
    };

    const setEditing = (index: number, site: IAddToSitesData) => {
        setEditingIndex(index);
        setEditingSite(site);
    };

    const getFirstInvalid = (): number => {
        return props.data.findIndex(site => !checkIsSaveable(site));
    };

    const next = () => {
        const invalidIndex = getFirstInvalid();
        if (invalidIndex < 0) {
            setIsImporting(true);
            props.submit();
        }
        else {
            setEditing(invalidIndex, props.data[invalidIndex]);
            setValidateFormOnShow(invalidIndex);
        }
    };

    return (
        <div>
            <div className="s-site-import-info">
                <div className="s-bold-text">
                    <Icon path={mdiInformationOutline} size={24} />
                    {t('ui.sites_import.preview_info_sites').replace('{1}', props.data.length.toString())}
                </div>
                <div>{t('ui.sites_import.preview_info')}</div>
                { props.pendingSites && props.pendingSites.length > 0 ?
                    <LoadingAnimation />
                    : errorCount > 0 &&
                        <>
                            <div className="s-site-import-info-errors">
                                <Icon path={mdiAlertCircleOutline} size={24} color="#CF3F3F" />
                                {t('ui.sites_import.preview_info_detail').replace('{1}', errorCount.toString())}
                            </div>
                            <div>
                                {t('ui.sites_import.preview_info_detail_2')}
                            </div>
                        </>
                }
            </div>

            {props.data.map((site, index) =>
                <div key={index}>
                    {editingSite && editingIndex === index
                        ?
                        <div className="s-site-import-edit-form-container">
                            <ErrorBoundary fallback={<p>{t('ui.map_loading_error')}</p>}>
                                <div className="s-map-container-small s-edit-site-map">
                                    <StaticMap
                                        onClick={(point: ILatLng) => setLocation(point)}
                                        onPinClick={(pin: IMapPin) => setLocation(pin.center)}
                                        center={editingSite.location.center}
                                        interactive={true}
                                        layers={[]}
                                        pins={[getPin(editingSite)]}
                                        rasters={{}}
                                        zoom={editingSite.location.center ? 7 : 0} />
                                </div>
                            </ErrorBoundary>

                            <SitePropertiesForm
                                ref={formRef}
                                data={editingSite}
                                setData={changedSite => setEditingSite(changedSite)}
                                submitText={t('actions.save')}
                                isSaving={props.loading}
                                onCancel={() => setEditing(undefined, undefined)}
                                onSubmit={updateData}
                            />
                        </div>
                        :
                        <div className="s-site-import-site-preview-container">
                            <div className="s-site-import-site-preview-container-data">
                                <div>
                                    <BaseBlock site={site} />
                                </div>
                                <div>
                                    <AddressBlock index={index} pendingSites={props.pendingSites} site={site} />
                                </div>
                            </div>
                            <Button disabled={props.pendingSites.includes(getTempSiteId(site, index))} onClick={() => setEditing(index, site)} style={{ height: 'fit-content' }}>
                                {t('actions.edit')}
                            </Button>
                        </div>
                    }
                </div>,
                )}
                <div className="s-site-import-button-container">
                    <Button disabled={isImporting} onClick={props.cancel}>{t('actions.back')}</Button>
                    <Button disabled={isImporting} onClick={next} variant="primary">
                        {t('ui.sites_import.upload')}
                    </Button>
                </div>
        </div>
    );
}
