import * as React from 'react';


interface IBreadcrumbPathProps {
    items: {
        label: string
        route: string
    }[]
    onItemClick?: (item: { label: string, route: string }) => void
    style?: React.CSSProperties
}


export default function BreadcrumbPath(props: IBreadcrumbPathProps): JSX.Element {
    return (
        <div className="s-breadcrumb-path" style={props.style}>
            {props.items.map((item, ix) => <span
                key={`${ix} ${item.route}`}
                onClick={() => props.onItemClick(item)}>{item.label}</span>)}
        </div>
    );
}
