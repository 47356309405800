const artificialHabitats = [
    { id: "14.0", name: "Artificial – Terrestrial" },
    { id: "14.1", name: "Arable Land" },
    { id: "14.2", name: "Pastureland" },
    { id: "14.3", name: "Plantations" },
    { id: "14.4", name: "Rural Gardens" },
    { id: "14.5", name: "Urban Areas" },
    { id: "14.6", name: "Subtropical/Tropical Heavily Degraded Former Forest" },
    { id: "15.0", name: "Artificial – Aquatic" },
    { id: "15.1", name: "Water Storage Areas (over 8 ha)" },
    { id: "15.2", name: "Ponds (below 8 ha)" },
    { id: "15.3", name: "Aquaculture Ponds" },
    { id: "15.4", name: "Salt Exploitation Sites" },
    { id: "15.5", name: "Excavations (open)" },
    { id: "15.6", name: "Wastewater Treatment Areas" },
    { id: "15.7", name: "Irrigated Land (including irrigation channels)" },
    { id: "15.8", name: "Seasonally Flooded Agricultural Land" },
    { id: "15.9", name: "Canals and Drainage Channels, Ditches" },
    { id: "15.10", name: "Karst and Other Subterranean Hydrological Systems (human-made)" },
    { id: "15.11", name: "Marine Anthropogenic Structures" },
    { id: "15.12", name: "Mariculture Cages" },
    { id: "15.13", name: "Marine/Brackish-culture Ponds" },
    { id: "16.0", name: "Introduced Vegetation" },
    { id: "17.0", name: "Other" },
    { id: "18.0", name: "Unknown" }
];

interface Habitat {
    id: string;
    name: string;
    percentage: number;
  }
  
  export function calculateNaturalHabitatPercentage(assessmentHabitats: Habitat[]): string {
    let artificialHabitatPercentage = 0;
  
    for (const habitat of assessmentHabitats) {
        if (artificialHabitats.some(artificialHabitat => artificialHabitat.id === habitat.id)) {
            artificialHabitatPercentage += habitat.percentage;
        }
    }
  
    const naturalHabitatPercentage = 100 - (artificialHabitatPercentage * 100);
    return naturalHabitatPercentage.toFixed(2) + ' %';
  }
 
