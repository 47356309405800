export interface ISiteMappings {
    name: string
    location: string
    street: string
    city: string
    zip: string
    province: string
    country: string
    industry: string
    lat: string
    long: string
}

export interface ISiteFileData {
    [key: string]: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [key: number]: any
    }
}

interface CodeAndName {
    code: string
    name: string
}

export type LatLngImportMode = 'combined' | 'separate' | 'unsupplied';

export const Alpha2CountryFormat = /^([A-Z]){2}$/;
export const CoordinateFormat = /^((-?|\+?)?\d+(\.\d+)?)$/;
export const IndustryCodeFormat = /^([0-9]){2}$/;
export const LatCommaLongFormat = /^((-?|\+?)?\d+(\.\d+)?),\s*((-?|\+?)?\d+(\.\d+)?)$/;

export const createSites = (fileData: ISiteFileData, mappings: ISiteMappings, industries: CodeAndName[], countries: CodeAndName[]): IAddToSitesData[] => {
    const sites: IAddToSitesData[] = [];
    const amountOfSites = Object.values(fileData[mappings.name]).length;

    for (let index = 0; index < amountOfSites; index++) {
        const country = countries.find(country => country.code === getRowValue(fileData, mappings.country, index));

        sites.push({
            location: {
                address: {
                    name: getRowValue(fileData, mappings.name, index),
                    street_address: getRowValue(fileData, mappings.street, index),
                    city: getRowValue(fileData, mappings.city, index),
                    province: getRowValue(fileData, mappings.province, index),
                    zip_code: getRowValue(fileData, mappings.zip, index),
                    country_code: country?.code,
                    country: country?.name,
                },
                center: createCenter(fileData, mappings, index),
            },
            industry: createIndustry(fileData, mappings, index, industries),
        } as IAddToSitesData);
    }

    return sites;
};

const createCenter = (data: ISiteFileData, mappings: ISiteMappings, index: number): ILatLng => {
    if (mappings.location) {
        const location = getRowValue(data, mappings.location, index);
        if (!location || !LatCommaLongFormat.test(location)) {
            return undefined;
        }
        const locationParts = location.split(',');
        return { lat: +locationParts[0], lng: +locationParts[1] } as ILatLng;
    }
    else {
        const lat = getRowValue(data, mappings.lat, index);
        const lng = getRowValue(data, mappings.long, index);

        return (lat && lng && CoordinateFormat.test(lat) && CoordinateFormat.test(lng))
            ? { lat: +lat, lng: +lng } as ILatLng
            : undefined;
    }
};

const createIndustry = (data: ISiteFileData, mappings: ISiteMappings, index: number, industries: CodeAndName[]) => {
    const industryCode = mappings.industry ? getRowValue(data, mappings.industry, index) : undefined;
    return getIndustry(industryCode, industries);
};

const getIndustry = (industryCode: string, industries: CodeAndName[]): CodeAndName => {
    if (!industryCode) {
        return undefined;
    }
    else {
        return industries.find(industry => industry.code === industryCode);
    }
};

const getRowValue = (data: ISiteFileData, columnName: string, row: number): string => {
    if (!columnName) {
        return '';
    }

    const value = data[columnName][row];

    return value ? value + '' : '';
};


export const hasOneValueWithFormat = (format: RegExp, list: string[]) => {
    return !!list.find((value: string) => format.test(value));
};

export const getTempSiteId = (site: IAddToSitesData, index: number) => {
    return site.location.address.name + index;
};
