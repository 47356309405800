import * as React from 'react';
import { colors, style, StyleSheet } from "@/utils/style";
import { isThreatenedSpecies, isProtectedSpecies, isKeyStoneSpecies, t } from "@/utils";
import { StaticMap } from "@/lib/map";
import Dropdown from "@/utils/Dropdown";
import {booleanSort, getSortBy, SortOrder, speciesRoleSort, stringSort} from "@/utils/sort";
import { TableHeaderCell } from "@/lib";
import { SiteSpeciesItem } from "@/project/site/species/index";
import './index.scss';
import Checkbox from "@/utils/Checkbox";

interface SiteOverviewProps {
  site: INewSiteDetailed
  bbox: number[]
  layers: string[]
  onLayerLoad: (id: string, layer: IKeyAreaLayer) => void,
  onClick: (species: ISpecies, what: 'info' | 'map') => void
}

type SortType = 'species' | 'umbrella' | 'keystone' | 'protected' | 'threatened';

const SpeciesRoleDropdownItems = [
  { id: -1, label: t('ui.all'), apiLabel: '_all' },
  { id: 3, group: t('ui.habitat_role'), label: t('ui.apex_predator'), apiLabel: 'apex_predator' },
  { id: 4, group: t('ui.habitat_role'), label: t('ui.habitat_creator'), apiLabel: 'habitat_creator' },
  { id: 5, group: t('ui.habitat_role'), label: t('ui.seed_disperser'), apiLabel: 'seed_disperser' },
  { id: 6, group: t('ui.habitat_role'), label: t('ui.other'), apiLabel: 'other' },
];

export const SpeciesPage: React.FC<SiteOverviewProps> = ({ site, bbox, layers, onLayerLoad, onClick }) => {

  const threatenedSpecies = site.species.filter(s => isThreatenedSpecies(s)).length;
  const protectedSpecies = site.species.filter(s => isProtectedSpecies(s)).length;
  const keystoneSpecies = site.species.filter(s => isKeyStoneSpecies(s)).length;

  return (
    <div style={{ display: 'flex', flexDirection: 'row', flex: 1, gap: 30 }}>

      <div style={styles.wrapper}>
        <SpeciesTable site={site} onClick={onClick} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: 20 }}>
        <div style={styles.summaryHeader}>
          <HeaderKpi label={t('ui.total_species')} value={site.assessment?.assessed_species_count} />
          <HeaderKpi label={t('ui.threatened_species')} value={threatenedSpecies} />
          {/* <HeaderKpi label={t('ui.protected_species')} value={protectedSpecies} /> */}
          <HeaderKpi label={t('ui.keystone_species')} value={keystoneSpecies} />
        </div>
        <div style={{ position: 'relative', minHeight: 500 }}>
          <StaticMap
            bbox={bbox}
            center={site.latlng}
            circles={[
              { center: site.latlng, id: `site-${site.id}-inner`, radius: site.parameters.inner_range_meters },
              { center: site.latlng, id: `site-${site.id}-outer`, radius: site.parameters.outer_range_meters },
            ]}
            layers={layers}
            onLayerLoad={onLayerLoad}
            pins={[{ center: site.latlng, id: 'site-' + site.id, text: site.name ?? site.site_id }]}
            rasters={{}}
            zoom={7} />
        </div>
      </div>
    </div>
  );
};

const SpeciesTable: React.FC<{site: INewSiteDetailed, onClick:(species: ISpecies, what: 'info' | 'map') => void}> = ({ site, onClick }) => {

  const [sortType, setSortType] = React.useState<SortType>('species');
  const [sortOrder, setSortOrder] = React.useState<SortOrder>('desc');

  const [speciesTypeIds, setSpeciesTypeIds] = React.useState<number[]>([]);
  const [roleIds, setRoleIds] = React.useState<number[]>([]);
  const speciesTypes = [{ id: 1, label: t('ui.protected_species')}, { id: 2, label: t('ui.threatened_species')}]

  const onSort = (order: SortOrder, type: SortType) => {
    setSortType(type);
    setSortOrder(order);
  };

  const filteredSpeciesList = React.useMemo(() => {
    let species = [].concat(site.species).filter(s => isKeyStoneSpecies(s));

    if (speciesTypeIds.length > 0) {

      const selectedSpeciesLabels = speciesTypeIds.map(id => speciesTypes.find(c => c.id === id)?.label);

      if (selectedSpeciesLabels.includes(t('ui.protected_species'))) {
        species = species.filter(s => isProtectedSpecies(s));
      }

      if (selectedSpeciesLabels.includes(t('ui.threatened_species'))) {
        species = species.filter(s => isThreatenedSpecies(s));
      }
    }

    const selectedRoleLabels = SpeciesRoleDropdownItems
        .filter(selectedRole => roleIds.includes(selectedRole.id))
        .map(filteredRole => filteredRole.apiLabel);

    let filteredSpecies = selectedRoleLabels.length === 0 ? species : []

    selectedRoleLabels.forEach((selectedRoleLabel) => {

      if (['apex_predator', 'habitat_creator', 'seed_disperser'].includes(selectedRoleLabel)) {
        filteredSpecies = filteredSpecies.concat(species.filter(s => s.assessment[selectedRoleLabel + '_rank'] > 0));
        console.log('filteredSpecies INSIDE XXXXXXXX ', filteredSpecies);
      } else if (selectedRoleLabel === 'other') {
        filteredSpecies = filteredSpecies.concat(species.filter(s => s.assessment.apex_predator_rank === undefined && s.assessment.habitat_creator_rank === undefined && s.assessment.seed_disperser_rank === undefined));
      }
    });

    species = filteredSpecies;
    

    return species.sort((a, b) => {
      if (sortType === 'species') {
        return stringSort(a.species.name, b.species.name, sortOrder);
      }
      else if (sortType === 'protected') {
        return booleanSort(isProtectedSpecies(a), isProtectedSpecies(b), sortOrder);
      }
      else if (sortType === 'threatened') {
        return booleanSort(isThreatenedSpecies(a), isThreatenedSpecies(b), sortOrder);
      } else {
        return speciesRoleSort(a, b, sortOrder);
      }
    });
  }, [speciesTypeIds, site.species, roleIds, sortOrder, sortType]);

  

  const handleSetRole = (ids: number[]): void => {

    const oldSelect = roleIds;
    const newSelect = ids.filter(newId => !oldSelect.includes(newId));

    if (ids.length === 0 || newSelect.includes(-1)) {

      setRoleIds([]);
    } else {

      setRoleIds(ids)
    }
  };

  const handleSetSpeciesType = (selectedId: number, check: boolean): void => {

    if (check) {

      setSpeciesTypeIds(prev => [...prev, selectedId]);
    } else {

      setSpeciesTypeIds(speciesTypeIds.filter(id => id !== selectedId));
    }
  };

  return (
    <>
    <div style={{ ...style.flexRow, ...styles.filterBar }}>
      <Dropdown
        placeholder={'key-stone species'}
        dropdownStyle={{ padding: '5px 10px', marginBottom: 'unset', fontSize: 13, height: 'unset' }}
        listDropdownStyle={{ top: "unset" }}
        handler={handleSetRole}
        options={SpeciesRoleDropdownItems}
        selected={roleIds}
        flat
        multiple
        selectedRight
      />
      <div style={{ ...style.flexRow, justifyContent: "right", flex: 2 }}>
          {speciesTypes.map((species, i) => (
            <div id={"species_" + species.id} style={{ marginRight: 10 }}>
              <Checkbox
                checked={speciesTypeIds.includes(species.id)}
                label={species.label}
                checkHandler={(check:boolean) => handleSetSpeciesType(species.id, check)}
              />
            </div>
          ))}
      </div>
    </div>

      <div className={'table-container'}>
        <table className="s-table s-table-fixed species-table">
          <thead>
          <tr>
            <TableHeaderCell
              onClick={(arg: SortOrder) => onSort(arg, 'species')}
              sortedBy={getSortBy(sortType, 'species', sortOrder)}
              label={t('ui.species')}
            />
            <TableHeaderCell
              label={t('ui.keystone_species')}
              onClick={(arg: SortOrder) => onSort(arg, 'keystone')}
              sortedBy={getSortBy(sortType, 'keystone', sortOrder)}
            />
            <TableHeaderCell
              label={t('ui.protected_species')}
              onClick={(arg: SortOrder) => onSort(arg, 'protected')}
              sortedBy={getSortBy(sortType, 'protected', sortOrder)}
            />
            <TableHeaderCell
              label={t('ui.threatened_species')}
              onClick={(arg: SortOrder) => onSort(arg, 'threatened')}
              sortedBy={getSortBy(sortType, 'threatened', sortOrder)}
              textAlign={'end'}
            />
          </tr>
          </thead>
          <tbody>
          {filteredSpeciesList.map((s: IAssessedSpecies) => <SiteSpeciesItem
            key={s.species.id}
            assessment={s.assessment}
            onClick={what => onClick(s.species, what)}
            species={s.species}
            />
          )}
          </tbody>
        </table>
      </div>
  </>
  );
};


export const HeaderKpi: React.FC<{ label: string, value: number | string }> = ({ label, value }) => {

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10, alignItems: 'center' }}>
      <span style={{ fontSize: 12, textTransform: 'uppercase' }}>{label}</span>
      <span style={{ fontSize: 30, color: colors.brightBlue }}>{value}</span>
    </div>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    width: '50%',
    borderRadius: 10,
    border: `1px solid ${colors.brightBlue}`,
    padding: 10,
    backgroundColor: colors.darkBlue,
  },
  summaryHeader: {
    height: 90,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.darkBlue,
    borderRadius: 10,
    padding: '5px 20px',
  },
  filterBar: {
    borderRadius: 10,
    backgroundColor: colors.opaqueWhite,
    padding: 10,
    alignItems: 'center',
    gap: 10,
  },
  export: {
    height: '100%',
    borderRadius: 20,
    backgroundColor: 'transparent',
    opacity: 1,
    padding: '5px 10px',
    color: colors.white,
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    border: `1px solid ${colors.white}`,
    fontSize: 13,
  },
});
