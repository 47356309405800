import * as React from 'react';
import classnames from 'classnames';


interface IRadioProps {
    checked: boolean
    disabled?: boolean
    help?: string
    label: string | React.ReactElement
    onToggle?: (checked: boolean) => void
    style?: React.CSSProperties
}


export default function Radio(props: IRadioProps): JSX.Element {
    const onClick = (e: React.MouseEvent<HTMLSpanElement>) => {
        const t = e.currentTarget;

        e.preventDefault();

        if (!props.disabled && t.matches('.s-radio-box, .s-radio-label')) {
            props.onToggle?.(!props.checked);
        }
    };
    const onKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>): void => {
        if (!props.disabled && e.code === 'Space') {
            props.onToggle?.(!props.checked);
        }
    };

    return (
        <div className={classnames('s-radio', { 's-radio-checked': props.checked })} style={props.style}>
            <span
                aria-checked={props.checked ? 'true' : 'false'}
                className="s-radio-box"
                onClick={onClick}
                onKeyDown={onKeyDown}
                role="radio"
                tabIndex={props.disabled ? -1 : 0} />
            <span className="s-radio-label" onClick={onClick}>
                <span>{props.label}</span>
                {props.help && <span>{props.help}</span>}
            </span>
        </div>
    );
}
