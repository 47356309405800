import * as React from 'react';
import classnames from 'classnames';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
import Icon from './Icon';


interface IDropdownProps {
    align?: 'left' | 'right'
    disabled?: boolean
    error?: string;
    id?: string
    labelId?: string
    items: IDropdownItem[]
    label: string
    onBlur?: () => void
    onItemClick?: (id: string) => void
    placeholder?: string
    selectedIcon?: string
    selectedIds?: string[]
    size?: string
    style?: React.CSSProperties
    variant?: 'hud' | 'selected'
}

export interface IDropdownItem {
    id: string
    label: string
    group?: string
}

function getPreferredMenuEdge(y: number): string {
    return y < window.innerHeight / 2 ? 'below' : 'above';
}


const Dropdown = React.forwardRef((props: IDropdownProps, ref: React.ForwardedRef<HTMLDivElement>): JSX.Element => {
    const [menuEdge, setMenuEdge] = React.useState<string>(undefined);
    const [focusIndex, setFocusIndex] = React.useState<number>(null);
    const [selectedId, setSelectedId] = React.useState<string>(null);
    const [filterText, setFilterText] = React.useState<string>('');
    const timerRef = React.useRef(null);

    const openMenuKeyboardInput = (e: React.KeyboardEvent) => {
        e.stopPropagation();
        e.preventDefault();

        if (!menuEdge) {
            setMenuEdge(edge => edge ? undefined : getPreferredMenuEdge((e.target as HTMLDivElement).clientTop));
        }
    };

    const onButtonClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const t = e.target as HTMLDivElement;

        if (!props.disabled && (t.matches('.s-dropdown, .s-dropdown-button, .s-dropdown-button > *'))) {
            setMenuEdge(getPreferredMenuEdge(e.clientY));
        }
    };

    const onItemClick = (item: IDropdownItem) => {
        if (!props.disabled) {
            setMenuEdge(undefined);

            if (!props.selectedIds) {
                setSelectedId(item.id);
            }

            props.onItemClick?.(item.id);
        }
    };

    const onCharacterInput = (character: string): void => {
        const items = props.items.filter(item => !item.id || !item.label);
        const fullFilter = (filterText + character).toLowerCase();

        const fullMatchIndex = items.findIndex(item => item.label.toLowerCase() === fullFilter);
        if (fullMatchIndex >= 0) {
            setFocusIndex(fullMatchIndex);
        }
        else {
            //if user has typed one character multiple times, scroll through matching options
            if (/^(.)\1+$/.test(fullFilter)) {
                const itemsToScroll = items.filter(item => item.label[0].toLocaleLowerCase() === character);
                if (itemsToScroll.length > 0) {
                    const index = fullFilter.length % itemsToScroll.length;
                    setFocusIndex(items.findIndex(item => item.id === itemsToScroll[index].id));
                }
            }
            else {
                const index = items.findIndex(item => item.label.toLowerCase().startsWith(fullFilter));
                if (index >= 0) {
                    setFocusIndex(index);
                }
            }
        }

        setFilterText(fullFilter);
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
        const t = e.target as HTMLDivElement;
        if (e.code === 'Escape') {
            e.stopPropagation();
            e.preventDefault();

            setMenuEdge(undefined);
        }
        else if (e.code === 'Space' || e.code === 'Enter') {
            e.stopPropagation();
            e.preventDefault();

            if (menuEdge) {
                onItemClick(props.items[focusIndex]);
            }
            else {
                setMenuEdge(getPreferredMenuEdge(t.clientTop));
            }
        }
        // If alt key pressed, open menu without moving focus
        // If alt key not pressed, open menu and move focus downward
        else if (e.code === 'ArrowDown') {
            openMenuKeyboardInput(e);

            if (!e.altKey) {
                if (focusIndex === null || focusIndex >= props.items.length - 1) {
                    setFocusIndex(0);
                }
                else {
                    setFocusIndex(focusIndex + 1);
                }
            }
        }
        // If alt key pressed, close menu and select focused item
        // If alt key not pressed, open menu and move focus upward
        else if (e.code === 'ArrowUp') {
            if (e.altKey) {
                onItemClick(props.items[focusIndex]);
            }
            else {
                openMenuKeyboardInput(e);

                if (focusIndex === null || focusIndex === 0) {
                    setFocusIndex(props.items.length - 1);
                }
                else {
                    setFocusIndex(focusIndex - 1);
                }
            }
        }
        else if (e.code === 'Home') {
            openMenuKeyboardInput(e);
            setFocusIndex(0);
        }
        else if (e.code === 'End') {
            openMenuKeyboardInput(e);
            setFocusIndex(focusIndex - 1);
        }
        else if (e.code === 'Tab' && menuEdge) {
            onItemClick(props.items[focusIndex]);
        }
        else if (e.key !== undefined && e.key.length === 1) {
            openMenuKeyboardInput(e);
            onCharacterInput(e.key);
        }
    };

    const isSelected = (id: string) => {
        return (props.selectedIds ? props.selectedIds.find(item => item === id) : selectedId === id);
    };

    const getItemClasses = (item: IDropdownItem, index: number) => {
        let classNames = '';
        classNames += isSelected(item.id) ? ' s-dropdown-item--selected' : '';
        classNames += item.group ? ' s-dropdown-item--grouped' : '';
        classNames += index === focusIndex ? ' s-dropdown-item--focused' : '';
        return classNames;
    };

    const showGroupHeading = (item: IDropdownItem, index: number) => {
        return item.group && (index === 0 || props.items[index - 1].group !== item.group);
    };

    React.useLayoutEffect(() => {
        if (!menuEdge) {
            setFocusIndex(null);
            return;
        }

        const hide = (e: MouseEvent) => {
            const t = e.currentTarget as unknown as HTMLElement;

            if (!t.closest('.s-dropdown')) {
                setMenuEdge(undefined);
            }
        };

        window.setTimeout(() => {
            document.body.addEventListener('click', hide);
        }, 75);

        return () => document.body.removeEventListener('click', hide);
    }, [menuEdge]);

    React.useEffect(() => {
        if (filterText) {
            clearTimeout(timerRef.current);

            timerRef.current = setTimeout(() => {
                setFilterText('');
            }, 500);
        }
    }, [filterText]);

    React.useEffect(() => {
        return () => clearTimeout(timerRef.current);
      }, []);

    const classes = classnames(
        's-dropdown',
        's-dropdown-' + (props.variant || 'default'),
        's-dropdown-' + (props.size || 'md'),
        's-dropdown-' + (props.align || 'right'),
        { 's-dropdown-disabled': props.disabled },
        { 's-text-input-has-error': !!props.error },
    );

    const chevronColor = { hud: '#EEE', selected: '#FFF' }[props.variant] || '#999';

    return (
        <>
            <div
                ref={ref}
                id={props.id}
                className={classes}
                onClick={onButtonClick}
                onBlur={props.onBlur}
                onKeyDown={onKeyDown}
                role="dropdown"
                style={props.style}
                tabIndex={props.disabled ? -1 : 0}
                aria-describedby={props.id + '-error'}
                aria-labelledby={props.labelId}>
                <div className="s-dropdown-button">
                    <span className={!props.label && props.placeholder ? 's-placeholder' : undefined}>{props.label || props.placeholder || '—'}</span>
                    <svg viewBox="0 0 24 24" width={16} height={16} style={{ margin: '1px 0 0 3px' }}>
                        <path d={menuEdge ? mdiChevronUp : mdiChevronDown} fill={chevronColor} strokeWidth="0.5" />
                    </svg>
                </div>
                {menuEdge &&
                    <div className={'s-dropdown-menu s-dropdown-menu-' + menuEdge}>
                        {props.items.map((item, index) => <React.Fragment key={`${props.id}-wrapper-${index}`}>
                            {showGroupHeading(item, index) &&
                                <div key={`${props.id}-group-${index}`} className="s-dropdown-item-group-title" tabIndex={-1}>
                                    {item.group}
                                </div>
                            }
                            <div
                                key={`${item.id}-item-${index}`}
                                id={`${props.id} ${index}`}
                                onClick={() => onItemClick(item)}
                                className={'s-dropdown-item' + getItemClasses(item, index)}
                            >
                                {item.label}
                                {(isSelected(item.id) && props.selectedIcon) && <Icon size={16} path={props.selectedIcon} />}
                            </div>
                        </React.Fragment>)}
                </div>}
            </div>
            {props.error && <div id={props.id + '-error'} role="alert" className="s-text-input-error">{props.error}</div>}
        </>
    );
});

Dropdown.displayName = 'Dropdown';

export default Dropdown;
