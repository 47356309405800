import { apiGet } from '@/utils';
import { SFDRReportChanged } from '@/context';


type IGenericDispatch = (action: IGenericAction) => void;


export async function updateSFDRReport(state: IAppState, dispatch: IGenericDispatch, id: number): Promise<ISFDRReport | null> {
    const project = document.location.pathname.split('/')[2];
    const reply = await apiGet<{ report: ISFDRReport }>(state.config.team.slug, `projects/${project}/sfdr/${id}`);

    if (!reply.ok) {
        return null;
    }

    dispatch({ type: SFDRReportChanged, report: reply.data.report });

    return reply.data.report;
}
