import { colors, style } from "@/utils/style";
import React from "react";
import Checkbox from "@/utils/Checkbox";
import { apiGet } from "@/utils";
import { url } from "@/rest/apiHelper";
import { AppContext, useProject, useTeam } from "@/context";
import Dropdown from "@/utils/Dropdown";
import { initImpactDependencyClusters } from "@/lib/AppLoader";
import { DropdownOption, SiteInfoTabProp } from "@/types/sleap";

export const ImpactSitesInfo: React.FC<SiteInfoTabProp> = ({ onFilter }) => {
  const project = useProject();

  const allImpacts = { id: -1, label: 'ALL' };

  const [selectedScores, setSelectedScores] = React.useState<string[]>([]);
  const [numberImpacts, setNumberImpacts] = React.useState<number>(0);
  const [applicableImpacts, setApplicableImpacts] = React.useState<number>(0);
  const [impacts, setImpacts] = React.useState<DropdownOption[]>([]);
  const [selectedImpacts, setSelectedImpacts] = React.useState<DropdownOption[]>([allImpacts]);
  const { state, dispatch } = React.useContext(AppContext);
  const team = useTeam();

  const [scoreOptions, setScoreOptions] = React.useState<{ label: string, checked: boolean }[]>([
    { label: 'VH', checked: false },
    { label: 'H', checked: false },
    { label: 'M', checked: false },
  ]);

  React.useEffect(() => {

    fetchImpacts();
  }, []);

  React.useEffect(() => {

    if (!state?.impactClusters?.length) {

      initImpactDependencyClusters(dispatch, team.slug, project.slug);
    }
 else {

      let impactRatings:iCluster[] = [];
      impactRatings = state?.impactClusters.slice(0, 3);
      const impacts = impactRatings.reduce((sum, impact) => sum + impact.values.length, 0);
      setApplicableImpacts(impacts);
    }
  }, [state?.impactClusters]);

  React.useEffect(() => {

    setSelectedScores(scoreOptions.filter(score => score.checked).map(score => score.label));
  }, [scoreOptions]);

  React.useEffect(() => {

    const queries = [];
    if (!selectedImpacts.some(dep => dep.id === -1)) {

      queries.push(`impact_ids[]=${selectedImpacts.map(dep => dep.id).join("&impact_ids[]=")}`);
    }
    if (selectedScores.length) {

      queries.push(`impact_ratings[]=${selectedScores.join("&impact_ratings[]=")}`);
    }
    onFilter(queries);

  }, [selectedScores, selectedImpacts]);

  const fetchImpacts = () => {

    apiGet<string[]>(null, url.impacts).then((reply) => {

      if (reply.ok) {
        const impactsFromDb = reply.data.map((impact, i) => ({ id: i, label: impact.replaceAll('_', ' ').toUpperCase() }));
        impactsFromDb.unshift(allImpacts);
        setImpacts(impactsFromDb);
        setNumberImpacts(reply.data.length);
      }
 else {
        throw new Error(`${reply.status}`);
      }
    });
  };

  const handleCheck = (i: number, check: boolean) => {

    setScoreOptions(prev => {
      const newArray = prev.slice();
      newArray.splice(i, 1, {
        ...prev[i],
        checked: check,
      });
      return newArray;
    });
  };

  const handleSelect = (selected: number[]) => {

    if (!selected.length || selected[selected.length - 1] === -1) {

      setSelectedImpacts([impacts.find(impact => impact.id === -1)]);
    }
 else {

      let newSelected: DropdownOption[] = selected.map(select => impacts.find(impact => impact.id === select));
      if (selected[0] === -1) {
        newSelected = newSelected.slice(1);
      }
      setSelectedImpacts(newSelected);
    }

  };

  return (
    <>
      <div style={{
        ...style.bubble, ...style.centerFlex, ...style.upperCaseCenterText,
        backgroundColor: colors.red,
        position: 'relative',
      }}>
        {applicableImpacts}
        <span style={style.totalSpan}>out of {numberImpacts}</span>
      </div>
      <div style={{ flex: 1, padding: '0 30px', height: '100%', ...style.flexRow, alignItems: 'flex-start', gap: 20 }}>
          <div style={{ flex: 1 }}>
            <Dropdown
              label={'Show me:'}
              options={impacts}
              selected={selectedImpacts.map(s => s.id)}
              multiple={true}
              handler={handleSelect}
              labelStyle={{ fontSize: 16 }}
              listDropdownStyle={{ maxHeight: 100 }}
            />
          </div>
          <div style={{ flex: 1 }}>
            <span>Rating: </span>
            <div style={{ ...style.flexRow, alignItems: 'center' }}>
              {scoreOptions.map((score, i) => (
                <Checkbox
                  checked={score.checked}
                  label={score.label}
                  key={i}
                  checkHandler={(check:boolean) => handleCheck(i, check)}
                />
              ))}
            </div>
          </div>
      </div>
    </>
  );
};
