import * as React from 'react';
import TextInput from './TextInput';


interface ILabeledTextInputProps {
    disabled?: boolean
    error?: string
    help?: string
    inputStyle?: React.CSSProperties
    label: string
    leftAccessory?: React.ReactElement
    onInput?: (value: string) => void
    onBlur?: () => void
    placeholder?: string
    rightAccessory?: React.ReactElement
    size?: string
    style?: React.CSSProperties
    type?: string
    value: string
    id: string
}


const LabeledTextInput = React.forwardRef((props: ILabeledTextInputProps, ref: React.ForwardedRef<HTMLInputElement>): JSX.Element => {
    return (
        <label className="s-labeled-text-input" style={props.style}>
            <span>{props.label}</span>
            <TextInput
                ref={ref}
                id={props.id}
                disabled={props.disabled}
                error={props.error}
                leftAccessory={props.leftAccessory}
                onInput={props.onInput}
                onBlur={props.onBlur}
                placeholder={props.placeholder}
                rightAccessory={props.rightAccessory}
                size={props.size}
                style={props.inputStyle}
                type={props.type}
                value={props.value} />

            {!props.error && props.help && <span className="s-labeled-text-input-help">{props.help}</span>}
        </label>
    );
});

LabeledTextInput.displayName = 'LabeledTextInput';

export default LabeledTextInput;
