import * as React from 'react';
import classnames from 'classnames';


interface ITextAreaProps {
    error?: string
    disabled?: boolean
    leftAccessory?: React.ReactElement
    onEnter?: () => void
    onInput?: (value: string) => void
    placeholder?: string
    rightAccessory?: React.ReactElement
    rows?: number
    size?: string
    style?: React.CSSProperties
    value: string
    id: string
}


const TextArea = React.forwardRef((props: ITextAreaProps, ref: React.ForwardedRef<HTMLDivElement>): JSX.Element => {
    const [focused, setFocused] = React.useState<boolean>(false);
    const [notifyTimer, setNotifyTimer] = React.useState<number>(0);
    const [value, setValue] = React.useState<string>(props.value);
    const classes = classnames('s-text-area s-text-area-' + (props.size || 'md'), {
        's-text-area-disabled': props.disabled,
        's-text-area-focused': focused,
        's-text-area-error': props.error,
    });
    const onValueChange = (e: React.SyntheticEvent<HTMLTextAreaElement>): void => {
        const v = e.currentTarget.value;

        window.clearTimeout(notifyTimer);

        setValue(v);

        if (['Enter', 'NumpadEnter'].includes((e as unknown as { code: string }).code)) {
            props.onInput?.(v);
            props.onEnter?.();
        }
        else {
            setNotifyTimer(window.setTimeout(() => props.onInput?.(v), 75));
        }
    };
    const onBezelClick = (e: React.MouseEvent) => {
        // Treat clicks on bezel (non-input element occupied area) like a focus click to
        // the input

        if (!props.disabled && e.currentTarget.classList.contains('s-text-area')) {
            e.currentTarget.querySelector('textarea').focus();
        }
    };

    React.useEffect(() => {
        if (props.value !== value) {
            setValue(props.value);
        }
    }, [props.value]);

    return (
        <div className={classes} onClick={onBezelClick} ref={ref} style={props.style}>
            {props.leftAccessory}
            <textarea
                id={props.id}
                autoComplete="off"
                disabled={props.disabled}
                onBlur={() => setFocused(false)}
                onChange={onValueChange}
                onFocus={() => setFocused(true)}
                onKeyUp={onValueChange}
                onPaste={onValueChange}
                placeholder={props.placeholder}
                rows={props.rows || 6}
                role="textbox"
                value={value} />
            {props.rightAccessory}
        </div>
    );
});

TextArea.displayName = 'TextArea';

export default TextArea;
