import * as React from 'react';
import { AppContext, ProjectReportChanged } from '@/context';
import { apiPost, t } from '@/utils';
import BaseModalDialog from './BaseModalDialog';
import Button from '../Button';
import HorizontalStack from '../HorizontalStack';
import LabeledTextInput from '../LabeledTextInput';


interface ITargetModalProps {
    onClose: () => void
    project: IProject
    report: IAssessmentReport
}

const validatePercentage = (value: string) => {
    if (!/^([1-9]\d?|100)$/.test(value) || parseFloat(value) < 0 || parseFloat(value) > 100) {
        return t('error.percentage');
    }
    return null;
};

const validateYear = (value: string) => {
    if (!/^2\d{3}$/.test(value) || parseInt(value) < 2022 || parseInt(value) > 2299) {
        return t('error.year');
    }
    return null;
};


export default function TargetsModalDialog(props: ITargetModalProps): JSX.Element {
    const { dispatch, state } = React.useContext(AppContext);
    const [isSaving, setIsSaving] = React.useState<boolean>(false);
    const [target, setTarget] = React.useState({
        percentage: '' + (props.report.summary.target.percentage * 100),
        year: '' + (props.report.summary.target.year || ''),
    });
    const yearRef = React.useRef<HTMLInputElement>(null);
    const percentageRef = React.useRef<HTMLInputElement>(null);
    const [errors, setErrors] = React.useState<Record<string, string>>({});

    const onSaveClick = () => {
        if (isSaving) {
            return;
        }

        if (!validateYear(target.year) && !validatePercentage(target.percentage)) {
            const path = `projects/${props.project.slug}/reports/${props.report.id}/target`;
            const payload = {
                percentage: parseFloat(target.percentage) / 100,
                year: parseInt(target.year),
            };

            setIsSaving(true);
            apiPost(state.config.team.slug, path, payload).then(reply => {
                if (reply?.ok) {
                    dispatch({ type: ProjectReportChanged, project: props.project, report: {
                        ...props.report,
                        summary: { ...props.report.summary, target: payload },
                    } });

                    props.onClose();
                }
            }).finally(() => setIsSaving(false));
        }
        else {
            validateFields();
        }
    };

    const validateFields = () => {
        const yearError = validateYear(target.year);
        const percentageError = validatePercentage(target.percentage);
        setErrors({
            year: yearError,
            percentage: percentageError,
        });
        if (yearError) {
            yearRef.current.focus();
        }
        else if (percentageError) {
            percentageRef.current.focus();
        }
    };

    return (
        <BaseModalDialog title={t('ui.target')} onClose={props.onClose}>
            <p style={{ marginTop: 0 }}>Set target to track the progress.</p>

            <form>
                <HorizontalStack style={{ marginTop: '1.5rem' }}>
                    <LabeledTextInput
                        ref={yearRef}
                        id="target_year"
                        style={{ height: '90px' }}
                        label={t('ui.target_year')}
                        onInput={year => setTarget({ ...target, year })}
                        onBlur={() => setErrors({ ...errors, year: validateYear(target.year) })}
                        value={target.year}
                        error={errors.year} />
                    <LabeledTextInput
                        ref={percentageRef}
                        id="target_completion"
                        style={{ height: '90px' }}
                        label={t('ui.completion_percentage')}
                        onInput={percentage => setTarget({ ...target, percentage })}
                        onBlur={() => setErrors({ ...errors, percentage: validatePercentage(target.percentage) })}
                        value={target.percentage}
                        error={errors.percentage} />
                </HorizontalStack>

                <HorizontalStack horizontalAlign="flex-end" spacing="condensed" style={{ marginTop: '.7rem' }}>
                    <Button disabled={isSaving} onClick={props.onClose}>{t('actions.cancel')}</Button>
                    <Button disabled={isSaving} onClick={onSaveClick} variant="primary">{t('actions.save')}</Button>
                </HorizontalStack>
            </form>
        </BaseModalDialog>
    );
}
