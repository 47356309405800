import React from "react";
import { StyleSheet } from "@/utils/style";
import { Icon } from "@/lib";
import { mdiClose } from '@mdi/js';
import { DropdownOption, DropdownProps, SelectedProps } from "@/types/sleap";

export default function Dropdown({
   options, handler, label, selected, value, multiple, labelStyle, dropdownStyle, listDropdownStyle, flat, placeholder, selectedRight,
   }: DropdownProps): JSX.Element {

    const [inputValue, setInputValue] = React.useState('');
    const [isOpen, setIsOpen] = React.useState(false);
    const dropdownRef = React.useRef<HTMLUListElement>(null);
    const selectedContainer = React.useRef(null);

    React.useEffect(() => {

        if (selected.length > 0 && options.length > 0 && !multiple) {
            setInputValue(options.find(o => o.id === selected[selected.length - 1])?.label);
        }
    }, [selected, options]);

    const filteredOptions = options.filter((option) =>
        inputValue ? option.label.toLowerCase().includes(inputValue.toLowerCase()) : option,
    );

    const selectedOptions = multiple ? options.filter(o => selected.includes(o.id)) : [];

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
        // emptying input field should only reset selected for non multiple dropdowns
        if (!multiple && event.target.value.length < 1) {

            handler([], value);
        }
    };

    const handleOptionSelect = (selectedOption: DropdownOption) => {

        if (!multiple) {

          setInputValue(selectedOption.label);
          handler([selectedOption.id], value);
        } else {

          handler([...selected, selectedOption.id], value);
        }
        setIsOpen(false);
    };

    const handleDropdownBlur = (event: React.FocusEvent<HTMLUListElement>) => {
        // Check if the blur event was triggered by clicking outside the dropdown
        if (!event.currentTarget.contains(event.relatedTarget as Node)) {
            setIsOpen(false);
        }
    };

    const handleDropdownMouseDown = () => setIsOpen(true);

    const getMinMarginOfDropdown = () => {

        if (flat) {
          return 0;
        }
        const dropdownHeight = Math.min(filteredOptions.length, 3) * 30 + 30;
        const selectedContainerHeight = selectedContainer.current?.offsetHeight ?? 0;

        const difference = dropdownHeight - selectedContainerHeight;
        return Math.max(difference, 0);
    };

    const containerStyle: React.CSSProperties = {
        position: "relative",
        flex: 1,
        marginBottom: isOpen ? `${getMinMarginOfDropdown()}px` : "0",
        ...(selectedRight && {display: 'flex', flexDirection: 'row', gap: 10}),
    };

    const handleOptionDelete = (selectedDelete: number) => {

        handler(selected.filter(i => i !== selectedDelete), value);
    };

    return (
        <div style={containerStyle}>
          {label && (<><span style={{fontSize: 12, ...(labelStyle && labelStyle)}}>{label}</span><br /></>)}
            <div style={{ display: 'flex', flexDirection: 'row', position: 'relative', ...(selectedRight && {alignSelf: 'center'}) }}>
                <input
                  style={dropdownStyle ? dropdownStyle : null}
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  onFocus={() => setIsOpen(true)}
                  onBlur={() => setIsOpen(false)}
                  className={'dropdown-input'}
                  placeholder={placeholder}
                  autoComplete="off"
                  role="presentation"
                />
                {isOpen && filteredOptions.length > 0 && (
                    <ul
                        style={listDropdownStyle ? listDropdownStyle : null}
                        className={'dropdown-list'}
                        ref={dropdownRef}
                        onBlur={handleDropdownBlur}
                        onMouseDown={handleDropdownMouseDown}
                    >
                        {filteredOptions.map((option) => (
                            <li key={option.id} onMouseDown={() => handleOptionSelect(option)}>
                                {option.label}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
            {multiple && selectedOptions.length > 0 && (<div style={styles.multipleContainer} ref={selectedContainer}>
                {selectedOptions.map((s, i) => (<Selected selected={s} deleteSelected={handleOptionDelete} key={i} />))}
            </div>)}
        </div>
    );
}

function Selected({ selected, deleteSelected }: SelectedProps) {

    return (<div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
        <Icon path={mdiClose} size={12} onClick={() => deleteSelected(selected.id)} color={'white'} style={{ marginRight: 5 }} />
        <span style={{ fontSize: 12 }}>{selected.label}</span>
    </div>);
}

const styles = StyleSheet.create({
    multipleContainer: {
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        color: 'white',
        maxHeight: 100,
        overflowY: 'scroll',
    },
});
