
import { ActionLink, HorizontalStack, SectionHeader } from '@/lib';
import * as React from 'react';
import { mdiArrowLeft } from '@mdi/js';
import RecommendationMeasureGroup from './MeasureGroup';
import { t } from '@/utils';
import { useProject } from '@/context';
import { useParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';

interface ISingleAction {
    site: INewSiteDetailed
}

export default function SingleAction(props: ISingleAction): JSX.Element {
    const project = useProject();
    const report = project.reports[project.latest_report_id] as IAssessmentReport;
    const subIndustryId = decodeURI(useParams().subIndustryId);
    const clusterId = decodeURI(useParams().clusterId);

    const [singleActionPlan, setSingleActionPlan] = React.useState([]);
    React.useEffect(() => {
        const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
        fetch(`${process.env.KUYUA_APP_BACKEND_URL}/action-plan?sub_industry_id=${subIndustryId}&cluster_id=${clusterId}`, {
            headers: {
                'Authorization': `Bearer ${encryptedToken}`
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log('single action result ====>>> ', result)
                    setSingleActionPlan(result);
                },
                (error) => {
                    console.log('error ====>>> ', error)
                }
            )
    }, []);
    

    return (
        <div>
            <HorizontalStack style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                <ActionLink
                    onClick={() => history.back()}
                    icon={mdiArrowLeft}
                    iconColor="#4973B6"
                >
                    {t('actions.back')}
                </ActionLink>
            </HorizontalStack>

            
            {singleActionPlan.length > 0 &&  <RecommendationMeasureGroup
                key={singleActionPlan[0].cluster.id}
                label={singleActionPlan[0]}
                actionPlan={singleActionPlan[0]} 
                project={project}
                report={report}
                site={props.site}
            />}
           
            <div>
                <SectionHeader level={5} text={t('ipbes.action_drivers_title')} bordered={false} />
                { singleActionPlan.length > 0 && singleActionPlan[0].cluster.ipbes.map((item: any, index: number) => {
                    return (
                        <p key={index}>{item?.name}</p>
                    );
                })}
            </div>
        </div>
);
}
