import * as React from 'react';
import { mdiAlertCircleOutline } from '@mdi/js';
import Icon from './Icon';


interface IErrorPanelProps {
    icon?: string
    style?: React.CSSProperties
    title: string
    text?: string
}


export default function ErrorPanel(props: IErrorPanelProps): JSX.Element {
    const { icon, style, text, title } = props;

    return (
        <div className="s-error-panel" style={style}>
            <Icon color="#666" path={icon || mdiAlertCircleOutline} size={64} />
            <span>{title}</span>
            <span>{text}</span>
        </div>
    );
}
