import * as React from 'react';
import classnames from 'classnames';
import { mdiCheckBold } from '@mdi/js';
import Icon from './Icon';


interface ICheckboxProps {
    children?: React.ReactNode
    checked: boolean
    disabled?: boolean
    label: string | React.ReactElement
    onToggle?: (checked: boolean) => void
    style?: React.CSSProperties
}


export default function Checkbox(props: ICheckboxProps): JSX.Element {
    const onClick = (e: React.MouseEvent<HTMLSpanElement>) => {
        const t = e.currentTarget;

        e.preventDefault();

        if (!props.disabled && t.matches('.s-checkbox-box, .s-checkbox-label')) {
            props.onToggle?.(!props.checked);
        }
    };
    const onKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>): void => {
        if (!props.disabled && e.code === 'Space') {
            props.onToggle?.(!props.checked);
        }
    };

    return (
        <div className={classnames('s-checkbox', { 's-checkbox-disabled': props.disabled })} style={props.style}>
            <span
                aria-checked={props.checked ? 'true' : 'false'}
                className={classnames('s-checkbox-box', { 's-checkbox-box-checked': props.checked })}
                onClick={onClick}
                onKeyDown={onKeyDown}
                role="checkbox"
                tabIndex={props.disabled ? -1 : 0}>
                {props.checked && <Icon color="#FFF" path={mdiCheckBold} size={12} style={{ paddingLeft: '1px', paddingBottom: '1px' }} />}
            </span>
            <span className="s-checkbox-label" onClick={onClick}>{props.label}{props.children}</span>
        </div>
    );
}
