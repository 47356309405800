import * as React from 'react';
import { mdiArrowRightThin, mdiWindowClose } from '@mdi/js';
import { AppContext, useTeam, useTheme } from '@/context';
import { Icon, ErrorPanel, Dropdown, ActionLink, LoadingAnimation } from '@/lib';
import { BaseModalDialog } from '@/lib/modals';
import { apiGet, t } from '@/utils';
import './index.scss';
import { Link } from 'react-router-dom';
import CryptoJS from "crypto-js";

const MitigationOptions = [
    { id: 'avoid', label: 'Avoid' },
    { id: 'minimize', label: 'Minimize' },
    { id: 'restore', label: 'Restore' },
    // { id: 'offset', label: 'Offset' },
];

const capitalize = (string: string) => {
    if (!string) {
        return string;
    }
    return string[0].toUpperCase() + string.substring(1);
};

const createFilterLabel = (activeFilters: string[], allFilters: {id: string, label: string}[]) => {
    if (activeFilters.length === 0) {
        return t('ui.all');
    }
    return allFilters.filter(o => !!activeFilters.find(f => f === o.id)).map(o => o.label).join(', ');
};

interface ISiteActionsProps {
    project: IProject
    report: IAssessmentReport
    site: INewSiteDetailed
}

const toggleFilter = (id: string, filters: string[], setter: React.Dispatch<React.SetStateAction<string[]>>) => {
    if (filters.find(item => item === id)) {
        setter(filters.filter(filter => filter !== id));
    }
    else {
        setter(filters.concat(id));
    }
};

export default function SiteActionsTab(props: ISiteActionsProps): JSX.Element {
    const { dispatch, state } = React.useContext(AppContext);
    const team = useTeam();
    const theme = useTheme();
    const [ipbesFilters, setIpbesFilters] = React.useState<string[]>([]);
    const [mitigationFilters, setMitigationFilters] = React.useState<string[]>([]);
    const [showPopup, setShowPopup] = React.useState<('ipbes' | 'mitigation' | null)>(undefined);
    const [ipbesOptions, setIpbesOptions] = React.useState<{ id: string, label: string }[]>(undefined);
    const [stats, setStats] = React.useState<ISiteStatistics[]>(undefined);
    const [measureChanged, setMeasureChanged] = React.useState<boolean>(false);
    const [actionPlans, setActionPlans] = React.useState([]);
    const [filteredActionPlans, setFilteredActionPlans] = React.useState([]);
    
    // const subIndustryId = props.site.economic_activities[0].division_id;
    const subIndustryIds = props.site.economic_activities.map((item: any) => item.division_id);
    const uniqueStringSubIndustryIds = Array.from(new Set(subIndustryIds)).toString();
    const geoServerSubIndustryIds = props.site.site_id.split('_').slice(1).join(',')
    //check if we have subIndustryIds from the site_id otherwise use the division_id from the old backend
    const subIndustryIdsToFetch = geoServerSubIndustryIds ? geoServerSubIndustryIds : uniqueStringSubIndustryIds;

    console.log('uniqueSubIndustryIds ====>>> ', uniqueStringSubIndustryIds)

    React.useEffect(() => {
        const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
        //TODO: rename sub_industry_id to be sub_industry_ids after handle it in the backend
        fetch(`${process.env.KUYUA_APP_BACKEND_URL}/action-plan?sub_industry_id=${subIndustryIdsToFetch}`, {
            headers: {
                'Authorization': `Bearer ${encryptedToken}`
            }
        })
        .then(res => res.json())
        .then(
            (result) => {
                console.log('result ====>>> ', result)
                setActionPlans(result);
            },
            (error) => {
                console.log('error ====>>> ', error)
            }
        )
    }, []);

    React.useEffect(() => {
        setFilteredActionPlans(actionPlans);
    }, [actionPlans]);

    React.useEffect(() => {
        if (mitigationFilters.length > 0) {
            setIpbesFilters([]);
        }
    }, [mitigationFilters]);
    
    React.useEffect(() => {
        if (ipbesFilters.length > 0) {
            setMitigationFilters([]);
        }
    }, [ipbesFilters]);
    
    const updateFilteredActionPlans = React.useCallback(() => {
        if (mitigationFilters.length > 0) {
            const filtered = actionPlans.filter(plan => mitigationFilters.includes(plan.mitigation.description.toLowerCase()));
            setFilteredActionPlans(filtered);
        } else if (ipbesFilters.length > 0) {
            const filtered = actionPlans.filter(plan => plan.cluster.ipbes.some((ipbes: any) => ipbesFilters.includes(ipbes.code)));
            setFilteredActionPlans(filtered);
        } else {
            setFilteredActionPlans(actionPlans)
        }
    }, [mitigationFilters, ipbesFilters, actionPlans]);
    
    React.useEffect(() => {
        updateFilteredActionPlans();
    }, [updateFilteredActionPlans]);


    React.useEffect(() => {
        const options = actionPlans.map((item: any) => {
            return item.cluster.ipbes.map((ipbes: any) => {
                return {
                    id: ipbes.code,
                    label: ipbes.name,
                    // group: ipbes.code
                };
            });
        });
        if (options.length > 0) {
            //make unique options by id
            const uniqueOptions = options.flat().reduce((acc: any, current: any) => {
                const x = acc.find((item: any) => item.id === current.id);
                if (!x) {
                    return [...acc, current];
                } else {
                    return acc;
                }
            }, []);
            setIpbesOptions(uniqueOptions);
        }
    }, [actionPlans]);


    React.useEffect(() => {
        if (!stats || measureChanged) {
            setMeasureChanged(false);
            const url = `projects/${props.project.slug}/reports/${props.report.id}/sites/${props.site.id}/statistics`;
            apiGet<ISiteStatistics[]>(team.slug, url).then(reply => {
                if (reply.ok && reply.data && reply.data.length > 0) {
                    const data = reply.data.map(data => ({ ...data, date: new Date(data.date) }));
                    setStats(data);
                }
            });
        }
    }, [measureChanged]);

    const ipbesFiltersLabel = React.useMemo(() => createFilterLabel(ipbesFilters, ipbesOptions), [ipbesFilters]);
    const mitigationFiltersLabel = React.useMemo(() => createFilterLabel(mitigationFilters, MitigationOptions), [mitigationFilters]);
    const headingHeight = React.useMemo(() => {
        const cardTitles = Array.from(document.querySelectorAll('.s-action-action-card-title'));
        const maxHeight = Math.max(...cardTitles.map(item => item.getBoundingClientRect().height));

        return maxHeight;
    }, [actionPlans, state.layoutSize]);
    const getHeadingFontSize = (text: string) => {
        const length = text.length;
        return (length < 30 ? '1rem' : theme.smallFontSize);
    };
    console.log('action Plan ====>>> ', actionPlans)

    return (
        <div>
            <div>
                <div className="s-actions-filters-container">
                    <div className="s-action-filter">
                        <h2 className="s-action-filter-heading">
                            {t('action_plans.ipbes_filters_heading')}
                            <ActionLink onClick={() => setShowPopup('ipbes')} style={{ fontSize: '.85rem' }}>
                                {t('action_plans.more_info')}
                            </ActionLink>
                        </h2>
                        { ipbesOptions ?
                            <Dropdown
                                label="ALL"
                                items={ipbesOptions}
                                selectedIds={ipbesOptions.filter(o => ipbesFilters.includes(o.id)).map(o => o.id)}
                                onItemClick={(id) => toggleFilter(id, ipbesFilters, setIpbesFilters)}
                                selectedIcon={mdiWindowClose}
                            />
                            :
                            <div style={{ textAlign: 'center' }}>
                                <LoadingAnimation />
                            </div>
                        }
                    </div>

                    <div className="s-action-filter">
                        <h2 className="s-action-filter-heading">
                            {t('action_plans.mitigation_filters_heading')}
                            <ActionLink onClick={() => setShowPopup('mitigation')} style={{ fontSize: '.85rem' }}>
                                {t('action_plans.more_info')}
                            </ActionLink>
                        </h2>
                        <Dropdown
                            label={mitigationFiltersLabel}
                            items={MitigationOptions}
                            selectedIds={mitigationFilters}
                            onItemClick={(id) => toggleFilter(id, mitigationFilters, setMitigationFilters)}
                            selectedIcon={mdiWindowClose}
                        />
                    </div>
                </div>

                {filteredActionPlans ? <div className="s-action-action-cards-container">
                    {filteredActionPlans.map((item) =>
                        <>
                        <Link className="s-action-action-card"
                            key={item.cluster.name}
                            to={`./ ${subIndustryIdsToFetch}/${item.cluster.id}`}
                        >
                            <div
                                className={`s-action-action-card-title s-action-action-card-title-${item?.mitigation.description.toLowerCase()}`}
                                style={{ height: headingHeight, fontSize: getHeadingFontSize(item.cluster.name) }}
                            >
                                <span className="s-action-action-card-title-text">{item.cluster.name}</span>
                                <span className="s-label">{item?.mitigation.description}</span>
                            </div>
                            <div className="s-action-action-card-footer">
                                <span>
                                    {t('action_plans.tasks_completed')} {item.recommendations?.filter((item: any) => item.progress !== 0).length + '/' + item.recommendations?.length}
                                </span>
                                <Icon color="#7796C9" path={mdiArrowRightThin} size={32} />
                            </div>
                        </Link>
                        </>
                    )}
                </div> : <LoadingAnimation />}
            </div>

            {!actionPlans && <ErrorPanel title="No items" style={{ marginTop: '3rem' }} />}

            {showPopup && <BaseModalDialog title={showPopup === 'mitigation' ? t('mitigation.title') : t('ipbes.title')} onClose={() => setShowPopup(null)}>
                <div>
                    {showPopup === 'mitigation' ? <div>{t('mitigation.info')}<img src="/-/app/mitigation-hierarchy.png" width="100%" /></div> : t('ipbes.info')}
                </div>
            </BaseModalDialog>}
        </div>
    );
}
