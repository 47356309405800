import { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';

const ratingMap:any = {
    5: 'VH',
    4: 'H',
    3: 'M',
    2: 'L',
    1: 'VL'
};

function countUniqueItemsByName(dependenciesOrImpactsArray:any, nameKey:any, filterZeroRating = false) {
    const allCounts:any = {};
    const filteredCounts:any = {};

    dependenciesOrImpactsArray.forEach((item:any) => {
        const name = item[nameKey];
        const rating = item.rating;

        if (!allCounts[name]) {
            allCounts[name] = 0;
        }
        allCounts[name]++;

        if (rating >= 1) {
            if (!filteredCounts[name]) {
                filteredCounts[name] = 0;
            }
            filteredCounts[name]++;
        }
    });

    const allCountsArray = Object.keys(allCounts).map(name => ({
        [nameKey]: name,
        count: allCounts[name]
    }));

    const filteredCountsArray = Object.keys(filteredCounts).map(name => ({
        [nameKey]: name,
        count: filteredCounts[name]
    }));

    return filterZeroRating ? filteredCountsArray.length : allCountsArray.length;
}

function processArray(dependenciesOrImpactsArray:any, nameProp:any) {
    const uniqueItems:any = {};

    dependenciesOrImpactsArray.forEach((item:any) => {
        if (item.rating > 0) {
            const itemName = item[nameProp];
            const { rating } = item;
            if (!uniqueItems[itemName] || uniqueItems[itemName].rating < rating) {
                uniqueItems[itemName] = { [nameProp]: itemName, rating };
            }
        }
    });

    const groupedByRating:any = {};
    Object.values(uniqueItems).forEach((item:any) => {
        const ratingText:any = ratingMap[item.rating];
        if (!groupedByRating[ratingText]) {
            groupedByRating[ratingText] = [];
        }
        groupedByRating[ratingText].push(item[nameProp]);
    });

    const reverseRatingMap = Object.fromEntries(
        Object.entries(ratingMap).map(([key, value]) => [value, key])
    );

    const sortedKeys = Object.keys(groupedByRating).sort((a, b) => reverseRatingMap[b] - reverseRatingMap[a]);

    const sortedData:any = {};
    for (const key of sortedKeys) {
        sortedData[key] = groupedByRating[key];
    }

    return Object.keys(sortedData).map(rating => ({
        rating,
        values: sortedData[rating]
    }));
}

export const useMaterialityAssessmentGeoServer = (economicActivitiesIds:any, site:any) => {
    console.log('Economic activities ids from custom hook =======>>>>> ', economicActivitiesIds);
    const [totalUniqueDependencies, setTotalUniqueDependencies] = useState(0);
    const [totalUniqueDependenciesWithRating, setTotalUniqueDependenciesWithRating] = useState(0);
    const [restructuredDependenciesGeoServer, setRestructuredDependenciesGeoServer] = useState([]);
    const [totalUniqueImpacts, setTotalUniqueImpacts] = useState(0);
    const [totalUniqueImpactsWithRating, setTotalUniqueImpactsWithRating] = useState(0);
    const [restructuredImpactsGeoServer, setRestructuredImpactsGeoServer] = useState([]);

    const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
    const header = {
        headers: {
            'Authorization': `Bearer ${encryptedToken}`
        }
    };

    const fetchDependencies = async () => {
        const url = `${process.env.KUYUA_APP_BACKEND_URL}/dependencies?economic_activities_ids=${economicActivitiesIds}`;
        try {
            const response = await fetch(url, header);
            const data = await response.json();
            if (data.dependencies && data.dependencies.length > 0) {
                console.log('Dependencies from custom hook =======>>>>> ', data.dependencies);
                setTotalUniqueDependencies(countUniqueItemsByName(data.dependencies, 'd_name', false));
                setTotalUniqueDependenciesWithRating(countUniqueItemsByName(data.dependencies, 'd_name', true));
                const restructuredDependencies = processArray(data.dependencies, 'd_name');
                setRestructuredDependenciesGeoServer(restructuredDependencies);
            } else {
                console.log('No dependencies found for this site');
            }
        } catch (error) {
            console.error('Error fetching dependencies:', error);
        }
    };

    const fetchImpacts = async () => {
        const url = `${process.env.KUYUA_APP_BACKEND_URL}/impacts?economic_activities_ids=${economicActivitiesIds}`;
        try {
            const response = await fetch(url, header);
            const data = await response.json();
            if (data.impacts && data.impacts.length > 0) {
                setTotalUniqueImpacts(countUniqueItemsByName(data.impacts, 'i_name', false));
                setTotalUniqueImpactsWithRating(countUniqueItemsByName(data.impacts, 'i_name', true));
                const restructuredImpacts = processArray(data.impacts, 'i_name');
                setRestructuredImpactsGeoServer(restructuredImpacts);
            } else {
                console.log('No impacts found for this site');
            }
        } catch (error) {
            console.error('Error fetching impacts:', error);
        }
    };

    useEffect(() => {
            fetchImpacts();
            fetchDependencies();
    }, [economicActivitiesIds, site]);

    return {
        totalUniqueDependencies,
        totalUniqueDependenciesWithRating,
        restructuredDependenciesGeoServer,
        totalUniqueImpacts,
        totalUniqueImpactsWithRating,
        restructuredImpactsGeoServer
    };
};
