import React from "react";
import Icon from "../../../lib/Icon";
import {mdiUpload} from "@mdi/js";
import {colors, StyleSheet} from "@/utils/style";
export const ExportButton: React.FC = ({url}) => {


  const exportSites = React.useCallback(() => {
    document.location = url;
  }, []);

  return (
    <div onClick={() => exportSites()} style={styles.export}>
      <Icon path={mdiUpload} color={colors.white} size={16} />
      <span>EXPORT LIST</span>
    </div>
  );
}

const styles = StyleSheet.create({
  export: {
    height: '100%',
    borderRadius: 20,
    backgroundColor: 'transparent',
    opacity: 1,
    padding: '5px 10px',
    color: colors.white,
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    border: `1px solid ${colors.white}`,
    fontSize: 13,
  },
});
