import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext, ToastAdded, useProject, useTeam } from '@/context';
import { MasterHeader, SteppedTrack } from '@/lib';
import { updateCountries, updateIndustries } from '@/resources';
import { apiPost, apiGet, t } from '@/utils';
import { getDataForGeocoding, IBatchGeocodingTask, IGeocodingResult, needsLocationGeocoding, recreateSitesWithLocations } from './geocode-util';
import { Alpha2CountryFormat, createSites, hasOneValueWithFormat, IndustryCodeFormat, ISiteFileData, ISiteMappings, LatCommaLongFormat, LatLngImportMode } from './util';
import SiteImportUploadStep from './SiteImportUploadStep';
import SiteImportEditStep from './SiteImportEditStep';
import SiteImportMappingStep from './SiteImportMappingStep';
import SitemImportWaitCompletionStep from './SiteWaitCompletionStep';
import './index.scss';


type SiteImportStep = 'upload' | 'associate' | 'fix' | 'import';

const maxResultFetches = 20;

const findColumn = (columns: string[], names: string[]): string => {
    for (let i = names.length - 1; i >= 0; i--) {
        if (names[i].indexOf(' ') !== -1) {
            names.push(names[i].replace(/ /g, '_'));
        }
    }

    return columns.find(column => names.includes(column.toLowerCase().trim()));
};

const findColumnWithFormat = (excelData: ISiteFileData, names: string[], regExp: RegExp): string => {
    for (let i = names.length - 1; i >= 0; i -= 1) {
        if (names[i].indexOf(' ') !== -1) {
            names.push(names[i].replace(/ /g, '_'));
        }
    }

    const columns = Object.keys(excelData);

    for (let i = 0; i < names.length; i += 1) {
        const column = columns.find(column => column.toLowerCase().trim() === names[i]);

        if (column && hasOneValueWithFormat(regExp, Object.values(excelData[column]))) {
            return column;
        }
    }

    return undefined;
};


export default function SiteImport(): JSX.Element {
    const { dispatch, state } = React.useContext(AppContext);
    const team = useTeam();
    const project = useProject();
    const navigate = useNavigate();

    const [industries, setIndustries] = React.useState<{ name: string, code: string }[]>([]);
    const [countries, setCountries] = React.useState<{ name: string, code: string }[]>([]);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [step, setStep] = React.useState<SiteImportStep>('upload');

    const [file, setFile] = React.useState<File | undefined>(undefined);
    const [fileData, setFileData] = React.useState<Record<string, string> | undefined>(undefined);

    const [latLngImportMode, setLatLngImportMode] = React.useState<LatLngImportMode>('combined');
    const [mappings, setMappings] = React.useState<ISiteMappings>({} as ISiteMappings);

    const [sites, setSites] = React.useState<IAddToSitesData[]>(undefined);
    const [importJobId, setImportJobId] = React.useState<string>(undefined);

    const [pendingLocationJobs, setPendingLocationJobs] = React.useState<IBatchGeocodingTask[]>([]);
    const [fetchAttempt, setFetchAttempt] = React.useState<number>(undefined);

    if (!project.experimental_features.includes('site-import')) {
        navigate(`/${team.slug}/${project.slug}/explore`);
    }

    React.useEffect(() => {
        updateIndustries(state, dispatch).then(industries => setIndustries(Object
            .values(industries)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(c => ({ code: c.code, name: c.name }))));

        updateCountries(state, dispatch).then(countries => setCountries(Object.values(countries)));
    }, []);

    React.useEffect(() => {
        setLatLngImportMode('combined');

        if (!fileData) {
            setMappings({} as ISiteMappings);
        }
        else {
            const keys = Object.keys(fileData);

            setMappings(({
                city: findColumn(keys, ['city']),
                country: findColumnWithFormat(fileData, ['country', 'country code'], Alpha2CountryFormat),
                industry: findColumnWithFormat(fileData, ['industry'], IndustryCodeFormat),
                location: findColumnWithFormat(fileData, ['coordinates', 'location'], LatCommaLongFormat),
                name: findColumn(keys, ['name']),
                province: findColumn(keys, ['province']),
                street: findColumn(keys, ['street', 'street address', 'street name']),
                zip: findColumn(keys, ['post code', 'postal code', 'zip', 'zip code']),
            } as ISiteMappings));
        }
    }, [fileData]);

    const onMappingsSet = (mappingsChanged: boolean) => {
        if (!mappingsChanged && sites) {
            setStep('fix');
        }
        else {
            const newSites = createSites(fileData, mappings, industries, countries);
            setSites(newSites);

            if (needsLocationGeocoding(newSites, mappings)) {
                apiPost<{ tasks: IBatchGeocodingTask[] }>(team.slug, `projects/${project.slug}/geocode`, getDataForGeocoding(newSites))
                    .then(reply => {
                        if (reply.ok) {
                            setPendingLocationJobs(reply.data.tasks);
                            setFetchAttempt(1);
                        }
                        else {
                            dispatch({ type: ToastAdded, toast: { kind: 'error', text: t('ui.sites_import.failed_to_fetch_locations') } });
                        }
                    })
                    .finally(() => {
                        setStep('fix');
                    });
            }
            else {
                setStep('fix');
            }
        }
    };

    const uploadSites = () => {
        setLoading(true);

        apiPost<{ job_id: string }>(team.slug, `projects/${project.slug}/reports/${project.latest_report_id}/sites/create-multi`, sites).then(reply => {
            if (reply.ok) {
                setImportJobId(reply.data.job_id);
                setStep('import');
            }
            else {
                dispatch({ type: ToastAdded, toast: { kind: 'error', text: t('ui.sites_import.failed_to_add_sites') } });
            }
        }).finally(() => setLoading(false));
    };

    const pollForLocations = () => {
        if (!pendingLocationJobs) {
            return;
        }
        else if (pendingLocationJobs.length === 0) {
            setFetchAttempt(maxResultFetches);
        }
        else {
            const query = `?id=${pendingLocationJobs.map(job => job.task_id).join('&id=')}`;

            apiGet<{id: string, geocodingResult: IGeocodingResult[]}[]>(team.slug, `projects/${project.slug}/geocode${query}`).then(reply => {
                if (reply.ok) {
                    const completedTasks = reply.data.filter(task => task.geocodingResult);
                    setSites(recreateSitesWithLocations(sites, completedTasks, pendingLocationJobs));

                    setPendingLocationJobs(pendingLocationJobs.filter(pending => !completedTasks.find(task => task.id === pending.task_id)));
                    setFetchAttempt(fetchAttempt + 1);
                }
                else {
                    dispatch({ type: ToastAdded, toast: { kind: 'error', text: t('ui.sites_import.failed_to_fetch_locations') } });
                    setFetchAttempt(maxResultFetches);
                }
            });
        }
    };

    const onFileChange = (fileData: Record<string, string>) => {
        setSites(undefined);
        setFileData(fileData);
    };

    React.useEffect(() => {
        if (fetchAttempt && fetchAttempt < maxResultFetches) {
            const id = window.setTimeout(() => pollForLocations(), 2000);

            return () => window.clearTimeout(id);
        }
        else if (fetchAttempt >= maxResultFetches) {
            setPendingLocationJobs([]);
        }
      }, [fetchAttempt]);

    return (
        <div className="s-site-import">
            <MasterHeader style={{ marginBottom: '.75rem' }} text={t('ui.sites_import.heading')} />

            <div style={{ maxWidth: '60rem' }}>
                {t('ui.sites_import.info')}
            </div>

            <SteppedTrack steps={[
                { active: step === 'upload', label: t('actions.upload') },
                { active: step === 'associate', label: t('actions.analyze') },
                { active: step === 'fix', label: t('actions.fix') },
                { active: step === 'import', label: t('actions.import') },
            ]} style={{ padding: '3rem 4rem' }} />

            {step === 'upload' &&
                <SiteImportUploadStep
                    onNext={() => setStep('associate')}
                    setFile={setFile}
                    setFileData={onFileChange}
                />
            }

            {step === 'associate' && !loading &&
                <SiteImportMappingStep
                    fileData={fileData}
                    fileName={file.name}
                    onNext={onMappingsSet}
                    mappings={mappings}
                    latLngImportMode={latLngImportMode}
                    setMappings={setMappings}
                    setLatLngImportMode={setLatLngImportMode}
                    cancel={() => setStep('upload')}
                />
            }

            {step === 'fix' &&
                <SiteImportEditStep
                    data={sites}
                    loading={loading}
                    pendingSites={pendingLocationJobs.map(job => job.sites).flat(1)}
                    updateData={setSites}
                    cancel={() => setStep('associate')}
                    submit={uploadSites}
                />
            }

            {step === 'import' &&
                <SitemImportWaitCompletionStep
                    data={sites}
                    jobId={importJobId}
                    loading={loading} />
            }
        </div>
    );
}
