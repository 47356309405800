import * as React from 'react';
import classnames from 'classnames';
import { times } from '@/utils';


interface IRatingProps {
    inactiveBallColor?: string
    onRateClick?: (value: number) => void
    range: number
    size?: 'md' | 'sm' | 'xs'
    style?: React.CSSProperties
    value: number
}


function getElementIndex(el: Element): number {
    let ix = 0;

    while (el.previousElementSibling) {
        ix += 1;
        el = el.previousElementSibling;
    }

    return ix;
}


export default function Rating(props: IRatingProps): JSX.Element {
    const interactive = !!props.onRateClick;
    const classes = classnames(`s-rating s-rating-${props.size || 'md'}`, {
        's-rating-clickable': interactive,
    });
    const onClick = (e: React.MouseEvent<HTMLSpanElement>) => {
        props.onRateClick?.(getElementIndex(e.currentTarget) + 1);
    };
    const onKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>) => {
        if (e.code === 'Enter' || e.code === 'Space') {
            props.onRateClick?.(getElementIndex(e.currentTarget) + 1);
        }
    };

    return (
        <div className={classes} style={props.style}>
            {times(props.range, (n: number) => n).map(ix => <span
                key={ix}
                className={classnames({ 's-rating-on': ix < props.value })}
                onClick={onClick}
                onKeyDown={onKeyDown}
                style={{ background: ix >= props.value && props.inactiveBallColor }}
                tabIndex={interactive ? 0 : -1}></span>)}
        </div>
    );
}
