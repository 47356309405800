import * as React from 'react';
import { AppContext, useProject, useTeam } from '@/context';
import { getSiteSpecies } from '@/resources';
import { isThreatenedSpecies, isProtectedSpecies, isKeyStoneSpecies, t } from '@/utils';
import { CopyrightNotice, ErrorPanel, FilterBar, LoadingAnimation, TableHeaderCell } from '@/lib';
import { getSortBy, SortOrder, speciesRoleSort, stringSort } from '@/utils/sort';
import { unique } from '@/utils';
import SpeciesKeyValues from './SpeciesKeyValues';
import Checkbox from "@/utils/Checkbox";


const makeProtectionLabel = (sa: ISiteSpeciesAssessment) => {
    if (!sa.protection_statuses) {
        return '\u00A0';
    }

    return Object.keys(sa.protection_statuses).sort().join(', ');
};


interface ISiteSpeciesProps {
    onClick: (species: ISpecies, what: 'info' | 'map') => void
    project: IProject
    report: IAssessmentReport
    site: ISite
}

interface ISiteSpeciesItemProps {
    assessment: ISiteSpeciesAssessment
    onClick: (what: 'info' | 'map') => void
    species: ISpecies
}

interface ISiteSpeciesTableProps {
    onClick: (species: ISpecies, what: 'info' | 'map') => void
    site: ISite
    species: IAssessedSpecies[]
    style?: React.CSSProperties
}

type SortType = 'species' | 'group' | 'status' | 'score';


export function SiteSpeciesItem(props: ISiteSpeciesItemProps): JSX.Element {
    const a = props.assessment;
    const s = props.species;
    const [rank, setRank] = React.useState<string>();

    React.useEffect(() => {
        if (props.assessment.apex_predator_rank > 0) {
            setRank(t('ui.apex_predator'));
        }
        else if (props.assessment.habitat_creator_rank > 0) {
            setRank(t('ui.habitat_creator'));
        }
        else if (props.assessment.seed_disperser_rank > 0) {
            setRank(t('ui.seed_disperser'));
        } else {
            setRank(t('ui.other'));
        }
    }, [props.assessment]);

    return (
        <tr>
            <td>
                <div>
                    <a className="s-link" onClick={() => props.onClick('map')}>{s.name}</a>
                </div>
                <div className="s-table-sublabel">{s.common_name || '—'}</div>
            </td>
            <td>
                <div style={{textAlign: "center"}}>{rank}</div>
                <div className="s-table-sublabel">&nbsp;</div>
            </td>
            <td style={{textAlign: "center"}}>
                <Checkbox checked={isProtectedSpecies(props)} readonly={true} checkHandler={() => {}}/>
            </td>
            <td style={{textAlign: "center"}}>
                <Checkbox checked={isThreatenedSpecies(props)} readonly={true} checkHandler={() => {}}/>
            </td>
       </tr>
    );
}


const SpeciesRoleDropdownItems = [
    { id: '_all', label: t('ui.all') },
    { id: 'protected', group: t('ui.regulatory'), label: t('ui.protected_species_plural') },
    { id: 'endangered', group: t('ui.regulatory'), label: t('ui.endangered_species_plural') },
    { id: 'apex_predator', group: t('ui.habitat_role'), label: t('ui.apex_predator_plural') },
    { id: 'habitat_creator', group: t('ui.habitat_role'), label: t('ui.habitat_creator_plural') },
    { id: 'seed_disperser', group: t('ui.habitat_role'), label: t('ui.seed_disperser_plural') },
    { id: 'umbrella', group: t('ui.habitat_role'), label: t('ui.umbrella_species_plural') },
];


export function SiteSpeciesTable(props: ISiteSpeciesTableProps): JSX.Element {
    const team = useTeam();
    const project = useProject();
    const [exportDisabled, setExportDisabled] = React.useState<boolean>(false);
    const [sortOrder, setSortOrder] = React.useState<SortOrder>(undefined);
    const [sortType, setSortType] = React.useState<SortType>(undefined);
    const [classId, setClassId] = React.useState<string>('_all');
    const [roleId, setRoleId] = React.useState<string>('umbrella');
    const speciesClasses = React.useMemo(() => [{ id: '_all', label: t('ui.all') }]
        .concat(unique(props.species.map(s => s.species.class)).sort().map(id => ({ id, label: id }))), [props.species]);
    const finalSpeciesList = React.useMemo(() => {
        let species = [].concat(props.species);

        if (classId !== '_all') {
            species = species.filter(s => s.species.class === classId);
        }

        if (['apex_predator', 'habitat_creator', 'seed_disperser'].includes(roleId)) {
            species = species.filter(s => s.assessment[roleId + '_rank'] > 0);
        }
        else if (roleId === 'umbrella') {
            species = species.filter(s => isKeyStoneSpecies(s));
        }
        else if (roleId === 'protected') {
            species = species.filter(s => isProtectedSpecies(s));
        }
        else if (roleId === 'threatened') {
            species = species.filter(s => isThreatenedSpecies(s));
        }

        return species.sort((a, b) => {
            if (sortType === 'species') {
                return stringSort(a.species.name, b.species.name, sortOrder);
            }
            else if (sortType === 'group') {
                return stringSort(a.species.class, b.species.class, sortOrder);
            }
            else if (sortType === 'status') {
                return stringSort(a.assessment.conservation_status, b.assessment.conservation_status, sortOrder);
            } else {
                return speciesRoleSort(a, b, sortOrder);
            }
        });
    }, [classId, props.species, roleId, sortOrder, sortType]);

    const onSort = (order: SortOrder, type: SortType) => {
        setSortType(type);
        setSortOrder(order);
    };

    const onDownloadSpecies = () => {
        setExportDisabled(true);
        window.setTimeout(() => setExportDisabled(false), 2500);

        const path = `/api/v1/teams/${team.slug}/projects/${project.slug}/reports/${project.latest_report_id}/sites/${props.site.id}/species/download`;
        const qs = [
            classId !== '_all' ? 'class=' + encodeURIComponent(classId) : '',
            roleId !== '_all' ? 'role=' + encodeURIComponent(roleId) : '',
        ].filter(p => p !== '').join('&');

        document.location = path + (qs.length > 0 ? '?' + qs : '');
    };

    return <>
        <FilterBar
            buttons={[{ disabled: exportDisabled, id: 'dl', label: t('actions.export'), variant: 'primary', icon: 'csv' }]}
            dropdowns={[
                {
                    id: 'meta',
                    label: roleId === '_all' ? t('ui.role') : SpeciesRoleDropdownItems.find(r => r.id === roleId).label,
                    items: SpeciesRoleDropdownItems,
                }, {
                    id: 'class',
                    label: classId === '_all' ? t('ui.taxonomic_group') : speciesClasses.find(c => c.id === classId).label,
                    items: speciesClasses,
                },
            ]}
            onButtonClick={() => onDownloadSpecies()}
            onDropdownItemSelect={(what, id) => {
                switch (what) {
                case 'class':
                    setClassId(id);
                    break;

                case 'meta':
                    setRoleId(id);
                    break;
                }
            }}
            style={props.style} />

        <table className="s-table s-table-fixed">
            <thead>
                <tr>
                    <TableHeaderCell
                        onClick={(arg: SortOrder) => onSort(arg, 'species')}
                        sortedBy={getSortBy(sortType, 'species', sortOrder)}
                        label={t('ui.species')}
                        width="35%"
                    />
                    <TableHeaderCell
                        onClick={(arg: SortOrder) => onSort(arg, 'group')}
                        sortedBy={getSortBy(sortType, 'group', sortOrder)}
                        label={t('ui.group')}
                        width="15%"
                    />
                    <TableHeaderCell
                        onClick={(arg: SortOrder) => onSort(arg, 'status')}
                        sortedBy={getSortBy(sortType, 'status', sortOrder)}
                        label={t('ui.conservation_status') + ' (' + t('ui.protection_list_plural') + ')'}
                        width="35%"
                    />
                </tr>
            </thead>
            <tbody>
                {finalSpeciesList.map((s: IAssessedSpecies) => <SiteSpeciesItem
                    key={s.species.id}
                    assessment={s.assessment}
                    onClick={what => props.onClick(s.species, what)}
                    species={s.species}/>)}
            </tbody>
        </table>
    </>;
}


export default function SiteSpeciesTab(props: ISiteSpeciesProps): JSX.Element {
    const { state } = React.useContext(AppContext);
    const [species, setSpecies] = React.useState<ISiteSpecies>(undefined);
    const [loading, setLoading] = React.useState<boolean>(true);

    React.useEffect(() => {
        if (props.site.species === undefined) {
            getSiteSpecies(state, props.project, props.report, props.site).then(species => {
                setSpecies(species);
                setLoading(false);
            });
        }
    }, [props.site]);

    if (loading || species.loading) {
        return <LoadingAnimation />
    }

    if (!species || !species.items || species.items.length === 0) {
        return <ErrorPanel title="No Species" style={{ marginTop: '6rem' }} />;
    }

    return <>
        <SpeciesKeyValues species={species.items} style={{ marginTop: '1.5rem' }} />

        <SiteSpeciesTable
            onClick={props.onClick}
            site={props.site}
            species={species.items}
            style={{ marginTop: '2rem' }}
         />

        <CopyrightNotice />
    </>;
}

