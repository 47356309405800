import * as React from 'react';
import * as ReactDOM from 'react-dom';


interface IPopoverProps {
    at: { x: number, y: number }
    children?: React.ReactNode
    onClose: () => void
}

interface IPopoverState {
    boundBodyClick: (e: MouseEvent) => void
    boundKeyDown: (e: KeyboardEvent) => void
    boundResize: (e: UIEvent) => void
    portal: HTMLDivElement
}


export default class Popover extends React.Component<IPopoverProps, IPopoverState> {

    constructor(props: IPopoverProps) {
        super(props);

        this.state = {
            boundBodyClick: undefined,
            boundKeyDown: undefined,
            boundResize: undefined,
            portal: document.createElement('div'),
        };
    }


    componentDidMount(): void {
        const onBodyClick = (e: MouseEvent) => this.onBodyClick(e);
        const onResize = () => this.props.onClose();
        const onKeyDown = (e: KeyboardEvent) => {
            if (e.code === 'Escape') {
                this.props.onClose();
            }
        };

        document.querySelector('#modals').appendChild(this.state.portal);

        this.setState({
            ...this.state,
            boundBodyClick: onBodyClick,
            boundKeyDown: onKeyDown,
            boundResize: onResize,
        });

        window.setTimeout(() => {
            document.body.addEventListener('click', onBodyClick);
            window.addEventListener('keydown', onKeyDown);
            window.addEventListener('resize', onResize);
        }, 10);
    }


    componentWillUnmount(): void {
        document.body.removeEventListener('click', this.state.boundBodyClick);
        window.removeEventListener('keydown', this.state.boundKeyDown);
        window.removeEventListener('resize', this.state.boundResize);
        document.querySelector('#modals').removeChild(this.state.portal);
    }


    onBodyClick(e: MouseEvent) {
        if (!(e.target as HTMLElement).closest('.s-popover')) {
            this.props.onClose();
        }
    }


    render(): JSX.Element {
        let { x, y } = this.props.at;
        const w = window.innerWidth;
        const h = window.innerHeight;
        const xEdge = x < w / 2 ? 'left' : 'right';
        const yEdge = y < h / 2 ? 'top' : 'bottom';

        if (xEdge === 'right') {
            x = w - x;
        }

        if (yEdge === 'bottom') {
            y = h - y;
        }

        return ReactDOM.createPortal(
            <div className="s-popover" style={{ [xEdge]: x, [yEdge]: y }}>
                {this.props.children}
            </div>,
            this.state.portal,
        );
    }
}
