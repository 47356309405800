// BSA.tsx
import * as React from 'react';
import { colors, style, StyleSheet } from "@/utils/style";
import BSAMap  from "./BSAMap";
import { HeaderKpi } from '../species/SpeciesPage';

const BSA = ({site}) => {
  console.log('hey site ===>>>> ', site);
  const [bsaCount, setBsaCount] = React.useState(0);
  const [bsa, setBsa] = React.useState([]);
  const [proximity, setProximity] = React.useState<number>(0);
  const [coverageArea, setCoverageArea] = React.useState<number>(0);
  const [selected, setSelected] = React.useState({});
  function handleBSAChange(data:any){
    setBsaCount(data.radiusFiltered.features.length);
    setBsa(data.radiusFiltered.features);
    setProximity(data.closestDistanceMeters.distance_meters.toFixed(0));
    setCoverageArea(data.coverageAreaM2);
  }
    return (
        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, gap: 30 }}>
          <div style={styles.wrapper}>
          <div className={'table-container'}>
              <table className="s-table s-table-fixed species-table" style={{ textAlign: 'left' }}>
              <thead>
            <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Reported Area (km²)</th>
            </tr>
           </thead>
           <tbody>
            {bsa.length > 0 ? (
              bsa.map((item, index) => (
                <tr key={index}>
                  <td onClick={() => setSelected(item)}>{item.properties.name}</td>
                  <td>{item.properties.gov_type}</td>
                  <td>{item.properties.rep_area.toFixed(3)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={2}>No Records.</td>
              </tr>
            )}
          </tbody>
        </table>
        </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: 20 }}>
            <div style={styles.summaryHeader}>
            <HeaderKpi label='Biodiversity Sensitive Areas (BSA)' value={bsaCount} />
            <HeaderKpi label='Proximity (M)' value={proximity} />
            {/* hide it for now until we adjust it as it is hard to understand now */}
            {/* <HeaderKpi label='Coverage Area (km²)' value={(coverageArea / 1000).toFixed(0)} /> */}
            </div>
            <div style={{ position: 'relative', minHeight: 500 }}>
              <BSAMap site={site} onBSAChange={handleBSAChange} selected={selected}  />
            </div>
          </div>
        </div>
      );
};


const styles = StyleSheet.create({
    wrapper: {
      width: '50%',
      borderRadius: 10,
      border: `1px solid ${colors.brightBlue}`,
      padding: 10,
      backgroundColor: colors.darkBlue,
    },
    summaryHeader: {
      height: 90,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: colors.darkBlue,
      borderRadius: 10,
      padding: '5px 20px',
    },
    filterBar: {
      borderRadius: 10,
      backgroundColor: colors.opaqueWhite,
      padding: 10,
      alignItems: 'center',
      gap: 10,
    },
    export: {
      height: '100%',
      borderRadius: 20,
      backgroundColor: 'transparent',
      opacity: 1,
      padding: '5px 10px',
      color: colors.white,
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      border: `1px solid ${colors.white}`,
      fontSize: 13,
    },
  });


export default BSA;