import * as React from 'react';
import { Dropdown } from '@/lib';
import { IDropdownItem } from '@/lib/Dropdown';
import { t } from '@/utils';


export interface IMappingRow {
    id: string
    children?: React.ReactNode
    examples: React.ReactNode
    error?: string
    dropdownlabel: string
    kuyuaLabel: string
    options: IDropdownItem[]
    onChange: (value: string) => void
}


export const MappingRow = React.forwardRef((props: IMappingRow, ref: React.ForwardedRef<HTMLDivElement>): JSX.Element => {
    return (
        <tr>
            <td className="s-site-import-mappings-table-cell">
                <Dropdown
                    ref={ref}
                    id={props.id}
                    align="left"
                    label={props.dropdownlabel || t('actions.choose')}
                    items={props.options}
                    error={props.error}
                    onItemClick={props.onChange}
                />
            </td>
            <td className="s-site-import-mappings-table-cell">
                {props.examples}
            </td>
            <td className="s-site-import-mappings-table-cell">
                {props.kuyuaLabel}
                <div className="s-site-import-mappings-info">
                    {props.children}
                </div>
            </td>
        </tr>
    );
});

MappingRow.displayName = 'MappingRow';

export default MappingRow;
