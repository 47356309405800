import { httpGet } from '@/utils';
import { CountriesChanged } from '@/context';


type IGenericDispatch = (action: IGenericAction) => void;


export async function updateCountries(state: IAppState, dispatch: IGenericDispatch): Promise<Record<string, ICountry>> {
    const countries = state.countries;

    if (countries !== undefined) {
        return countries;
    }

    const reply = await httpGet<Record<string, ICountry>>('/api/v1/countries');

    if (!reply.ok) {
        return null;
    }

    dispatch({ type: CountriesChanged, project: reply.data });

    return reply.data;
}
