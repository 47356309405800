import * as React from 'react';
import classnames from 'classnames';
import { mdiAlertCircleOutline } from '@mdi/js';
import {
    AppContext,
    ConfigChanged,
    DependenciesChanged,
    EconomicActivityCountChanged, ImpactsAndDependenciesChanges,
    ImpactsChanged,
    ProjectsChanged, SetLoadingState, SitesChanged, ToastAdded
} from '../context';
import {apiGet, t} from '@/utils';
import { Icon } from '@/lib';
import {fetchImpactsAndDependencies} from "@/rest/apiHelper";


function initializeAppWithTeam(teamSlug: string, setInitErrorCode: (code: number) => void, dispatch: (action: IGenericAction) => void): void {
    apiGet<IConfig>(teamSlug, 'hello').then(reply => {
        if (!reply.ok) {
            if (reply.status >= 400) {
                setInitErrorCode(reply.status);
            }

            return;
        }

        const { team } = reply.data;
        const firstProject = Object.values(team.projects)[0];

        Object.values(team.projects).forEach(p => p.reports = new Map());

        initDependencies(dispatch);
        initImpacts(dispatch);
        initEconomicActivityCount(dispatch);
        initSites(dispatch, team, firstProject, () => {});
        dispatch({ type: ConfigChanged, config: reply.data });
        dispatch({ type: ProjectsChanged, projects: team.projects });
    });
}

export function initDependencies(dispatch: (action: IGenericAction) => void): void {

    apiGet<IDependency[]>(undefined, 'dependency').then(result => {

        if (!result.ok) {
            return;
        }

        dispatch({type: DependenciesChanged, dependencies: result.data})
    });
}

export function initEconomicActivityCount(dispatch: (action: IGenericAction) => void): void {

    apiGet<IDependency[]>(undefined, 'economic-activity/number-of').then(result => {

        if (!result.ok) {
            return;
        }

        dispatch({type: EconomicActivityCountChanged, count: result.data})
    });
}

export function initSites(dispatch: (action: IGenericAction) => void, team: ITeam, project: IProject, callback: () => void): void {

    fetchAllSites(dispatch, project, team, [], callback);
}

async function fetchAllSites(dispatch: (action: IGenericAction) => void, project: IProject, team: ITeam, pages: INewSite[] = [], callback: () => void) : void {

   const url = `projects/${project.slug}/reports/${project.latest_report_id}/dashboard_information`;

   await apiGet<{impacts: iCluster[], dependencies: iCluster[], sites: INewSite[]}>(team.slug, url).then(reply => {

        if (reply.ok) {

            dispatch({ type: SitesChanged, sites: reply.data.sites });
            callback();

        } else {

            dispatch({ type: ToastAdded, toast: { kind: 'error', text: t('ui.failed_to_load_sites') } });
        }
    });
}

export function initImpacts(dispatch: (action: IGenericAction) => void): void {

    apiGet<IImpact[]>(undefined, 'impact').then(result => {

        if (!result.ok) {
            return;
        }

        dispatch({type: ImpactsChanged, impacts: result.data})
    });
}

export function initImpactDependencyClusters(dispatch: (action: IGenericAction) => void, teamSlug: string, projectSlug: string): void {

    fetchImpactsAndDependencies(teamSlug, projectSlug).then(reply => {
        dispatch({ type: ImpactsAndDependenciesChanges, impacts: reply.impacts, dependencies: reply.dependencies });
    });
}

function resolveTeamSlug() {
    const slug = document.location.pathname.split('/')[1];

    if (['account', 'debug', 'teams', 'login', 'recovery-token'].includes(slug)) {
        return null;
    }

    return slug;
}


export default function AppLoader(props: { children: React.ReactNode }): JSX.Element {
    const [initErrorCode, setInitErrorCode] = React.useState<number>(0);
    const { dispatch, state } = React.useContext(AppContext);
    const slug = resolveTeamSlug();

    React.useEffect(() => {
        if (slug) {

            initializeAppWithTeam(slug, setInitErrorCode, dispatch);

            return;
        }
    }, []);

    if (initErrorCode !== 0) {
        const arnold = 'We\'ll be back soon!';
        const pd = 'Permission Denied';
        const mb = 'Maintenance Break';
        const title = { 401: pd, 403: pd, 404: pd, 500: mb, 502: mb, 503: mb, 504: mb }[initErrorCode] || 'Error';
        const msg = {
            401: 'Make sure that you\'ve logged in',
            403: 'Make sure that you\'ve logged in',
            404: 'No permission to access this team',
            500: arnold,
            502: arnold,
            503: arnold,
            504: arnold,
        }[initErrorCode] || 'An error occured while initializing the app';

        return (
            <div className="s-app-loader-error">
                <Icon color="#666" path={mdiAlertCircleOutline} size={92} />
                <span>{title}</span>
                <span>{msg}</span>
                <span><a href="/logout" style={{ color: '#013' }}>Log in to continue</a></span>
            </div>
        );
    }

    if (!state.config && slug) {
        return <div className="s-app-loader">Loading…</div>;
    }

    const isSmallLayout = ['xs', 'sm', 'md'].includes(state.layoutSize);
    const classes = classnames(`s-layout-${state.layoutSize}`, {
        's-layout-menu-open': isSmallLayout && state.navigationEnabled && state.navigationPanelVisible,
    });

    return <div className={classes}>
        {props.children}
    </div>;
}
