export const ProjectExploreBaseMapChanged = 'projects/projectExploreBaseMapChanged';
export const ProjectExploreRastersChanged = 'projects/projectExploreRastersChanged';
export const ProjectChanged = 'projects/projectChanged';
export const ProjectRemoved = 'projects/projectRemoved';
export const ProjectReportChanged = 'projects/reportChanged';
export const ProjectReportSiteMeasureChanged = 'projects/reportSiteMeasureChanged';
export const ProjectsChanged = 'projects/projectsChanged';

export function makeProjectsState(): IProjectsState {
    return {
        exploreMapBaseMap: '',
        exploreMapRasters: {},
        projects: {},
    };
}


export const projectsReducer = (state: IProjectsState, action: IGenericAction): IProjectsState => {
    switch (action.type) {

        case ProjectExploreBaseMapChanged:
            return { ...state, exploreMapBaseMap: action.basemap };

        case ProjectExploreRastersChanged:
            return { ...state, exploreMapRasters: { ...action.rasters } };

        case ProjectChanged:
            return { ...state, projects: { ...state.projects, [action.project.slug]: action.project } };

        case ProjectRemoved:
            return { ...state, projects: Object.fromEntries(Object.entries(state.projects).filter(([slug]) => slug !== action.slug)) };

        case ProjectReportChanged:
            action.project.reports = {
                ...action.project.reports,
                [action.report.id]: action.report,
            };

            return state;

        case ProjectReportSiteMeasureChanged:
            for (let i = 0; i < action.site.measures.length; i += 1) {
                const m = action.site.measures[i];

                if (m.id === action.measure.id) {
                    action.site.measures.splice(i, 1, action.measure);
                    break;
                }
            }

            return state;

        case ProjectsChanged:
            return { ...state, projects: action.projects };

    }

    return state;
};
