import * as React from 'react';
import { ActionLink, ProgressBar } from '@/lib';


interface IKpiProps {
    label: string
    progress?: number
    onSublabelClick?: () => void
    size?: string
    style?: React.CSSProperties
    sublabel?: string
    value: number | string
}


export default function Kpi(props: IKpiProps): JSX.Element {
    return (
        <div className={`s-kpi s-kpi-${props.size || 'md'}`} style={props.style}>
            <div className="s-kpi-label">{props.label}</div>
            <div className="s-kpi-value-wrapper">
                {props.progress !== undefined && <ProgressBar orientation="vertical" value={props.progress} />}
                <span className="s-kpi-value">{props.value}</span>
            </div>
            {props.sublabel && <div className="s-kpi-sublabel">
                {props.onSublabelClick && <ActionLink onClick={props.onSublabelClick}>{props.sublabel}</ActionLink>}
                {!props.onSublabelClick && props.sublabel}
            </div>}
        </div>
    );
}
