import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext, ToastAdded } from '@/context';
import { Button, HorizontalStack, LabeledTextInput, LoadingAnimation, Radio, VerticalStack } from '@/lib';
import { apiGet, apiPost, pick, t } from '@/utils';
import TeamRolePicker from './TeamRolePicker';


export default function TeamMemberhipDetail(): JSX.Element {
    const { dispatch, state } = React.useContext(AppContext);
    const params = useParams();
    const navigate = useNavigate();
    const [isSaving, setIsSaving] = React.useState<boolean>(false);
    const [userData, setUserData] = React.useState<{
        email: string
        first_name: string
        last_name: string
        is_active: boolean
        role: string
    }>(undefined);
    const onSaveClick = () => {
        if (isSaving) {
            return;
        }

        const payload = pick(userData, 'first_name', 'is_active', 'last_name', 'role');

        setIsSaving(true);
        apiPost(state.config.team.slug, `members/${params.userId}`, payload).then(reply => {
            dispatch({
                type: ToastAdded,
                toast: reply?.ok ?
                    { kind: 'success', text: 'Team member updated!' } :
                    { kind: 'error', text: JSON.stringify(reply.data) || 'Unknown error occurred!' },
            });

            if (reply?.ok) {
                navigate('/account/members');
            }
        }).finally(() => setIsSaving(false));
    };

    React.useEffect(() => {
        apiGet<{ membership: ITeamMembership }>(state.config.team.slug, `members/${params.userId}`).then(reply => {
            if (reply?.ok) {
                setUserData({
                    ...pick(reply.data.membership.user, 'email', 'first_name', 'is_active', 'last_name'),
                    role: reply.data.membership.role.slug,
                });
            }
        });
    }, [params.userId]);

    if (!userData) {
        return <LoadingAnimation />;
    }

    return (
        <div style={{ marginTop: '2rem', maxWidth: '40rem' }}>
            <VerticalStack>
                <LabeledTextInput
                    id="team_member_first_name"
                    label={t('ui.first_name')}
                    onInput={first_name => setUserData({ ...userData, first_name })}
                    value={userData.first_name} />
                <LabeledTextInput
                    id="team_member_last_name"
                    label={t('ui.last_name')}
                    onInput={last_name => setUserData({ ...userData, last_name })}
                    value={userData.last_name} />
                <LabeledTextInput id="team_member_email" disabled={true} label={t('ui.email')} value={userData.email} />
            </VerticalStack>

            <TeamRolePicker
                disabled={isSaving}
                onChange={role => setUserData({ ...userData, role })}
                role={userData.role}
                style={{ marginTop: '2rem' }} />

            <VerticalStack spacing="tight" style={{ marginTop: '2rem' }}>
                <div style={{ fontFamily: 'Roobert Medium', fontSize: '1.1rem', marginBottom: '1rem' }}>Status</div>

                <Radio
                    checked={userData.is_active}
                    help="Team member may log in and use kuyua normally"
                    label="Active"
                    onToggle={() => setUserData({ ...userData, is_active: true })} />
                <Radio
                    checked={!userData.is_active}
                    help="Team member cannot log in or make any changes to kuyua"
                    label="Disabled"
                    onToggle={() => setUserData({ ...userData, is_active: false })} />
            </VerticalStack>

            <HorizontalStack spacing="tight" style={{ marginTop: '2rem' }}>
                <Button disabled={isSaving} onClick={onSaveClick}>{t('actions.save')}</Button>
            </HorizontalStack>
        </div>
    );
}
