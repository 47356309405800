import { createContext, useReducer } from "react";
import * as React from "react";
import {GenericUpdate, SleapContextType} from "@/types/sleap";

export const SleapContext = createContext<{ sleapState: SleapContextType | undefined; sleapDispatch: React.Dispatch<any> }>({
    sleapState: undefined,
    sleapDispatch: (): null => null,
});

const reducer = (state: SleapContextType, value: GenericUpdate): SleapContextType => ({
    ...state,
    ...value,
});

export const SleapProvider = (props: { children: React.ReactNode }): JSX.Element => {

    const initialState: SleapContextType = {
        bbox: undefined,
        mapCenter: undefined,
        pointOfInterest: undefined,
        zoom: undefined,
        selectedCenter: undefined,
        filteredSites: undefined,
        unsetZoom: false,
        selectedSiteId: undefined,
        targetSiteId: undefined,
    };

    const [sleapState, sleapDispatch] = useReducer(reducer, initialState);

    return (
        <SleapContext.Provider value={{ sleapState, sleapDispatch }}>
            {props.children}
        </SleapContext.Provider>
    );
};
