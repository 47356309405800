import * as React from 'react';

export interface IDoughnutSegment {
    color: string
    value: number
}


interface IDoughnutProps {
    angle?: number  // In degrees
    segments: IDoughnutSegment[]
    size: number  // In pixels
    style?: React.CSSProperties
    thickness?: number
    caption?: string
}


export default function Doughnut(props: IDoughnutProps): JSX.Element {
    const center = Math.round(props.size / 2);
    const size = props.size;
    const thickness = props.thickness || 20;
    const radius = (size - (thickness + .5)) / 2;
    const circumference = 2 * Math.PI * radius;
    const total = props.segments.reduce((sum, seg) => sum + seg.value, 0);
    const segments = React.useMemo(() => {
        let angle = props.angle === undefined ? -90.0 : props.angle;

        return props.segments.map(s => {
            const offset = angle;
            const value = s.value > 0 ? s.value : 0;

            angle = value / total * 360 + angle;

            return {
                startOffset: offset,
                strokeOffset: circumference - ((value / total) * circumference),
                ...s,
            };
        });
    }, [props.angle, props.segments, size, props.thickness]);

    return (
        <svg className="s-doughnut" height={size} style={props.style} viewBox={`0 0 ${size} ${size}`} width={size}>
            {segments.map((s, ix) => <g key={`${ix} ${s.color} ${s.value}`}>
                <circle
                    cx={center}
                    cy={center}
                    fill="transparent"
                    r={radius}
                    stroke={s.color}
                    strokeDasharray={circumference}
                    strokeDashoffset={s.strokeOffset}
                    strokeWidth={thickness}
                    transform={`rotate(${s.startOffset}, ${center}, ${center})`}
                />
            </g>)}
        </svg>
    );
}
