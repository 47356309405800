import SatelliteTileset from '@/assets/map-tileset-satellite.jpg';
import TerrainTileset from '@/assets/map-tileset-terrain.png';

export const MapboxApiEndpoint = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
export const SatelliteStyleUri = 'mapbox://styles/andre-hickmann/clj5f7coj001y01pi58xa41b3';
export const TerrainStyleUri = 'mapbox://styles/andre-hickmann/clh65djdh00r401qyd5cfccw1';


export const MapStyles = [
    {
        name: 'Satellite',
        icon: SatelliteTileset,
        uri: SatelliteStyleUri,
    },
    {
        name: 'Streets',
        icon: TerrainTileset,
        uri: TerrainStyleUri,
    },
];
