import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { AppContext, useProject, useTeam, useTheme } from '@/context';
import { expandBBox, formatPercentage, getHomeUrl, t } from '@/utils';
import { BreadcrumbPath, Button, CopyrightNotice, Grid, HorizontalStack, Kpi, MasterHeader, NotificationBar } from '@/lib';
import { dismissNotification, updateNotifications } from '@/resources';
import { StaticMap } from '@/lib/map';
import { TargetsModalDialog } from '@/lib/modals';
import {AllSitesTable} from '../measure/AllSitesTable';


export default function ReportOverviewContent(): JSX.Element {
    const { dispatch, state } = React.useContext(AppContext);
    const [fullscreenMap, setFullscreenMap] = React.useState<boolean>(false);
    const [targetModalVisible, setTargetModalVisible] = React.useState<boolean>(false);
    const theme = useTheme();
    const team = useTeam();
    const project = useProject();
    const report = project.reports[project.latest_report_id] as IAssessmentReport;
    const isSmallLayout = ['xs', 'sm', 'md'].includes(state.layoutSize);
    const navigate = useNavigate();
    const assessedCount = React.useMemo(() => project.assessment_count, [project.assessment_count]);
    const pins = React.useMemo(() => project.locations.map(s => ({ center: s.latlng, id: 'site-' + s.id, text: s.name })), [project.locations]);
    const summary = report.summary;
    const hasSiteImportFeature = project.experimental_features.includes('site-import');
    React.useEffect(() => {
        document.title = `${t('pages.all_sites')} — ${project.name} — kuyua`;
    }, []);

    React.useEffect(() => {
        updateNotifications(state, dispatch, project);
    }, []);

    const notification = state.notifications?.notifications[0];
    const hasManyNotifications = state.notifications?.notifications.length > 1;

    return (
        <div style={{ padding: '10rem 2rem 2rem 2rem' }}>

            {notification && <NotificationBar
                actions={[
                    { label: t('actions.dismiss'), onClick: () => dismissNotification(state, dispatch, project, notification) },
                    { label: t('actions.view'), onClick: () => navigate(`/${team.slug}/${project.slug}/reports/sfdr/${notification.data.message.params.id}`) },
                ]}
                hasMore={hasManyNotifications}
                label={t(notification.data.message.key, { name: notification.data.message.params.name })}
                style={{ marginBottom: '.5rem' }} />}

            <ErrorBoundary fallback={<p>{t('ui.map_loading_error')}</p>}>
                <div style={{ minHeight: '200px', height: fullscreenMap ? 'calc(100vh - 20rem)' : '18vw', position: 'relative' }}>
                    <StaticMap
                        bbox={expandBBox(project.minimap?.bbox)}
                        layers={[]}
                        markers={[]}
                        onFullscreenChange={v => setFullscreenMap(v)}
                        onPinClick={pin => navigate(`/${team.slug}/${project.slug}/sites/` + pin.id.split('-')[1])}
                        pins={pins}
                        rasters={{}}
                        zoom={2} />
                </div>
            </ErrorBoundary>

            <Grid alignItems="flex-start" itemsPerRow={isSmallLayout ? 2 : 4} spacing="loose" style={{ marginTop: '1rem' }}>
                <Kpi label={t('ui.assessed_site', assessedCount)} value={assessedCount} />
                <Kpi label={t('ui.task', summary.total_measure_count)} value={summary.total_measure_count} />
                <Kpi label={t('ui.progress')}
                    onSublabelClick={() => ''}
                    progress={summary.implemented_measures.percentage}
                    value={formatPercentage(summary.implemented_measures.percentage, 0)} />
                <Kpi label={summary.target.year ? `${summary.target.year} Target` : 'Target'}
                    onSublabelClick={() =>setTargetModalVisible(true)}
                    progress={summary.implemented_measures.percentage / summary.target.percentage}
                    sublabel={t('ui.set_target')}
                    value={formatPercentage(summary.target.percentage, 0)} />
            </Grid>

            <AllSitesTable />
            {project.site_count === 0 && <div style={{ alignItems: 'center', background: '#F7F7F9', borderRadius: '.5rem', display: 'flex', flexFlow: 'column nowrap', marginTop: '1.5rem', padding: '1rem' }}>
                <HorizontalStack spacing="none" horizontalAlign="center" verticalAlign="flex-start">
                    <div style={{ padding: hasSiteImportFeature ? '3rem 2rem' : undefined, textAlign: 'center' }}>
                        <Button onClick={() => navigate('explore')} variant="primary">{t('actions.explore')}</Button>

                        <div style={{ marginTop: '.75rem' }}>
                            Navigate the map and add sites one by one.
                        </div>
                    </div>
                    {hasSiteImportFeature && <div style={{ borderLeft: `1px solid ${theme.hairlineColor}`, padding: '3rem 2rem', textAlign: 'center' }}>
                        <Button onClick={() => navigate('sites/import')} variant="primary">{t('actions.import_site_plural')}</Button>

                        <div style={{ marginTop: '.75rem' }}>
                            Import sites from a Excel or a CSV sheet.
                        </div>
                    </div>}
                </HorizontalStack>
            </div>}

            <CopyrightNotice />

            {targetModalVisible && <TargetsModalDialog
                onClose={() => setTargetModalVisible(false)}
                report={report}
                project={project} />}
        </div>
    );
}
