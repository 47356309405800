import * as React from 'react';
import { AppContext, ToastAdded, useProject, useTeam } from '@/context';
import { apiGet, apiPost, formatDate, getHomeUrl, t, unique } from '@/utils';
import { SortOrder } from '@/utils/sort';
import { Icon, LoadingAnimation } from "@/lib";
import { mdiChevronDown } from "@mdi/js";
import Pagination from "@/lib/Pagination/Pagination";
import { colors, style, StyleSheet } from "@/utils/style";
import { LocationSearch } from "@/project/sleap/Locate/LocationSearch";
import Checkbox from "@/utils/Checkbox";
import { fetchScopes } from "@/rest/apiHelper";
import Dropdown from "@/utils/Dropdown";
import { NavLink } from "react-router-dom";
import { accumulatePriorityScore } from "@/utils/calculatePriorityScore";
import { ExportButton } from '../sleap/Assess/ExportButton';

const maxSitesOnPage = 10;
const sortType = 'priority_score';
export const taskProcess = (site: INewSite): string => {

    if (site.number_of_tasks) {

        return `${(site.number_of_completed_tasks / site.number_of_tasks).toFixed(0)}%`;
    }
 else {
        return '0%';
    }
};

type IAllSitesTableProps = {
    activeDetailTab?: null | 'overview' | 'species' | 'actions',
}

type selectionChoiceMode = 'all_page' | 'all' | 'none';

export const AllSitesTable: React.FC<IAllSitesTableProps> = ({activeDetailTab}) => {
    const { dispatch, state } = React.useContext(AppContext);
    const [searchText, setSearchText] = React.useState<string>('');
    const [countries, setCountries] = React.useState<string[]>(['All']);
    const [sortOrder, setSortOrder] = React.useState<SortOrder>('desc');
    const [page, setPage] = React.useState<number>(1);
    const [sites, setSites] = React.useState<INewSite[]>(undefined);
    const [sitesCount, setSitesCount] = React.useState<number>(-1);
    const [selectedSiteTypeIds, setSelectedSiteTypeIds] = React.useState<number[]>([]);
    const [selectedSitesToAssess, setSelectedSitesToAssess] = React.useState<number[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const project = useProject();
    const team = useTeam();
    const [isStarting, setIsStarting] = React.useState<boolean>(false);
    const [showSelectionOptions, setShowSelectionOptions] = React.useState<boolean>(false);
    const [widthOffsets, setWidthOffsets] = React.useState<{last: number, secondToLast: number}>({ last: 0, secondToLast: 0 });

    const getSites = () => {
        setLoading(true);
        const queries = [];
        if (!countries.includes('All')) {
            queries.push(`countries[]=${countries.join("&countries[]=")}`);
        }
        if (searchText) {
            queries.push('filter=' + searchText);
        }
        if (sortType) {
            queries.push('order_by=' + sortType);
            queries.push('order_direction=' + sortOrder);
        }
        if (selectedSiteTypeIds.length) {
            queries.push(`site_type_ids[]=${selectedSiteTypeIds.join("&site_type_ids[]=")}`);
        }

        const url = `projects/${project.slug}/reports/${project.latest_report_id}/site/page/${page}`;
        apiGet<{count: number, items: INewSite[], pages: number, current_page: number}>(team.slug, url.concat(queries.length ? '?' + queries.join('&') : '')).then(reply => {
            if (reply.ok) {
                setSitesCount(reply.data.count);
                const sitesWithCalculatedScore = reply.data.items.map((site) => {
                    const impactScore = accumulatePriorityScore(site.impacts);
                    const dependencyScore = accumulatePriorityScore(site.dependencies);
                    return {
                      ...site,
                      calculatedPriorityScore: impactScore + dependencyScore
                    };
                  });
                 
                setSites(sitesWithCalculatedScore);
            } else {
                dispatch({ type: ToastAdded, toast: { kind: 'error', text: t('ui.failed_to_load_sites') } });
            }
        }).finally(() => setLoading(false));
    };

    React.useEffect(() => {
        selectedSiteTypeIds.length && getSites();
    }, [page, sortOrder, sortType]);

    React.useEffect(() => {
        if (page !== 1) {
            setPage(1);
        } else {
            selectedSiteTypeIds.length && getSites();
        }
    }, [countries, searchText, selectedSiteTypeIds]);

    const siteAddressString = (site: INewSite) => {

        if (!site.address?.street_address || !site.address.zip_code || !site.address.city) {
            return '-';
        } else {
            return site.address ? `${site.address.street_address}, ${site.address.zip_code}, ${site.address.city}` : '-';
        }
    };

    const handleSetCountry = (selection: string[]): void => {

        setCountries(selection);
    };

    const assessmentStatusColor = (status: string): string => {

        let color: string;
        switch (status) {
            case 'running':
                color = colors.yellow;
                break;
            case 'complete':
                color = colors.green;
                break;
            case 'queued':
                color = colors.purple;
                break;
            default:
                color = colors.red;
        }
        return color;
    };

    const generateHoverText = (site: INewSite) => {
        switch (site.assessment_status) {
            case 'running':
                return 'Assessment in process';
            case 'complete':
                return `Assessment completed on ${formatDate(site.assessment_end_date)}`;
            case 'queued':
                return 'Assessment queued';
            default:
                return 'Assessment not started';
        }
    };

    const isSuperUser = state.config.user.is_superuser;

    const startAssessment = (siteId: number) => {
        //mostafa: here we start site assessment, buy calling this endpoint
        const url = `projects/${project.slug}/reports/${project.latest_report_id}/sites/${siteId}/start-assessment`;

        setIsStarting(true);
        setSites(prev => {
            const indexSiteInQuestion = prev.findIndex(s => s.id === siteId);
            prev.splice(indexSiteInQuestion, 1, {
                ...prev[indexSiteInQuestion],
                assessment_status: 'queued',
            });
            return prev;
        });
        apiPost<ISite>(state.config.team.slug, url, {}).then(reply => {
            if (reply.ok) {
                dispatch({ type: ToastAdded, toast: { kind: 'success', text: t('site_loader.site_assessment_started') } });
            }
            else {
                dispatch({ type: ToastAdded, toast: { kind: 'error', text: 'Failed to start assessment!' } });
            }
        })
          .finally(() => setIsStarting(false));
    };

    const startAssessmentForAllSites = () => {
        const url = `projects/${project.slug}/reports/${project.latest_report_id}/site/start-all-assessments`;

        setIsStarting(true);
        setSites(prev => {

            return prev.map(site => {
                if (selectedSitesToAssess.includes(site.id)) {

                    return {
                        ...site,
                        assessment_status: 'queued',
                    };
                } else {

                    return site;
                }
            });

        });

        apiPost<ISite>(state.config.team.slug, url, {}).then(reply => {
            if (reply.ok) {
                dispatch({ type: ToastAdded, toast: { kind: 'success', text: t('site_loader.site_assessment_started') } });
            }
            else {
                dispatch({ type: ToastAdded, toast: { kind: 'error', text: 'Failed to start assessment!' } });
            }
        })
          .finally(() => setIsStarting(false));
    };

    const startAssessmentForSelected = () => {

        if (selectedSitesToAssess.length === state.sites.filter(site => ['failed', 'not-started'].includes(site.assessment_status)).length) {

            startAssessmentForAllSites();
        } else {

            selectedSitesToAssess.forEach(siteId => {

                startAssessment(siteId);
            });
        }
    };

    const handleCheck = (check: boolean, siteId: number) => {

        if (check) {
            setSelectedSitesToAssess(prev => ([...prev, siteId]));
        } else {
            setSelectedSitesToAssess(prev => (prev.filter(site => site !== siteId)));
        }
    };

    const handleSelectionChoice = (mode: selectionChoiceMode) => {

        switch (mode) {
            case 'all_page' :
                setSelectedSitesToAssess(sites.filter(site => ['failed', 'not-started'].includes(site.assessment_status)).map(site => site.id));
                break;
            case 'all':
                setSelectedSitesToAssess(state.sites.filter(site => ['failed', 'not-started'].includes(site.assessment_status)).map(site => site.id));
                break;
            case 'none':
                setSelectedSitesToAssess([]);
                break;
        }

        setShowSelectionOptions(false);
    };

    React.useEffect(() => {

        const table = document.getElementById('table');
        const headerRow = table?.querySelector('thead tr');

        const secondToLast = headerRow?.cells[headerRow.cells.length - 2];
        const last = headerRow?.cells[headerRow.cells.length - 1];

        setWidthOffsets({
            secondToLast: secondToLast?.offsetWidth,
            last: last.offsetWidth,
        });
    }, [sites]);

    const handleSortByPriorityScore = () => {

        setSortOrder(prev => prev === 'asc' ? 'desc' : 'asc');
    };


    return (
        <div style={{ flex: 1, padding: 10 }}>
            <TableSearchBar
                countryHandler={handleSetCountry}
                siteTypeHandler={setSelectedSiteTypeIds}
                searchHandler={setSearchText}
            />

            <div>
                <table className="sites-table" id={'table'}>
                    <thead>
                        <tr>
                            <td
                              style={{ textTransform: 'uppercase', display: 'flex', flexDirection: 'row', width: '10%' }}
                            >
                            
                            <span onClick={() => handleSortByPriorityScore()}>{t('ui.priority_score')}</span>
                            </td>
                            <td>{t('ui.name')}</td>
                            <td>{t('ui.site_id')}</td>
                            <td>{t('ui.address')}</td>
                            <td>{t('ui.country')}</td>
                            <td>{t('ui.site_type')}</td>
                            <td>{t('ui.task_plural')}</td>
                            <td>{t('ui.progress')}</td>
                            <td>{t('ui.status')}</td>
                            {isSuperUser && (
                              <>
                                  <td>{t('ui.select')}</td>
                                  <td>{t('ui.assessment')}</td>
                              </>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {sites && sites.map(site => <tr key={site.id}>
                            <td>{site.calculatedPriorityScore}</td> 
                            <td>
                                <div className="s-flex-row s-flex-row--space-between">
                                    <span>
                                        {site.assessment_status === 'complete' ? (
                                            <NavLink to={getHomeUrl(team) + `/site/${site.id}/overview`}>
                                                {site.name}
                                            </NavLink>
                                        ) : (
                                          site.name
                                        )}
                                    </span>
                                </div>
                            </td>
                            <td>{site.site_id}</td>
                            <td>{siteAddressString(site)}</td>
                            <td>{site.address?.country_code}</td>
                            <td>{site.site_type.name}</td>
                            <td>{site.number_of_tasks}</td>
                            <td>{taskProcess(site)}</td>
                            <td>
                                <div
                                  style={{ borderRadius: 50, height: 20, width: 20, backgroundColor: assessmentStatusColor(site.assessment_status) }}
                                  title={generateHoverText(site)}
                                />
                            </td>
                            {isSuperUser && (
                              <>
                                  <td>
                                      {(['failed', 'not-started'].includes(site.assessment_status) || isStarting) && (
                                        <Checkbox
                                          checked={selectedSitesToAssess.includes(site.id)}
                                          checkHandler={(check:boolean) => handleCheck(check, site.id)}
                                        />
                                      )}
                                  </td>
                                  <td>
                                  {/* mostafa: call assessment */}
                                      {(['failed', 'not-started'].includes(site.assessment_status) || isStarting) && (
                                        <button
                                          style={{ ...style.actionButton, ...styles.detailButton }}
                                          onClick={() => startAssessment(site.id)}
                                        >
                                            {t('ui.start_assessment')}
                                        </button>
                                      )}
                                  </td>
                              </>
                            )}
                        </tr>)}
                    </tbody>
                </table>
                {loading &&
                    <div className="s-loading-overlay">
                        <LoadingAnimation />
                    </div>
                }
            </div>

            {isSuperUser && (
              <div style={{ ...style.flexRow, ...styles.wrapper, marginTop: 10, justifyContent: 'flex-end', padding: 'unset', gap: 'unset' }}>
                  <div style={{ width: widthOffsets.secondToLast, padding: 10 }}>
                      <div style={{ position: 'relative' }}>
                          <button
                            style={{ ...style.actionButton, ...styles.detailButton, ...styles.selectionChoices }}
                            onClick={() => setShowSelectionOptions(prev => !prev)}
                          >
                              {t('ui.select_options')}
                              <Icon color="#000" path={mdiChevronDown} size={12} />
                          </button>
                          {showSelectionOptions && (
                            <div
                              style={styles.selectionChoicesDropDown}
                            >
                                <div>
                                    <a style={styles.link} onClick={() => handleSelectionChoice('all_page')}>{t('ui.select_all_page')}</a>
                                </div>
                                <div>
                                    <a style={styles.link} onClick={() => handleSelectionChoice('none')}>{t('ui.unselect_all')}</a>
                                </div>
                                <div>
                                    <a style={styles.link} onClick={() => handleSelectionChoice('all')}>{t('ui.select_all')}</a>
                                </div>
                            </div>
                          )}
                      </div>
                  </div>
                  <div style={{ width: widthOffsets.last, padding: 10 }}>
                      <button
                        style={{ ...style.actionButton, ...styles.detailButton, width: '100%', opacity: selectedSitesToAssess.length ? 1 : 0.5 }}
                        onClick={() => startAssessmentForSelected()}
                        disabled={!selectedSitesToAssess.length}
                      >
                          {t('ui.start_assessment_for_selected')}
                      </button>
                  </div>
              </div>
            )}

            {sitesCount > maxSitesOnPage &&
                <Pagination
                    changePage={setPage}
                    currentPage={page}
                    lastPage={Math.ceil(sitesCount / maxSitesOnPage)}
                />
            }
        </div>
    );
};

const TableSearchBar: React.FC<TableSearchBarProps> = ({ countryHandler, siteTypeHandler, searchHandler }) => {
    const [scopeOptions, setScopeOptions] = React.useState<iScope[]>([]);
    const [selectedScopeIds, setSelectedScopeIds] = React.useState<number[]>([]);
    const [selectedCountryIds, setSelectedCountryIds] = React.useState<number[]>([]);
    const project = useProject();
    const team = useTeam();
    const url=`/api/v1/teams/${team.slug}/projects/${project.slug}/reports/${project.latest_report_id}/sites/assessments/2`
    const countries = React.useMemo(() => {
        return [{ id: -1, label: t('ui.all') }].concat(
          unique(project.countries)
            .filter(country => country)
            .sort()
            .map((id, i) => ({ id: i, label: id })));
    }, [project.countries]);

    React.useEffect(() => {

        fetchScopes().then(reply => {
            setScopeOptions(reply);
            setSelectedScopeIds(reply.map(scope => scope.id));
        });
    }, []);

    const handleCheck = (id: number, check: boolean) => {

        if (check) {
            setSelectedScopeIds(prev => [...prev, id]);
        }
 else {
            setSelectedScopeIds(selectedScopeIds.filter(scopeId => scopeId !== id));
        }
    };

    const handleCountrySelect = (ids: number[]) => {

        const oldSelect = selectedCountryIds;
        const newSelect = ids.filter(newId => !oldSelect.includes(newId));

        if (ids.length === 0 || newSelect.includes(-1)) {

            countryHandler(['All']);
            setSelectedCountryIds([]);
        } else {

            const selectedCountries = countries.filter(country => ids.includes(country.id)).map(country => country.label);
            countryHandler(selectedCountries);
            setSelectedCountryIds(ids);
        }
    };

    React.useEffect(() => {

        siteTypeHandler(selectedScopeIds);
    }, [selectedScopeIds]);

    return (
      <div style={{ ...style.flexRow, ...styles.wrapper }}>
          <div style={{ flex: 2 }}>
              <LocationSearch inputHandler={searchHandler} />
          </div>   
          <div style={{ flex: 1 }}>
              <Dropdown
                placeholder={'country'}
                dropdownStyle={{ padding: '5px 10px', marginBottom: 'unset', fontSize: 13, height: 'unset' }}
                listDropdownStyle={{ top: "unset" }}
                handler={handleCountrySelect}
                options={countries}
                selected={selectedCountryIds}
                flat
                multiple
                selectedRight
              />
          </div>
          <div style={{ flex: 1 }}>
          <ExportButton url={url}/>
          </div>
          <div style={{ ...style.flexRow, flex: 2 }}>
              <span>Site Type: </span>
              {scopeOptions.map((scope, i) => (
                <Checkbox
                  checked={selectedScopeIds.includes(scope.id)}
                  label={scope.name}
                  key={i}
                  checkHandler={(check:boolean) => handleCheck(scope.id, check)}
                />
              ))}
          </div>

      </div>
    );
};

const styles = StyleSheet.create({
    wrapper: {
        borderRadius: 10,
        backgroundColor: colors.opaqueWhite,
        padding: 10,
        alignItems: 'center',
        gap: 10,
    },
    detailButton: {
        width: 50,
        fontSize: 10,
        height: 20,
        borderRadius: 20,
        color: colors.darkBlue,
        lineHeight: 1,
    },
    selectionChoices: {
        justifyContent: 'space-between',
        gap: 10,
        width: '100%',
        position: 'relative',
        zIndex: 2,
    },
    selectionChoicesDropDown: {
        width: '100%',
        position: 'absolute',
        top: 0,
        borderRadius: 20,
        backgroundColor: colors.opaqueBrightBlue,
        padding: '25px 10px 10px',
        display: 'flex',
        fontSize: 10,
        flexDirection: 'column',
        gap: 5,
        color: colors.black,
    },
    link: {
        color: colors.black,
        cursor: 'pointer',
        textDecoration: 'none',
    },
});
