import * as React from 'react';
import classNames from 'classnames';
import Icon from './Icon';
import {colors} from "@/utils/style";


interface ITabBarProps {
    activeId?: number | string
    onClick: (id: number | string) => void
    style?: React.CSSProperties
    tabs: {
        id: number | string
        label: string
        postfixIcon?: string
    }[]
}


export function TabBarItem(props: { active: boolean, label: string, onClick: () => void, postfixIcon?: string }): JSX.Element {
    const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (props.onClick && ['Enter', 'NumpadEnter', 'Space'].includes(e.code)) {
            props.onClick();
        }
    };

    return <span
        onClick={() => !props.active && props.onClick() }
        style={{
            borderRadius: '10px 10px 0 0',
            padding: '0 20px',
            color: colors.white,
            backgroundColor: colors.darkBlue,
            textAlign: 'center',
            cursor: 'pointer',
            ...(props.active && {color: colors.darkBlue, backgroundColor: 'rgba(255, 255, 255, 0.8)'}),
        }}
        onKeyPress={onKeyPress}
        role="tab"
        tabIndex={0}>
            <span>{props.label}</span>
            {props.postfixIcon && <Icon color="#333" path={props.postfixIcon} size={16} />}
    </span>;
}


export default function TabBar(props: ITabBarProps): JSX.Element {
    return (
        <div className="s-tab-bar" role="tablist" style={props.style}>
            {props.tabs.map(t => <TabBarItem
                active={t.id === props.activeId}
                key={t.id}
                label={t.label}
                onClick={() => props.onClick(t.id)}
                postfixIcon={t.postfixIcon} />)}
        </div>
    );
}
