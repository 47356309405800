import * as React from 'react';


interface INumberedTextProps {
    number: number
    style?: React.CSSProperties
    text: string
}


export default function NumberedText(props: INumberedTextProps): JSX.Element {
    return (
        <div className="s-numbered-text">
            <span>{props.number}</span>
            <span>{props.text}</span>
        </div>
    );
}
