import React, {useContext} from "react";
import {colors, style, StyleSheet} from "@/utils/style";
import {
    AppContext,
    ImpactsAndDependenciesChanges,
    SetLoadingState,
    SitesChanged,
    ToastAdded,
    useProject,
    useTeam
} from "@/context";
import {mdiAttachment, mdiFileUploadOutline, mdiPlus} from "@mdi/js";
import Icon from "../../../lib/Icon";
import LocationForm from "@/project/sleap/Locate/LocationForm";
import {fetchImpactsAndDependencies, fetchScopes, url} from "@/rest/apiHelper";
import {apiGet, apiPost, postFile, t} from "@/utils";
import {useSleapStep} from "@/utils/customHooks";
import {SleapContext} from "@/context/SleapContext";
import {LocationSearch} from "@/project/sleap/Locate/LocationSearch";
import {LocationTile} from "@/project/sleap/Locate/LocationTile";
import {Button} from "@/project/sleap/Button";
import {CheckHandler} from "@/types/sleap";
import {orderByPriority} from "@/utils/sort";
import {initSites} from "@/lib/AppLoader";
import {IHttpPostResult} from "@/utils/http";
import SiteImportValidationDialog, {ValidationResponse} from "@/project/sleap/Locate/SiteImportValidationDialog";
import Pagination from "@/lib/Pagination/Pagination";

export const getAddressSearchString = (site: INewSite): string => {

    return `${site.address.country} ${site.address.zip_code} ${site.address.city} ${site.address.street_address}`;
};

export const Locate: React.FC<CheckHandler> = ({ checkHandler }) => {
    const project = useProject();
    const { dispatch } = useContext(AppContext);
    const [scopeOptions, setScopeOptions] = React.useState<iScope[]>([]);
    const [openCreate, setOpenCreate] = React.useState(false);
    const [showValidationModule, setShowValidationModule] = React.useState(false);
    const team = useTeam();
    const report = project.reports[project.latest_report_id] as IAssessmentReport;
    const [currentOpen, setCurrentOpen] = React.useState<number>();
    const [sites, setSites] = React.useState<INewSite[]>([]);
    const [filterInput, setFilterInput] = React.useState<string>('');
    const { sleapState, sleapDispatch } = React.useContext(SleapContext);
    const [, setSleapStep] = useSleapStep();
    const [response, setResponse] = React.useState<ValidationResponse>();
    const [page, setPage] = React.useState<number>(1);
    const [sitesCount, setSitesCount] = React.useState<number>(-1);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        const file = e.target.files[0];
        if (!file) {
            return;
        }
        const data = new FormData();
        data.append('file', file);
        const path = `/api/v1/teams/${team.slug}/projects/${project.slug}/reports/${project.latest_report_id}/site/import`;
        dispatch({ type: SetLoadingState, loading: true });
        postFile<Record<string, string>>(path, data).then(reply => {
            if (reply.ok) {

              handleImportValidation(reply);
            } else {
              dispatch({ type: ToastAdded, toast: { kind: 'error', text: `Failed to import file! ${reply.response?.error}` } });
            }
        });
    };

    const handleImportValidation = (response: IHttpPostResult<Record<string, string>>) => {

        if (response.data.message.startsWith('Data for all sites in the file is correct')) {

            dispatch({ type: ToastAdded, toast: { kind: 'success', text: 'Import successful.' } });
        } else {

            createValidationPopup(response);
        }
        window.setTimeout(() => initSites(dispatch, team, project, () => {}), 5000);
        const inputField = document.getElementById('csvFileInput');
        if (inputField) {
            inputField.value = '';
        }
    };

    const createValidationPopup = (result: IHttpPostResult<Record<string, string>>) => {

        setResponse(result.data);
        setShowValidationModule(true);
    };

    React.useEffect(() => {

        fetchScopes().then(reply => setScopeOptions(reply));
    }, []);

    React.useEffect(() => {

        (openCreate) && setCurrentOpen(0);
    }, [openCreate]);

    React.useEffect(() => {

        currentOpen > 0 && setOpenCreate(false);
    }, [currentOpen]);

    React.useEffect(() => {

        checkHandler(sites.length > 0);
    }, [sites.length]);

    function createLocation(site: INewSite): void {
        apiPost(team.slug, url.siteCreate(report.id, project.slug), site).then((reply) => {
            console.log('site before sending to server ======>>>>>>. ', site)
            if (reply.ok) {
                const storedSite = reply.data
                const newSites = [...sites, storedSite].sort(orderByPriority);

                dispatch({ type: ToastAdded, toast: { kind: 'success', text: 'Site added successfully.' } });
                dispatch({ type: SitesChanged, sites: newSites })
                fetchImpactsAndDependencies(team.slug, project.slug).then(reply => {
                    dispatch({ type: ImpactsAndDependenciesChanges, impacts: reply.impacts, dependencies: reply.dependencies });
                });
                setOpenCreate(false);
                sleapDispatch({ unsetZoom: true, selectedCenter: undefined });
            } else {
                dispatch({ type: ToastAdded, toast: { kind: 'error', text: `Failed to create site!` } });
            }
        });
    }

    const closeForm = () => {

        setOpenCreate(false);
        sleapDispatch({ unsetZoom: true, selectedCenter: undefined });
    };

    React.useEffect(() => {

        if (sleapState.selectedCenter && !currentOpen) {
            setOpenCreate(true);
        }

    }, [sleapState?.selectedCenter]);

    React.useEffect(() => {

        setCurrentOpen(sleapState?.selectedSiteId ?? 0);

    }, [sleapState?.selectedSiteId]);

    const getSites = () => {

        const queries = ['limit=10'];

        let currentPage = page;
        if (filterInput.length) {
            currentPage = 1;
            queries.push('filter=' + filterInput);
        }

        const url = `projects/${project.slug}/reports/${project.latest_report_id}/site/page/${currentPage}`;
        apiGet<{count: number, items: INewSite[], pages: number, current_page: number}>(team.slug, url.concat(queries.length ? '?' + queries.join('&') : '')).then(reply => {
            if (reply.ok) {
                setSitesCount(reply.data.count);
                const orderedSites = reply.data.items.sort(orderByPriority);
                setSites(orderedSites);
            } else {
                dispatch({ type: ToastAdded, toast: { kind: 'error', text: t('ui.failed_to_load_sites') } });
            }
        });
    };

    React.useEffect(() => {
        getSites();
    }, [page, filterInput]);

    return (
      <>
        <div style={{ ...style.flexColumn, padding: 2 }}>
            <div style={{ margin: 10 }}>
                <LocationSearch inputHandler={setFilterInput} />
            </div>
            <div style={{ ...style.flexColumn, gap: 2, height: 300, overflowY: 'scroll', justifyContent: 'flex-start' }}>
                {sites.map(site => (
                    <LocationTile
                        site={site}
                        scopeOptions={scopeOptions}
                        key={site.id}
                        openHandler={setCurrentOpen}
                        currentOpen={currentOpen}
                    />),
                )}
            </div>
            {sitesCount > 10 &&
              <Pagination
                changePage={setPage}
                currentPage={page}
                lastPage={Math.ceil(sitesCount / 10)}
              />
            }
            <div style={{ ...style.centerFlex, gap: 10, flexDirection: 'row', justifyContent: 'flex-start', padding: '10px 20px' }}>
                <Button
                  title="Next Step: Evaluate"
                  style={style.sleapSectionRightButton}
                  onClick={() => setSleapStep('')}
                />
            </div>
            <div style={{ ...style.centerFlex, gap: 10, flexDirection: 'row', justifyContent: 'flex-start', padding: '10px 20px' }}>
                <button
                    style={{ ...styles.iconButton, ...style.centerFlex }}
                    onClick={() => setOpenCreate(true)}
                >
                    <Icon path={mdiPlus} color={colors.darkBlue} size={30} />
                </button>
                <button
                  onClick={() => document.getElementById('csvFileInput').click()}
                  style={{ ...styles.iconButton, ...style.centerFlex }}
                >
                    <Icon path={mdiFileUploadOutline} color={colors.darkBlue} size={30} />
                </button>
                <input
                  type={"file"}
                  id={"csvFileInput"}
                  accept={".csv"}
                  onChange={handleOnChange}
                  hidden
                />
                <div className="button-container">
                    <a
                      href="-/app/site_import_example.csv"
                      download="site_import_template.csv"
                      style={{ ...styles.iconButton, ...style.centerFlex, backgroundColor: 'transparent', border: `1px solid ${colors.white}` }}
                      className="custom-button"
                    >
                        <Icon path={mdiAttachment} color={colors.white} size={30} />
                    </a>
                </div>
            </div>
            {openCreate && (
                <LocationForm
                    saveHandler={createLocation}
                    closeHandler={closeForm}
                    scopeOptions={scopeOptions}
                />)}
        </div>
          {showValidationModule && <SiteImportValidationDialog
            validationResponse={response}
            onClose={() => setShowValidationModule(false)}
          />}
      </>
    );
};

const styles = StyleSheet.create({
    iconButton: {
        height: 60,
        width: 60,
        borderRadius: 10,
        border: 'none',
    },
});
